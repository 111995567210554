<div class="flex justify-between px-3 sticky top-0 bg-white py-2 z-10">
    <p class="text-gray-500 font-bold text-sm cursor-pointer" (click)="storage.back()"><i
            class="fa-solid fa-chevron-left mr-2"></i>BACK</p>
    <div class="cart-navigation cursor-pointer" routerLink="/menu">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
            stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round"
                d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
        </svg>
    </div>
</div>

<div class="bg-gray-100 p-3 rounded-lg">
    <div class="flex justify-between">
        <div>
            <p class="text-xl font-bold">{{storage.user.UserName}} <i class="feather-check-circle text-success"></i>
            </p>
            <p class="text-xs font-medium">+91-{{storage.user.MobileNo}}</p>
            <!-- <p class="text-xs font-medium">example@xyz.com</p> -->
        </div>
        <div>
            <img src="assets/img/user2.png" class="w-14 h-14 object-cover rounded-full ml-auto" alt="">
        </div>
    </div>
</div>

<p class="text-sm font-bold capitalize my-3">manage address</p>

<!--Select Address  -->
<div class="bg-white rounded-xl p-3 my-3">
    <p class="text-md font-semibold ml-0 my-4 mt-0 cursor-pointer" (click)="AddNewAddress()"><i
            class="fa-solid fa-plus text-sm mr-2"></i> Add New Address</p>
    <div *ngFor="let add of addressList">
        <label>
            <div class="bg-[#f2fcf2] p-3 rounded-md my-2 relative">
                <label for="delete-modal" class="absolute right-5 top-2">
                    <i class="fa fa-trash"></i>
                </label>
                <p class="flex items-center text-sm font-bold uppercase">
                    <input type="radio" name="radio-1" (change)="changeSelected(add.AddressId)"
                        class="radio radio-sm bg-white checked:bg-green-600 mr-3" checked>
                    {{add.AddressType}}
                </p>
                <p class="flex items-center text-xs mt-2 ml-8 font-medium capitalize">{{add.AddressLine1}},
                    {{add.AddressLine2}}</p>
            </div>
        </label>
    </div>
</div>

<!-- The button to open modal -->

<!-- Put this part before </body> tag -->
<input type="checkbox" id="delete-modal" class="modal-toggle" />
<div class="modal">
    <div class="modal-box bg-white text-center">
        <p class="py-4 text-2xl font-bold">Are you Sure ?</p>
        <div class="flex items-center justify-between  mt-4">
            <label for="delete-modal" class="rounded-md w-full py-1 text-white bg-white border border-[#2f4f4f] text-sm mr-2" >
                <button
                    for="delete-modal"
                    class="rounded-md w-full py-1 text-[#2f4f4f] text-sm pointer-events-none cursor-pointer">Cancel
                </button>
            </label>
            <label for="delete-modal" class="rounded-md w-full py-1 text-white bg-[#2f4f4f] border border-[#2f4f4f] text-sm ml-2">
                <button
                    for="delete-modal"
                    class="rounded-md w-full py-1 text-white bg-[#2f4f4f] text-sm pointer-events-none cursor-pointer">Confirm
                </button>
            </label>
        </div>
    </div>
</div>