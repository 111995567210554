<ng-container *ngIf="!storage.ContactlessSettings.IsReadOnlyEnabled">
    <div class="modal-end-placement">
        <div class="w-full">
            <label
                class="btn btn-sm btn-circle ml-auto mr-4 mb-[-20px] border-white relative z-10 flex w-8 h-8 bg-gray-800 text-white"
                (click)="setOrderType()">
                <i class="fa-solid fa-times text-[17px]"></i>
            </label>
            <div class="relative w-full rounded-b-none rounded-t-md px-0 py-3 bg-white">
                <h3 class="text-xl font-medium px-3 tracking-wide my-2">Choose order type</h3>
                <form>
                <div class="flex items-center pb-4 pt-3 px-3">
                    <ng-container *ngIf="storage.ContactlessSettings.IsDeliveryEnabled">
                        <label>
                            <p class="flex items-center text-md font-medium mr-4">
                                <input type="radio" id="Delivery" name="radio-1"
                                    class="radio radio-sm bg-white checked:bg-green-600 mr-2"
                                    (change)="changeRadio('HomeDelivery')" checked>
                                Delivery
                            </p>
                        </label>
                    </ng-container>
                    <ng-container *ngIf="storage.ContactlessSettings.IsDineinEnabled">
                        <label>
                            <p class="flex items-center text-md font-medium mr-4"
                                *ngIf="!storage.ContactlessSettings.IsTableNumberFieldVisible">
                                <input type="radio" id="Dine" name="radio-1"
                                    class="radio radio-sm bg-white checked:bg-green-600 mr-2"
                                    (change)="changeRadio('DineIn')">
                                Dine In
                            </p>
                        </label>
                    </ng-container>
                    <ng-container *ngIf="storage.ContactlessSettings.IsPickupEnabled">
                        <label>
                            <p class="flex items-center text-md font-medium mr-4">
                                <input type="radio" id="Pick" name="radio-1"
                                    class="radio radio-sm bg-white checked:bg-green-600 mr-2"
                                    (change)="changeRadio('TakeAway')">
                                Pickup
                            </p>
                        </label>
                    </ng-container>
                </div>
                </form>
                <div class="w-full px-3">
                    <label class="rounded-md w-full py-3 text-white bg-[#2f4f4f] text-sm" for="choose-order-type"
                        (click)="setOrderType()">
                        <button
                            class="rounded-md w-full py-3 text-white bg-[#2f4f4f] text-sm pointer-events-none cursor-pointer"
                            for="choose-order-type">Confirm and Proceed</button>
                    </label>
                </div>
            </div>
        </div>
    </div>
</ng-container>