export class ContactlessSetting {
    
    ContactlessSettingId:number;

    EnableStockCheck:boolean;
    
    IsDineinEnabled : boolean

    IsDeliveryEnabled : boolean

    IsPickupEnabled : boolean

    PriceBookId : number

    IsCodEnabled : boolean

    IsPaytmEnabled : boolean

    IsReadOnlyEnabled: boolean

    IsTableNumberFieldVisible : boolean
    
    BannerImage : string;
  FixedDeliveryCharge: number;
    
    constructor(){
        this.EnableStockCheck = false;
    }
}