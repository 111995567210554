import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { UtilService } from './util.service';
import { Product } from '../models/product';
import { ProductWrapper } from '../models/ProductWrapper';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  constructor(
    public storage: StorageService,
    ) { }


  increment(product: ProductWrapper, IsNew: boolean, UniqueNo: string) {
      if (IsNew) {
        if (product.IsVariantPresent || (product.ComboGroupList != undefined && product.ComboGroupList.length > 0)) {
          this.openModifier(product);
        }
        else {
          this.storage.addOrderItem(product, 1, product.MinSalePrice);
        }
      }
      else {
        this.storage.updateOrderItem(UniqueNo);
      }
  }

  async openModifier(product: ProductWrapper) {
    // const modal = await this.modalController.create({
    //   component: ModifierPage,
    //   cssClass: 'modal-modifier',
    //   componentProps: {
    //     Product: product,
    //   }
    // });
    //return await modal.present();
  }

  decrementProduct(productId: number) {
    if(this.storage.currentOrder != undefined && this.storage.currentOrder.length > 0){
      var order = this.storage.currentOrder[0];
      if (order.OrderItemWrapperList.filter(x => x.ProductId == productId).length > 1) {
        alert( 'There are variations of this item in your cart. Please choose from your cart which item to remove.');
      }
      else {
        this.storage.decreaseProduct(productId,0);
      }
    }
  }

  getProductCount(Product: any): number {
    var count = 0;
    if (this.storage.currentOrder != undefined && this.storage.currentOrder.length > 0) {
      var order = this.storage.currentOrder[0];
      order.OrderItemWrapperList.forEach(x => {
        if (x.ProductId == Product.ProductId) {
          count = count + x.Quantity;
        }
        else if (Product.IsVariantPresent && Product.VariationItems.filter(y => y.ProductId == x.ProductId).length > 0) {
          count = count + x.Quantity;
        }
      });
    }
    return count;
  }

  IsCustomizable(product: Product): boolean {
    let isAddOn = product.AddOnList != undefined && product.AddOnList.length > 0;
    let isCombo = product.ComboGroupList != undefined && product.ComboGroupList.length > 0;
    return (isAddOn || isCombo);
  }
}
