import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Route } from '@angular/router';
import { Order } from 'src/app/models/order';
import { StorageService } from 'src/app/services/storage.service';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-order-success',
  templateUrl: './order-success.component.html',
  styleUrls: ['./order-success.component.scss']
})
export class OrderSuccessComponent implements OnInit {

  orders: Order;
  currentOrder = new Order();
  orderId;
  inProgress: boolean;
  
  constructor(public storage: StorageService, private activatedRoute: ActivatedRoute,
    private router: Router, private api: ApiService) {
  }

  ngOnInit(): void {

    this.activatedRoute.params.subscribe(params => {
      var orderId = +params['id'];
      console.log(orderId);
      this.getOrders(orderId);
    });

    this.activatedRoute.queryParams.subscribe(params => {
      var isNewOrder = +params['isNewOrder'];
      if (isNewOrder) {
        this.storage.ResetOrder();
      }
    });


  }

  getOrders(orderId) {
    this.inProgress = true;
    this.api.getOrderById(orderId).subscribe((data: any) => {
      if (data != null && data.IsSuccess) {
        this.currentOrder = data.Result.order;
        this.inProgress = false;
      }
    },
      (error) => {
        console.log(error);
        this.inProgress = false;
      });
  }

}
