export class UserWrapper {
    UserId: number;
    UserName: string;
    LedgerId: number;
    RoleId: number;
    IsActive: boolean;
    IsHidden: boolean;
    Email: string;
    MobileNo: string;
    LedgerName: string;
    IsLogedIn: boolean;
    IsFCMSet: boolean;
    IsAdminUser: boolean;
    FcmToken: string;
    DateOfBirth: string;
    DateOfAnniversary: string;
    Designation: string;
    Industry: string;
  Version: number;
    constructor() {
        this.IsLogedIn = false;
        this.IsAdminUser = false;
        this.IsFCMSet = false;
    }
}
export class PasswordWrapper {
    PasswordId: number;
    PasswordString: string;
    IsActive: boolean;
    UserId: number;
    ConfirmPasswordString: string;
}