import { Location } from '@angular/common';
import { ThisReceiver } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseResponse } from 'src/app/models/apiResponse';
import { UserWrapper } from 'src/app/models/userWrapper';
import { ApiService } from 'src/app/services/api.service';
import { RouterService } from 'src/app/services/router.service';
import { StorageService } from 'src/app/services/storage.service';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginUser: UserWrapper = new UserWrapper();
  mobileNumberMsg: string;
  loginText: string = "Login";
  ResentText: string = "Resend otp"
  isErrorVisible: boolean;
  customerNameMsg: string;
  isGoToCart: boolean;
  mode: string //otp, login, signup
  isOtpButtonEnabled: boolean = false;
  enteredOtp: string;
  receivedOtp: string;
  authData: any;
  mobileNumber: any;
  showLoader = false;
  couponCode: any;

  constructor(private _api: ApiService, private storage: StorageService, private router: RouterService,
    private util: UtilService, private location: Location, private activatedRoute: ActivatedRoute) {
    this.mode = "signup";
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params['goToCart'] == 1) {
        this.isGoToCart = true;
      };
    });
  }

  doLogin() {
    this.showLoader = true;
    if (this.isValidDetailsAdded()) {
      this.loginText = "Please wait..";
      this._api.login(this.loginUser).subscribe((data: BaseResponse) => {
        this.loginText = "Send OTP";
        if (data.IsSuccess == true) {
          this.showLoader = false;
          this.receivedOtp = data.Result.OTP.otp;
          this.authData = data.Result;
          this.mobileNumber = data.Result.OTP.mobileNo;

          this.loginText = "Send OTP";
          this.mode = "otp";

        }
        else if (data.ErrorMessage == 'NO_USER_FOUND') {
          this.showLoader = false;
          this.mobileNumberMsg = data.ErrorMessage;
          this.util.showToast('User with this email/phone is not registered. Please Sign up.', 'warning');
          this.mode = "signup";
        }
        else if (data.ErrorMessage != null && data.ErrorMessage != '') {
          this.showLoader = false;
          this.mobileNumberMsg = data.ErrorMessage;
          this.isErrorVisible = true;
        }
        else {
          this.showLoader = false;
          this.util.showToast('Please try again later', 'warning');
        }
      },
        (error) => {
          this.showLoader = false;
          this.loginText = "Send OTP";
          this.util.hideLoader();
          this.util.showToast('Something is not right here', 'danger');
        },
      );
    }

  }

  resendOTP() {
    // this.loginUser = {
    //   MobileNo : this.mobileNumber,
    // }
    console.log(this.loginUser);
    // this._api.login(this.loginUser).subscribe((data: BaseResponse) => {
    //   if (data.IsSuccess == true) {
    //     this.receivedOtp = data.Result.OTP.otp;
    //     this.authData = data.Result;
    //     this.mobileNumber = data.Result.OTP.mobileNo;
    //     this.ResentText = "Sent";
    //   }
    //   else {
    //     this.util.showToast('Please try again later', 'warning');
    //   }
    // },
    //   (error) => {
    //     this.util.hideLoader();
    //     this.util.showToast('Something is not right here', 'danger');
    //   },
    // );

    console.log(this.mobileNumber);
  }

  doSignup() {
    if (this.isValidDetailsAdded()) {
      this.mobileNumber = this.loginUser.MobileNo;
      this.loginText = "Please wait..";
      this._api.sendOtp(this.storage.offerQrId, this.loginUser).subscribe((data: BaseResponse) => {
        this.loginText = "Send OTP";
        if (data.IsSuccess == true) {
          this.receivedOtp = data.Result.OTP.otp;
          this.authData = data.Result;
          this.loginText = "Send OTP";
          this.mode = "otp";
        }
        else if (data.ErrorMessage == 'NO_USER_FOUND') {
          //this.mobileNumberMsg = data.ErrorMessage;
          //this.util.showToast('User with this email/phone is not registered. Please Sign up.', 'warning');
        }
        else if (data.ErrorMessage != null && data.ErrorMessage != '') {
          this.mobileNumberMsg = data.ErrorMessage;
          this.isErrorVisible = true;
        }
        else {
          this.util.showToast('Please try again later', 'warning');
        }
      },
        (error) => {
          this.loginText = "Send OTP";
          this.util.hideLoader();
          this.util.showToast('Something is not right here', 'danger');
        },
      );
    }

  }

  verifyOtp() {

    if (this.enteredOtp == this.receivedOtp) {
      this._api.createCoupon(this.storage.offerQrId, this.loginUser).subscribe((data: BaseResponse) => {
        this.loginText = "Send OTP";
        if (data.IsSuccess == true) {
          this.couponCode = data.Result.couponCode;
          this.mode = "message";
        }
        else if (data.ErrorMessage == 'NO_USER_FOUND') {
          //this.mobileNumberMsg = data.ErrorMessage;
          //this.util.showToast('User with this email/phone is not registered. Please Sign up.', 'warning');
        }
        else if (data.ErrorMessage != null && data.ErrorMessage != '') {
          this.mobileNumberMsg = data.ErrorMessage;
          this.isErrorVisible = true;
        }
        else {
          this.util.showToast('Please try again later', 'warning');
        }
      },
        (error) => {
          this.loginText = "Send OTP";
          this.util.hideLoader();
          this.util.showToast('Something is not right here', 'danger');
        },
      );

    }
  }

  isValidDetailsAdded() {
    var hasError = true;
    this.mobileNumberMsg = null;
    if (this.loginUser.MobileNo == undefined || this.loginUser.MobileNo == '' || this.loginUser.MobileNo.length != 10 || Number.isNaN(this.loginUser.MobileNo)) {
      this.mobileNumberMsg = "Please enter valid 10 digit phone number";
      hasError = false;
    }
    if (this.mode == "signup" && this.loginUser.LedgerName == undefined || this.loginUser.LedgerName == "") {
      this.customerNameMsg = "Name is required";
      hasError = false;
    }
    else if (this.loginUser.MobileNo != undefined && this.loginUser.MobileNo != "") {
      hasError = true;
    }
    else {
      this.mobileNumberMsg = "Phone is required ";
      hasError = false;
    }
    return hasError;
  }



  onOtpChange(otp) {
    if (otp && otp.length == 4) {
      this.enteredOtp = otp;
      this.isOtpButtonEnabled = true;
    }
    else {
      this.isOtpButtonEnabled = false;
    }
    console.log(otp);
  }

  goBack() {
    this.router.redirect('/home/' + this.storage.offerQrId);
  }
}
