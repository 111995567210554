import { Component, OnInit } from '@angular/core';
// import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ApiService } from 'src/app/services/api.service';
import { RouterService } from 'src/app/services/router.service';
import { StorageService } from 'src/app/services/storage.service';
import { TableSelectionComponent } from '../table-selection/table-selection.component';

@Component({
  selector: 'app-store-detail',
  templateUrl: './store-detail.component.html',
  styleUrls: ['./store-detail.component.scss']
})
export class StoreDetailComponent implements OnInit {
  selectedOrderType = 'HomeDelivery';

  constructor(public storage: StorageService, private router: RouterService,
    private api: ApiService) { }

  ngOnInit(): void {

  }

  changeRadio(value){
    this.selectedOrderType = value;
    console.log(this.storage.currentOrder);
    
  }

  setOrderType() {
    if (this.selectedOrderType == 'DineIn') {
      this.router.redirect('table');
    }
    else {
      this.storage.currentOrder[0].SaleOrderType = this.selectedOrderType;
      this.storage.CalculateOrder();
      this.router.redirect('menu');
    }
  }

  onMapClicked() {
    var url = 'https://www.google.com/maps/search/?api=1&query=' + this.storage.Store.Latitude + "," + this.storage.Store.Longitude;
    window.open(url, "_blank");
  }

  onCallClicked() {
    window.open('tel:' + this.storage.Store.SupportContact);
  }

  openTableSelection() {

    // var bsModalRef = this.modalService.show(TableSelectionComponent);
    // bsModalRef.content.onTableSelected.subscribe(data => {
    //   console.log(data);

    //   bsModalRef.hide();
    // });
  }



}
