import { Injectable } from '@angular/core';
import { LoginResponse } from '../models/login';
import { Category } from '../models/category';
import { Order, OrderItem, TaxSlabs } from '../models/order';
import { StoreDetail, Store } from '../models/store';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';
import { UserWrapper } from '../models/userWrapper';
import { ContactlessSetting } from '../models/ContactlessSetting';
import { ProductWrapper } from '../models/ProductWrapper';
import { Location } from '@angular/common';



@Injectable()
export class StorageService {

  CategoryId: number;
  loginResponse: LoginResponse;
  StoreId: number;
  Store: Store;
  RoleWrapper: any;
  categoryList: Category[];
  currentOrder: Order[] = new Array<Order>();
  currentDateRange: Date[] = [];
  selectedfilter: string;
  StoreDetail: StoreDetail;
  previousDate: any;
  OTP: string;
  AuthKey: string;
  IsInternetConnected: boolean = true;
  orderList: Order[];
  RunningOrderList: Order[] = [];
  user: UserWrapper;
  TableNumberCompulsory: boolean = false;
  versionNumber: string;
  ContactlessSettings: ContactlessSetting;
  isLocationEnabled: boolean = true;
  saleOrderType: string;
  Subdomain: string;
  CarNo: string;
  Narration: string;
  FloorList: any;
  offerQrId: number;
  isLoginVisible : boolean = false;

  constructor(private api: ApiService, private auth: AuthService,private location : Location) {
    this.appInit();
    //alert('Storage' + this.companyCode);
    this.ContactlessSettings = new ContactlessSetting();
  }

  appInit() {
    this.currentOrder = this.auth.getOrder() != undefined ? JSON.parse(this.auth.getOrder()) : undefined;
    this.user = this.auth.getLoginUser() != undefined ? JSON.parse(this.auth.getLoginUser()) : new UserWrapper();
    //this.Location = this.auth.getLocation() != undefined ? JSON.parse(this.auth.getLocation()) : undefined;
    this.StoreId = this.auth.getStoreId() != undefined ? parseInt(this.auth.getStoreId()) : 0;


    this.Store = this.auth.getStore();
    this.AuthKey = this.auth.getAuthKey();

    if (this.currentOrder == undefined || this.currentOrder.length == 0) {
      this.createNewOrder();
    }
    //this.saleOrderType = this.auth.getOrderType() != undefined ? this.auth.getOrderType() : 'TakeAway';
  }

  // public get userDetail(): UserWrapper {
  //   return this.auth.getLoginUser() != undefined ? JSON.parse(this.auth.getLoginUser()) : new UserWrapper();
  // }

  public saveLoginResponse(response: LoginResponse) {
    localStorage.setItem('authkey', response.Result.AuthKey);
    //this._storage.SaveStoreId(data.Result.StoreList[0].StoreID);
    // localStorage.setItem('StoreId', data.Result.StoreList[0].StoreID);
  }

  setAuthKey(authKey) {
    this.auth.setAuthKey(authKey)
  }



  setSaleOrderType(orderType: string) {
    this.saleOrderType = orderType;
    this.auth.setOrderType(orderType);
  }

  // getSaleOrderType() {
  //   return this.saleOrderType;
  // }

  // setAddress(address: Address) {
  //   this.Address = address;
  //   this.auth.setAddress(JSON.stringify(this.Address));
  // }

  setUser(user: UserWrapper) {
    this.user = user
    this.auth.setLoginUser(JSON.stringify(this.user));
  }

  public logout() {
    localStorage.clear();
  }

  get isUserLoggedIn() {
    this.user = this.auth.getLoginUser() != undefined ? JSON.parse(this.auth.getLoginUser()) : new UserWrapper();
    if (this.user.MobileNo == undefined) {
      return false;
    }
    else {
      return true;
    }
  }

  public async getLoginResponse() {
    this.loginResponse = new LoginResponse();
    return this.loginResponse;
  }

  public getvariableList() {
    var variableList = new Array<any>();
    variableList.push({ Id: 1, Attribute: "[fname]", Description: "Fetches the first name of the customer to whom the campaign is sent (max length:8)" })
    variableList.push({ Id: 2, Attribute: "[bphone]", Description: "phone number of the outlet from which the customer ordered last (max length:10)" })
    variableList.push({ Id: 3, Attribute: "[customer_first_offer]", Description: "displays the eligible customer first offer to the customer (max length:20)" })
    variableList.push({ Id: 4, Attribute: "[barea]", Description: "outlet name which the customer ordered last (max length:12)" })
    variableList.push({ Id: 5, Attribute: "[coupon]", Description: "Available coupon for customer (max length:6)" })
    //  this.variableList = variableList;
    return variableList;
  }


  public getSMSTemplateList() {
    var SMSTemplateList = new Array<any>();
    SMSTemplateList.push({ Id: 1, Text: "Join With Transaction", SMS: "", Description: "This message will get triggered when a customer makes a purchase while signing up" })
    SMSTemplateList.push({ Id: 2, Text: "Join Without Transaction", SMS: "", Description: "This message will get triggered when a customer signs up but doesn't make a purchase" })
    SMSTemplateList.push({ Id: 3, Text: "Checkin with reward available", SMS: "", Description: "This message will get triggered when a customer signs up but doesn't make a purchase" })
    SMSTemplateList.push({ Id: 4, Text: "Checkin with no reward available", SMS: "", Description: "This message will get triggered when a customer signs up but doesn't make a purchase" })
    SMSTemplateList.push({ Id: 5, Text: "Deduct Points", SMS: "", Description: "This message will get triggered when a customer signs up but doesn't make a purchase" })
    //  this.variableList = variableList;
    return SMSTemplateList;
  }
  public getTaxTypeList() {
    var TaxTypeList = new Array<any>();
    TaxTypeList.push({ Id: 1, Type: "None" })
    TaxTypeList.push({ Id: 2, Type: "Inclusive" })
    TaxTypeList.push({ Id: 3, Type: "Exclusive" })
    return TaxTypeList;
  }


  updateOrderItem(UniqueNo: string) {
    this.currentOrder.forEach(element => {
      for (var index = 0; index < element.OrderItemWrapperList.length; index++) {
        if (element.OrderItemWrapperList[index].UniqueNo === UniqueNo) {
          element.OrderItemWrapperList[index].Quantity++;
          break;
        }
      }
    });

    this.CalculateOrder();
  }

  createNewOrder() {
    var order = new Order();
    order.StoreId = this.StoreId;
    order.StoreName = this.Store.StoreDisplayName;
    order.OrderUniqueNo = this.getUniqueNo();
    order.OrderItemWrapperList = [];
    this.currentOrder = [];
    this.currentOrder.push(order);
    this.CalculateOrder();
    return order;
  }

  addOrderItem(product: ProductWrapper, Quantity: number, Rate: number) {
    var order;
    if (this.currentOrder != undefined) {
      var temp = this.currentOrder[0];
      if (temp != undefined) {
        order = temp;
      }
      else {
        order = this.createNewOrder();
      }
    }
    else {
      order = this.createNewOrder();
    }

    var Count = 0;
    var AddOnIds = '';
    if (product.AddOnList != null && product.AddOnList.length > 0) {
      product.AddOnList.forEach((item) => {
        if (item.IsSelected) {
          if (AddOnIds == null) {
            AddOnIds = item.ProductId + item.Quantity;
          }
          else {
            AddOnIds = AddOnIds + ',' + item.ProductId + item.Quantity;
          }
        }
      });
    }

    if (product != undefined && product.ComboGroupList != undefined) {
      product.ComboGroupList.forEach(ComboGroup => {
        ComboGroup.ComboList.forEach(ComboItem => {
          if (ComboItem.IsSelected) {
            ComboItem.Quantity = ComboItem.Quantity > 0 ? ComboItem.Quantity : 1;
          }
        });
      });
    }

    var ComboIds = '';
    if (product.ComboGroupList != null && product.ComboGroupList.length > 0) {
      product.ComboGroupList.forEach((item) => {
        if (item.ComboList != null && item.ComboList.length > 0) {
          item.ComboList.forEach((item1) => {
            if (item1.IsSelected) {
              if (ComboIds == null) {
                ComboIds = item1.ComboGrpItemId + item1.Quantity;
              }
              else {
                ComboIds = ComboIds + ',' + item1.ComboGrpItemId + item1.Quantity;
              }
            }
          });
        }
      });
    }


    var OrderItemList = order.OrderItemWrapperList;
    for (var index = 0; index < OrderItemList.length; index++) {
      if (OrderItemList[index].ProductId === product.ProductId && OrderItemList[index].Rate == Rate && OrderItemList[index].Product.AddOnList == null && OrderItemList[index].Product.ComboGroupList == null && order.OrderItemWrapperList[index].KOTNo == 'NotAva') {
        OrderItemList[index].Quantity = OrderItemList[index].Quantity + Quantity;
        OrderItemList[index].Total = OrderItemList[index].Product.MinSalePrice * OrderItemList[index].Quantity;
        Count++;
        break;
      }
      else if (OrderItemList[index].ProductId === product.ProductId && OrderItemList[index].Rate == Rate) {
        if (AddOnIds == OrderItemList[index].GetAddonId && ComboIds == OrderItemList[index].GetComboItemId && order.OrderItemWrapperList[index].KOTNo == 'NotAva' && !order.OrderItemWrapperList[index].IsOfferItem) {
          OrderItemList[index].Quantity = OrderItemList[index].Quantity + Quantity;
          OrderItemList[index].Total = OrderItemList[index].Product.MinSalePrice * OrderItemList[index].Quantity;
          Count++;
          break;
        }
      }
    }

    if (Count == 0) {
      let Item = new OrderItem();
      Item.Product = JSON.parse(JSON.stringify(product));
      Item.ProductId = product.ProductId;
      Item.Quantity = Quantity;
      Item.IsKOTGenerated = false;
      Item.IsDeleted = false;
      Item.KOTNo = "NotAva";
      Item.Rate = Rate;
      Item.IsComplementary = false;
      Item.StartTime = new Date();
      Item.Total = Item.Rate * Item.Quantity;
      Item.UniqueNo = this.getUniqueNo();
      Item.TaxType = Item.Product.TaxType;
      Item.ItemDiscount = 0;
      order.OrderItemWrapperList.push(Item);
    }
    this.CalculateOrder();
  }

  decrease(item: OrderItem, storeId: number) {

    var temp = this.currentOrder;
    if (temp != undefined && temp.length > 0) {
      var order = temp[0];

      var idx = 0;
      for (var index = 0; index < order.OrderItemWrapperList.length; index++) {
        if (order.OrderItemWrapperList[index].UniqueNo === item.UniqueNo) {
          order.OrderItemWrapperList[index].Quantity--;
          order.OrderItemWrapperList[index].Total = order.OrderItemWrapperList[index].Rate * order.OrderItemWrapperList[index].Quantity;
          if (order.OrderItemWrapperList[index].Quantity == 0) {
            order.OrderItemWrapperList[index].IsCancelKOT = true;
            if (order.OrderItemWrapperList[index].Product.AddOnList != null) {
              order.OrderItemWrapperList[index].Product.AddOnList.forEach(element => {
                element.Quantity = 0;
              });
            }
          }
          idx = index;
          break;
        }
      }
      if (order.OrderItemWrapperList[idx].Quantity == 0 && order.OrderItemWrapperList[idx].Rate == item.Rate && order.OrderItemWrapperList[idx].KOTNo == 'NotAva') {
        order.OrderItemWrapperList.splice(idx, 1);
      }
    }
    this.CalculateOrder();
  }

  decreaseProduct(ProductId: number, storeId: number) {
    var temp = this.currentOrder;
    if (temp != undefined && temp.length > 0) {
      var order = temp[0];

      var idx = 0;
      for (var index = 0; index < order.OrderItemWrapperList.length; index++) {
        if (order.OrderItemWrapperList[index].ProductId === ProductId) {
          order.OrderItemWrapperList[index].Quantity--;
          order.OrderItemWrapperList[index].Total = order.OrderItemWrapperList[index].Rate * order.OrderItemWrapperList[index].Quantity;
          if (order.OrderItemWrapperList[index].Quantity == 0) {
            order.OrderItemWrapperList[index].IsCancelKOT = true;
            if (order.OrderItemWrapperList[index].Product.AddOnList != null) {
              order.OrderItemWrapperList[index].Product.AddOnList.forEach(element => {
                element.Quantity = 0;
              });
            }
          }
          idx = index;
          break;
        }
      }
      if (order.OrderItemWrapperList[idx].Quantity == 0 && order.OrderItemWrapperList[idx].ProductId == ProductId && order.OrderItemWrapperList[idx].KOTNo == 'NotAva') {
        order.OrderItemWrapperList.splice(idx, 1);
      }
    }
    this.CalculateOrder();
  }

  getUniqueNo() {
    var date = Date.now();
    if (date <= this.previousDate) {
      date = ++this.previousDate;
    } else {
      this.previousDate = date;
    }
    return date.toString();
  }

  CalculateOrder() {
    this.currentOrder.forEach(order => {
      if (order != undefined && order.OrderItemWrapperList != undefined && order.OrderItemWrapperList.length > 0) {
        order.CurrentPoints = 0;
        order.TaxSlabList = new Array<TaxSlabs>();

        order.DiscountValue = 0;
        order.TotalApplicableTaxInclusive = order.TotalApplicableTax = order.GrandTotal = 0;
        var Subtotal = 0;
        var DiscountPer = 0;
        var SubtotalForOrderPoints = 0;
        if (order.OfferList != undefined && order.Subtotal > 0) {
          var discountOffer = order.OfferList.find(x => x.SelectedOfferTypeMaster == 'GENERAL DISCOUNT');
          if (discountOffer != undefined) {
            if (!discountOffer.IsFixed) {
              DiscountPer = discountOffer.DiscountValue;
            }
            else {
              if (order.Subtotal > 0) {
                DiscountPer = (discountOffer.DiscountValue / (order.Subtotal || 0)) * 100;
              }
            }
          }
        }
        else if (order.DiscountPer > 0) {
          DiscountPer = order.DiscountPer;
        }
        else if (order.DiscountRs > 0 && order.Subtotal > 0) {
          DiscountPer = (order.DiscountRs / (order.Subtotal || 0)) * 100;
        }
        else if (order.LoyaltyDiscount > 0 && order.Subtotal > 0) {
          DiscountPer = (order.LoyaltyDiscount / (order.Subtotal || 0)) * 100;
        }
        for (var index = 0; index < order.OrderItemWrapperList.length; index++) {

          var SelectedProduct = order.OrderItemWrapperList[index];
          SelectedProduct.KOTTotal = 0;
          SelectedProduct.TotalTax = 0;
          if (SelectedProduct.Quantity > 0) {
            order.CurrentPoints = order.CurrentPoints + (SelectedProduct.LoyaltyPoints || 0);

            if (SelectedProduct.IsProductDiscountApply) {
              SelectedProduct.Total = (SelectedProduct.Rate * SelectedProduct.Quantity) - (SelectedProduct.ItemDiscount);
            }
            else {
              SelectedProduct.Total = SelectedProduct.Quantity * SelectedProduct.Rate;
            }
            if (SelectedProduct.Product.TaxMaster != null) {
              var ProductDiscountedPrize = (SelectedProduct.Total * ((DiscountPer / 100)));
              if (SelectedProduct.Product.TaxMaster != undefined && SelectedProduct.Product.TaxMaster.TaxItems.length > 0) {
                SelectedProduct.Product.TaxMaster.TaxItems.forEach(taxItem => {
                  var tax = (((SelectedProduct.Total - ProductDiscountedPrize) / 100) * taxItem.Value) || 0;
                  SelectedProduct.TotalTax = SelectedProduct.TotalTax + tax;
                  this.addToTaxSlab(taxItem.TaxItemId, taxItem.Name, tax, SelectedProduct.Product.RestaurantProduct, order);
                });

                if (SelectedProduct.Product.TaxType == 'Inclusive') {
                  order.TotalApplicableTaxInclusive = order.TotalApplicableTaxInclusive + SelectedProduct.TotalTax;
                }
                if (SelectedProduct.Product.TaxType == 'Exclusive') {
                  order.TotalApplicableTax = order.TotalApplicableTax + SelectedProduct.TotalTax;
                }
              }
            }

            if (SelectedProduct.Product.ComboGroupList != null && SelectedProduct.IsDeleted == false) {
              if (SelectedProduct.Product.ComboGroupList.length > 0) {
                var ComboSubTotal = 0;
                var AddComboItemList = this.GetComboItemList(SelectedProduct.Product.ComboGroupList) as any[];
                for (var comboIndex = 0; comboIndex < AddComboItemList.length; comboIndex++) {
                  if (AddComboItemList[comboIndex].IsSelected == true) {

                    var SelectedComboItem = AddComboItemList[comboIndex];

                    SelectedComboItem.Total = (SelectedComboItem.Quantity * SelectedComboItem.Price) * SelectedProduct.Quantity;
                    var ComboActualDiscountedValue = ((SelectedComboItem.Quantity) * ((DiscountPer / 100) * SelectedComboItem.Price));
                    SelectedComboItem.Product.TotalTax = 0;
                    if (!SelectedComboItem.Product.IsVariant && SelectedComboItem.Product.TaxMaster != null && SelectedComboItem.Price > 0) {
                      if (SelectedComboItem.Product.TaxMaster != undefined && SelectedComboItem.Product.TaxMaster.TaxItems.length > 0) {
                        SelectedComboItem.Product.TaxMaster.TaxItems.forEach(taxItem => {
                          var tax = (((SelectedComboItem.Total - ComboActualDiscountedValue) / 100) * taxItem.Value) || 0;
                          SelectedComboItem.Product.TotalTax = SelectedComboItem.Product.TotalTax + tax;
                          this.addToTaxSlab(taxItem.TaxItemId, taxItem.Name, tax, SelectedProduct.Product.RestaurantProduct, order);
                        });
                      }

                      if (SelectedComboItem.Product.TaxType == 'Inclusive') {
                        order.TotalApplicableTaxInclusive = order.TotalApplicableTaxInclusive + SelectedComboItem.Product.TotalTax;
                      }
                      if (SelectedComboItem.Product.TaxType == 'Exclusive') {
                        order.TotalApplicableTax = order.TotalApplicableTax + SelectedComboItem.Product.TotalTax;
                      }
                    }
                    else if (SelectedComboItem.Product.IsVariant && SelectedProduct.Product.TaxMaster != null && SelectedComboItem.Price > 0) {
                      if (SelectedProduct.Product.TaxMaster != undefined && SelectedProduct.Product.TaxMaster.TaxItems.length > 0) {
                        SelectedProduct.Product.TaxMaster.TaxItems.forEach(taxItem => {
                          var tax = (((SelectedComboItem.Total - ComboActualDiscountedValue) / 100) * taxItem.Value) || 0;
                          SelectedComboItem.Product.TotalTax = SelectedComboItem.Product.TotalTax + tax;
                          this.addToTaxSlab(taxItem.TaxItemId, taxItem.Name, tax, SelectedProduct.Product.RestaurantProduct, order);
                        });
                      }

                      if (SelectedProduct.Product.TaxType == 'Inclusive') {
                        order.TotalApplicableTaxInclusive = order.TotalApplicableTaxInclusive + SelectedComboItem.Product.TotalTax;
                      }
                      if (SelectedProduct.Product.TaxType == 'Exclusive') {
                        order.TotalApplicableTax = order.TotalApplicableTax + SelectedComboItem.Product.TotalTax;
                      }
                    }

                    ComboSubTotal = (ComboSubTotal || 0) + (SelectedProduct.Total || 0);
                    SelectedProduct.KOTTotal = (SelectedProduct.KOTTotal || 0) + (SelectedComboItem.Total || 0);
                  }
                }
              }
              else {
                SelectedProduct.KOTTotal = 0;
              }
            }

            Subtotal = (Subtotal || 0) + (SelectedProduct.Total || 0) + (SelectedProduct.KOTTotal || 0) + (SelectedProduct.ItemDiscount || 0);
            if (!false) {
              SubtotalForOrderPoints = (SelectedProduct.Total || 0) + (SelectedProduct.KOTTotal || 0) + (SubtotalForOrderPoints || 0);
            }
          }
        }
        order.SubtotalForOrderPoints = (SubtotalForOrderPoints || 0);
        order.Subtotal = Subtotal;

        if (order.Subtotal != 0) {
          if (discountOffer != undefined && discountOffer.SelectedOfferTypeMaster == 'GENERAL DISCOUNT') {
            if (discountOffer.IsFixed) {
              order.DiscountValue = discountOffer.DiscountValue;
            }
            else {
              order.DiscountValue = ((discountOffer.DiscountValue / 100) * order.SubtotalForOrderPoints);
            }
            if (discountOffer.MaxDiscountAmount > 0 && order.DiscountValue > discountOffer.MaxDiscountAmount) {
              order.DiscountValue = discountOffer.MaxDiscountAmount;
            }
          }
          else if (order.DiscountPer > 0) {
            order.DiscountValue = ((order.DiscountPer / 100) * order.Subtotal);
          }
          else if (order.DiscountRs > 0) {
            order.DiscountValue = order.DiscountRs || 0;
          }
          else if (order.LoyaltyDiscount > 0) {
            order.DiscountValue = order.LoyaltyDiscount || 0;
          }
          else {
            order.DiscountValue = 0;
          }
          order.OrderItemWrapperList.forEach(element => {
            if (element.Quantity > 0) {
              order.DiscountValue = (order.DiscountValue || 0) + (element.ItemDiscount || 0);
            }
          });
        }
        if (order.SaleOrderType == 'HomeDelivery') {
          order.DeliveryCharges = this.ContactlessSettings.FixedDeliveryCharge;
        }
        else{
          order.DeliveryCharges = 0;
        }
        order.GrandTotal = (order.Subtotal - (order.DiscountValue || 0)) + (order.TotalApplicableTax || 0) + ((order.DeliveryCharges || 0) + (order.PackagingCharges || 0) + (order.DecorationCharges || 0) + (order.MaintenanceCharges || 0)) + (order.ServiceCharge || 0);
        if (order.Channel != 'Zomato') {
          order.RoundOff = Math.round(order.GrandTotal) - order.GrandTotal;
          order.GrandTotal = Math.round(order.GrandTotal);
        }
      }
      else {
        order.CurrentPoints = 0;
        order.Subtotal = 0;
        order.TotalApplicableTax = 0;
        order.TotalApplicableTaxInclusive = 0;
        order.GrandTotal = 0;
        order.DiscountValue = 0;
        order.DiscountRs = 0;
        order.DiscountPer = 0;
        order.RoundOff = 0;
      }

      if (order != undefined && order.OrderItemWrapperList != undefined && order.OrderItemWrapperList.length > 0) {
        order.OrderItemWrapperList.forEach(element => {
          element.ComboString = '';
          element.ComboListString = [];
          element.ComboTotal = 0;
          if (element.Product.ComboGroupList != null && element.Product.ComboGroupList.length > 0) {
            element.Product.ComboGroupList.forEach((item) => {
              var ComboString = '';
              if (item.ComboList != null && item.ComboList.length > 0) {
                item.ComboList.filter(x => x.IsSelected).forEach((item1) => {
                  element.ComboTotal = element.ComboTotal + ((item1.Price * item1.Quantity) * element.Quantity);
                  var QuantityStr = '';
                  if (item1.Quantity > 1) {
                    QuantityStr = '(' + item1.Quantity + ')';
                  }
                  if (ComboString == null) {
                    ComboString = item1.ProductName + QuantityStr;
                  }
                  else {
                    ComboString = ComboString + ',' + item1.ProductName + QuantityStr;
                  }
                });
                if (item.ComboList.filter(x => x.IsSelected).length > 0) {
                  element.ComboListString.push(item.Name + ' : ' + ComboString + ' ');
                }
              }
            });
          }
          if (element.Product.AddOnList != null && element.Product.AddOnList.length > 0) {
            element.Product.AddOnList.forEach((item) => {
              if (item.IsSelected) {
                element.ComboTotal = element.ComboTotal + (item.MinSalePrice * item.Quantity);
                var QuantityStr = '';
                if (item.Quantity > 1) {
                  QuantityStr = '(' + item.Quantity + ')';
                }
                if (element.ComboString == null) {
                  element.ComboString = item.ProductName + QuantityStr;
                }
                else {
                  element.ComboString = element.ComboString + ',' + item.ProductName + QuantityStr;
                }
              }
            });
          }
        });
      }

      order.DiscountRs = order.DiscountValue;
    });

    // for (let i = this.currentOrder.length - 1; i >= 0; i--) {
    //   if (this.currentOrder[i].OrderItemWrapperList == undefined || this.currentOrder[i].OrderItemWrapperList.length == 0) {
    //     //alert('empty order');
    //     this.currentOrder.splice(i, 1); 
    //   }
    // }

    this.auth.setOrder(JSON.stringify(this.currentOrder));
  }

  addToTaxSlab(TaxItemId, Name, TotalTax, RestaurantProduct, order: Order) {
    if (order.TaxSlabList.find(element => element.TaxItemId == TaxItemId) ? true : false) {
      for (let index = 0; index < order.TaxSlabList.length; index++) {
        if (order.TaxSlabList[index].TaxItemId == TaxItemId) {
          order.TaxSlabList[index].TotalTax = order.TaxSlabList[index].TotalTax + (TotalTax || 0);
          break;
        }
      }
    }
    else {
      var TaxSlab = new TaxSlabs();
      TaxSlab.TaxItemId = TaxItemId;
      TaxSlab.Name = Name;
      TaxSlab.TotalTax = (TotalTax || 0);
      TaxSlab.RestaurantProductType = RestaurantProduct;
      order.TaxSlabList.push(TaxSlab);
    }
  }

  GetComboItemList(ComboGroupList) {
    var ComboItemList: any = [];
    if (ComboGroupList != null && ComboGroupList.length > 0) {
      for (var items = 0; items < ComboGroupList.length; items++) {
        for (var cmbitem = 0; cmbitem < ComboGroupList[items].ComboList.length; cmbitem++) {
          ComboItemList.push(ComboGroupList[items].ComboList[cmbitem]);
        }
      }
    }
    return ComboItemList;
  }

  get getTotalProduct() {
    var count = 0;
    if (this.currentOrder != undefined && this.currentOrder.length > 0) {
      this.currentOrder.forEach(element => {
        element.OrderItemWrapperList.forEach(x => {
          count = count + x.Quantity;
        });
      });
    }
    return count;
  }

  ResetOrder() {
    this.currentOrder = new Array<Order>();
    this.auth.setOrder(JSON.stringify(this.currentOrder));
  }

  ResetOrderItem() {
    // this.currentOrder.OrderItemWrapperList = new Array<OrderItem>();
    // this.auth.setOrder(JSON.stringify(this.currentOrder));
  }

  removeOffer(OfferId: number) {
    // this.currentOrder.OrderItemWrapperList = this.currentOrder.OrderItemWrapperList.filter(x => x.OfferId != OfferId);
    // this.currentOrder.OfferList = this.currentOrder.OfferList.filter(x => x.OfferId != OfferId);
    // this.CalculateOrder(this.currentOrder, true);
  }







  isDeliveryOrder() {
    // if (this.currentOrder.SaleOrderType == "HomeDelivery") {
    //   return true;
    // }
    // else if (this.currentOrder.SaleOrderType == "TakeAway") {
    //   return false;
    // }
    // else {
    //   return false;
    // }
    return true;
  }

  get deliverySlotString() {
    // if (this.currentOrder.DeliverySlotId > 0) {
    //   for (let index = 0; index < this.deliveryAppSetting.MasterDeliverySlots.length; index++) {
    //     const element = this.deliveryAppSetting.MasterDeliverySlots[index];

    //     for (let j = 0; j < element.DeliverySlotWrapperlist.length; j++) {
    //       const temp = element.DeliverySlotWrapperlist[index];
    //       if (temp.DeliverySlotId == this.currentOrder.DeliverySlotId) {
    //         return this.currentOrder.DeliveryDateString.toString() + " " + temp.Slot;
    //       }
    //     }
    //   }
    // }
    return 'Later';
  }

  get grandTotal(): number {
    var total = 0;
    if (this.currentOrder != undefined) {
      this.currentOrder.forEach(element => {
        total = total + element.GrandTotal;
      });
    }
    return total;
  }

  back(){
    this.location.back();
  }
}
