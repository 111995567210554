import { Component, OnInit, ViewChild, NgZone, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { AgmMap, MapsAPILoader } from '@agm/core';

import { Subject } from 'rxjs';
import { Address } from 'src/app/models/address';
import { BaseResponse } from 'src/app/models/apiResponse';
import { AddressService } from 'src/app/services/address.service';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { StorageService } from 'src/app/services/storage.service';
import { HttpClient } from '@angular/common/http';
import { UtilService } from 'src/app/services/util.service';


@Component({
  selector: 'app-address-edit',
  templateUrl: './address-edit.component.html',
  styleUrls: ['./address-edit.component.scss']
})
export class AddressEditComponent implements OnInit {
  @ViewChild(AgmMap, { static: true }) public agmMap: AgmMap;
  @ViewChild('search')
  public searchElementRef: ElementRef;

  lat;
  lng;
  address: Address;
  inProgress: boolean;
  onAddressSaved = new Subject<Address>();
  errors: any = {};
  flat;
  private geoCoder;


  constructor(private api: ApiService, public storage: StorageService,
    private auth: AuthService, private router: Router, private newAddress: AddressService, private http: HttpClient,
    private util: UtilService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone) {

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const longitude = position.coords.longitude;
        const latitude = position.coords.latitude;
        this.callApi(longitude, latitude);
      });
    } else {
      console.log("No support for geolocation")
    }
  }

  ngOnInit(): void {
    this.handlePermission()
    if (this.address == undefined) {
      this.address = new Address();
      this.address.AddressType = 'Work';
    }
    this.initAutoComplete()
  }

  handlePermission() {
    if (navigator.permissions && navigator.permissions.query)
    {
      navigator.permissions.query({ name: 'geolocation' }).then(function (result) {
        if (result.state == 'granted') {
          console.log(result.state);
          // this.getLocation();
          // this.isMapShow = true ;
          // isPromptbtn = false ;
        } else if (result.state == 'prompt') {
          console.log(result.state);
        } else if (result.state == 'denied') {
          console.log(result.state);
          // this.isMapShow = false ;
          //  isPromptbtn = true ;
        }
      });
    }
  }


  callApi(Longitude: number, Latitude: number) {
    console.log(Longitude, Latitude);
    this.lat = this.address.Latitude = Latitude;
    this.lng = this.address.Longitude = Longitude;
    setTimeout(() => {
      this.getAddress(this.lat, this.lng);
    }, 2000);
  }

  getAddress(lat: number, lng: number) {
    if (navigator.geolocation) {
      let geocoder = new google.maps.Geocoder();
      let latlng = new google.maps.LatLng(lat, lng);
      let request = { LatLng: latlng };
      geocoder.geocode({ location: latlng }, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          let result = results[0];
          let rsltAdrComponent = result.address_components;
          let resultLength = rsltAdrComponent.length;
          if (result != null) {
            // console.log(result.formatted_address)
            var myAddress = [];
            myAddress = result.address_components;
            console.log(result);
            const pin = myAddress[myAddress.length - 1]?.long_name
            this.address.AddressLine1 = myAddress[0]?.long_name + " " + myAddress[1]?.long_name;
            this.address.AddressLine2 = myAddress[2]?.long_name + " " + myAddress[3]?.long_name;
            this.address.City = myAddress[4]?.long_name;
            this.address.Pincode = pin;
          } else {
            this.util.showToast('No address available!', 'warning');
          }
        }
      });
    }
  }

  saveAddress() {
    if (this.validateAddress()) {
      this.inProgress = true;
      this.api.saveAddress(this.address).subscribe((data: BaseResponse) => {
        this.inProgress = false;
        if (data != null && data.IsSuccess) {
          if (!data.Result.IsAggregator) {
            this.storage.currentOrder[0].AddressId = data.Result.AddressId;
            this.address.AddressId = data.Result.AddressId;
            this.auth.setAddress(JSON.stringify(this.address));
            this.onAddressSaved.next(this.address);
          }
        }
        else {
          this.util.showToast('Something went wrong', 'warning');
        }
      },
        (error) => {
          this.inProgress = false;
          this.util.showToast(error, 'warning');
        });
    }
  }

  validateAddress() {
    this.errors = {};
    var isValidate = true;
    if (this.address.AddressLine1 == undefined || this.address.AddressLine1 == null || this.address.AddressLine1 == '') {
      isValidate = false;
      this.errors.addressLine1Message = 'This field required';
    }
    else if (this.address.AddressLine2 == undefined || this.address.AddressLine2 == null || this.address.AddressLine2 == '') {
      isValidate = false;
      this.errors.addressLine2Message = 'This field required';
    }
    else if (this.address.City == undefined || this.address.City == null || this.address.City == '') {
      isValidate = false;
      this.errors.addressLine3Message = 'City required';
    }
    else if (this.address.Pincode == undefined || this.address.Pincode == null || this.address.Pincode == '') {
      isValidate = false;
      this.errors.addressLine4Message = 'Pincode required';
    }
    return isValidate;
  }

  confirmLocation() {
    if (this.validateAddress()) {
      console.log(this.address, this.errors);
      this.newAddress.newAddress.next(this.address);
      this.api.saveAddress(this.address).subscribe((data: BaseResponse) => {
        this.inProgress = false;
        if (data != null && data.IsSuccess) {
          if (!data.Result.IsAggregator) {
            this.storage.currentOrder[0].AddressId = data.Result.AddressId;
            this.address.AddressId = data.Result.AddressId;
            this.auth.setAddress(JSON.stringify(this.address));
            this.onAddressSaved.next(this.address);
            this.router.navigate(['address-list']);
            this.util.showToast('Address Added', 'warning');
          }
        }
        else {
          this.util.showToast('Something went wrong', 'warning');
        }
      },
        (error) => {
          this.util.showToast(error, 'warning');
        });
    }
  }





  // _____________________________________________________________________________

  initAutoComplete() {
    this.mapsAPILoader.load().then(() => {
      // this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder;

      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          console.log(place.geometry.location.lat());
          console.log(place.geometry.location.lng());
          this.callApi(place.geometry.location.lng(), place.geometry.location.lat());
        });
      });
    });
  }

  markerDragEnd($event: any) {
    console.log($event);
    var lt = $event.coords.lat;
    var ln = $event.coords.lng;
    this.callApi(ln, lt);
  }

}
