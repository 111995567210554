export class Address {
    AddressId: number = 0;
    AddressType: string;
    Latitude: number;
    Longitude: number;
    AddressLine1: string;
    AddressLine2: string;
    City: string;
    State: string;
    Pincode: string;
    Country: string;
    TelNo: string;
    Location: string;
    Message : string;
    IsSelected: boolean;
    
    constructor() {

    }
}