import { Component, OnInit } from '@angular/core';
// import { BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { RouterService } from 'src/app/services/router.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-table-selection',
  templateUrl: './table-selection.component.html',
  styleUrls: ['./table-selection.component.scss']
})
export class TableSelectionComponent implements OnInit {

  constructor(public storage: StorageService, private router: RouterService,
    private api: ApiService) { }

  ngOnInit(): void {
    this.getFloors();
  }

  getFloors() {
    if (this.storage.FloorList == undefined || this.storage.FloorList.length == 0) {
      this.api.getFloors().subscribe((data: any) => {
        if (data != null && data.IsSuccess) {
          this.storage.FloorList = data.Result;
        }
      },
        (error) => {
          console.log(error);
        });
    }
  }

  onTableSelected(table) {
    this.storage.currentOrder[0].Table = table; 
    this.storage.CalculateOrder();
    this.router.redirect('menu');
  }

}
