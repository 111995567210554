import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
// import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { AlertComponent } from '../components/alert/alert.component';
import { ToastrService } from 'ngx-toastr';

declare var $: any;

@Injectable()
export class UtilService {
  loaderToShow: any;
  inProgress: boolean = false;
  public onLocationSelected = new Subject();

  constructor(
    private toastCtrl: ToastrService,
    // public loadingController: LoadingController,
    private location: Location,
    // private modalService: BsModalService
  ) { }

  async showToast(toastMsg: string, color: string) {
    this.toastCtrl.info(toastMsg);
  }

  showLoader(message?: string) {
    // if (environment.production){
    //   if (message == undefined) {
    //     message = 'Please wait..';
    //   }
    //   this.loaderToShow = this.loadingController.create({
    //     message: message,
    //     spinner: 'circles'
    //   }).then((res) => {
    //     res.present();
    //   });
    // }
  }

  hideLoader() {
    if (environment.production) {
      //this.loadingController.dismiss();
    }
  }

  backButton() {
    this.location.back();
  }

  toggleLoader() {
    this.inProgress = !this.inProgress;
  }

  showAlert(title, message) {
    // const initialState: ModalOptions = {
    //   initialState: {
    //     title: title,
    //     message: message
    //   }
    // };
    // var bsModalRef = this.modalService.show(AlertComponent, initialState);
    // bsModalRef.content.onAddressSaved.subscribe(data => {
    //   console.log(data);

    //   bsModalRef.hide();
    // });
  }

}
