import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { ApiService } from './services/api.service';
import { StorageService } from './services/storage.service';
import { UtilService } from './services/util.service';
import { filter } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  title = '';
  inInitialized: boolean = true;
  Menu = false;
  currentRoute: any;
  doc: any;
  menu = true;
  currentUrl: any;

  constructor(
    public storage: StorageService,
    public router: Router,
    public util: UtilService,
    private api: ApiService,
    private titleService: Title,
    private dom: DomSanitizer,
    private activatedRoute: ActivatedRoute
  ) {
    this.initializeApp();
    this.currentUrl = this.dom.bypassSecurityTrustResourceUrl(window.location.href);
  }

  initializeApp() {
  }

  init() {
    this.inInitialized = true;
    // this.util.toggleLoader();
    // this.api.Init().subscribe((data: any) => {
    //   if (data != null && data.IsSuccess) {
    //     this.inInitialized = true;
    //     this.storage.Store = data.Result.StoreDetail;
    //     this.storage.StoreId = data.Result.StoreDetail.StoreID;
    //     this.storage.ContactlessSettings = data.Result.ContactlessSettings;
    //     this.titleService.setTitle(this.storage.Store.StoreDisplayName);
    //   }
    //   this.util.toggleLoader();
    // },
    //   (error) => {
    //     console.log(error);
    //     this.util.toggleLoader();
    //   });
  }

  logout() {
    this.storage.logout();
  }

  showMenu() {
    this.Menu = !this.Menu;
  }
}