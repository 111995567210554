<!-- if user not logged in  -->
<div class="bg-gray-100 p-3 rounded-lg" *ngIf="!storage.isUserLoggedIn">
    <div class="flex justify-between">
        <div>
            <p class="text-xl font-bold">User</p>
            <p class="text-xs font-medium">+91-99999-xxxxx</p>
            <p class="text-xs font-medium">example@xyz.com</p>
        </div>
        <div>
            <img src="assets/img/user2.png" class="w-14 h-14 object-cover rounded-full ml-auto" alt="">
        </div>
    </div>
</div>

<!-- if user logged in -->
<div class="bg-gray-100 p-3 rounded-lg" *ngIf="storage.isUserLoggedIn">
    <div class="flex justify-between">
        <div>
            <p class="text-xl font-bold">{{storage.user.UserName}} <i class="feather-check-circle text-success"></i>
            </p>
            <p class="text-xs font-medium">+91-{{storage.user.MobileNo}}</p>
            <!-- <p class="text-xs font-medium">example@xyz.com</p> -->
        </div>
        <div>
            <img src="assets/img/user2.png" class="w-14 h-14 object-cover rounded-full ml-auto" alt="">
        </div>
    </div>
</div>


<div *ngIf="storage.isUserLoggedIn">
    <p class="text-xs font-bold capitalize my-3">my account</p>
    <div class="flex items-center mb-5">
        <div class="bg-gray-100 cursor-pointer rounded-xl p-3 text-center mr-3 w-28 h-24" (click)="manageAddress()">
            <i class="fa-solid fa-location-dot text-2xl text-black"></i>
            <p class="text-xs capitalize mt-3">manage address</p>
        </div>
        <div class="bg-gray-100 cursor-pointer rounded-xl p-3 text-center mr-3 w-28 h-24" (click)="myOrders()">
            <i class="fa fa-bag-shopping text-2xl text-black"></i>
            <p class="text-xs capitalize mt-3">my orders</p>
        </div>
    </div>
</div>



<!-- <p class="text-xs font-bold capitalize my-3">social links</p>
<div class="flex items-center my-5  mt-2">
    <a href="https://www.facebook.com/ciferonofficial/" target="_blank"><i
            class="fa-brands fa-facebook text-gray-500 mr-4 text-[20px]"></i></a>
    <a href="https://www.instagram.com/ciferon.pos/" target="_blank"><i
            class="fa-brands fa-instagram text-gray-500 mr-4 text-[20px]"></i></a>
    <a href="https://mobile.twitter.com/ciferonpos/" target="_blank"><i
            class="fa-brands fa-twitter text-gray-500 mr-4 text-[20px]"></i></a>
</div> -->

<p class="text-xs font-bold capitalize my-3">About</p>
<div class="items-center my-5  mt-2">
    <a class="text-xs" style="display: block; margin-bottom: 10px" routerLink="/about">About & Contact Us</a>
    <a class="text-xs" style="display: block; margin-bottom: 10px" routerLink="/tnc">Terms of Service</a>
    <a class="text-xs" style="display: block; margin-bottom: 10px" routerLink="/policy">Privacy Policy</a>
    <a class="text-xs" style="display: block; margin-bottom: 10px" routerLink="/refund">Refunds & Cancellations</a>
</div>

<!-- <p class="text-xs font-bold capitalize my-3" *ngIf="storage.Store.Fssai != undefined">Fssai Lic. No.
    {{storage.Store.Fssai}}
</p> -->

<div class="bg-gray-100 rounded-md px-3 py-3 text-center" *ngIf="storage.isUserLoggedIn">
    <label for="logout">
        <p class="text-sm cursor-pointer capitalize text-red-500">
            <i class="fa fa-power-off text-md mr-3"></i>
            Logout
    </label>
</div>

<ng-container *ngIf="!storage.isUserLoggedIn">
    <div class="text-center mt-10">
        <button class="bg-green-600 px-8 py-2 mt-4 text-white text-md rounded-md block mx-auto"
            [routerLink]="['/login']">Login</button>
        <p class="text-sm mt-3 text-black">Click on login to view your profile</p>
    </div>
</ng-container>







<!-- logout modal -->
<input type="checkbox" id="logout" class="modal-toggle">
<div class="modal modal-middle">
    <div class="w-full">
        <label for="logout"
            class="btn btn-sm btn-circle ml-auto mr-4 mb-[-20px] border-white relative z-10 flex w-8 h-8 bg-gray-800 text-white">
            <i class="fa-solid fa-times text-[17px]"></i>
        </label>
        <div class="modal-box relative w-[90%] mx-auto rounded-xl px-0 pt-3 pb-0 bg-white">
            <h3 class="text-xl font-bold px-8 my-2">Are you sure<br /> you want to logout ?</h3>
            <div class="w-full mt-7">
                <label class=" w-full py-3 text-white bg-[#5aa85a] text-sm" for="logout" (click)="storage.logout()">
                    <button class=" w-full py-3 text-white bg-[#5aa85a] text-sm pointer-events-none cursor-pointer"
                        for="login">Yes,Logout</button>
                </label>
            </div>
        </div>
    </div>
</div>
<!-- modal ends -->