import { Injectable } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class RouterService {

  constructor(private _router: Router, private route: ActivatedRoute, private storage: StorageService) { }

  redirectToMain() {
    this._router.navigate(['/home']);
  }

  redirect(route: string) {
    this._router.navigateByUrl(route);
  }

  redirectAndSkip(route: string) {
    this._router.navigateByUrl(route), { replaceUrl: true };
  }

  myMethodChangingQueryParams(queryParams: Params) {
    // const queryParams: Params = { sfsdfsd: value };
    this._router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: queryParams
      });
  }

  hasAccess(role, roleAction): boolean {
    var hasAccess = false;

    if (this.storage.RoleWrapper != undefined && !this.storage.RoleWrapper.IsAdmin && role != "NA") {
      if (roleAction == 'Read' && this.storage.RoleWrapper.ReadAccess != undefined && this.storage.RoleWrapper.ReadAccess.split(',').indexOf(role) != -1) {
        hasAccess = true;
      }
      else if (roleAction == 'Edit' && this.storage.RoleWrapper.EditAccess != undefined && this.storage.RoleWrapper.EditAccess.split(',').indexOf(role) != -1) {
        hasAccess = true;
      }
      else if (roleAction == 'Create' && this.storage.RoleWrapper.CreateAccess != undefined && this.storage.RoleWrapper.CreateAccess.split(',').indexOf(role) != -1) {
        hasAccess = true;
      }
      else if (roleAction == 'Delete' && this.storage.RoleWrapper.DeleteAccess != undefined && this.storage.RoleWrapper.DeleteAccess.split(',').indexOf(role) != -1) {
        hasAccess = true;
      }
      else {
        hasAccess = false;
      }
    }
    else {
      hasAccess = true;
    }
    return hasAccess;
  }

}
