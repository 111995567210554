<div>
    <div class="flex justify-between px-3 sticky top-0 bg-white py-2 z-10">
        <p class="text-gray-500 font-bold text-sm cursor-pointer"  (click)="storage.back()" ><i
                class="fa-solid fa-chevron-left mr-2"></i>BACK</p>
        <!-- <div class="cart-navigation cursor-pointer" routerLink="/menu">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round"
                    d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
            </svg>
        </div> -->
    </div>

<div class="osahan-profile row">
    <div class="col-md-12 mb-5">
        <div class="rounded shadow-sm p-4 bg-white">
            <h5 class="text-green-600 text-lg">Hey, where are you seated?</h5>
            <div class="mt-4" id="edit_profile" *ngFor="let floor of storage.FloorList">
                <label for="exampleInputName1" class="text-black mb-2">{{floor.FloorName}}</label>
                <div class="grid grid-cols-4 gap-3">
                    <div class="col-3" *ngFor="let table of floor.TableList">
                        <div (click) = "onTableSelected(table)" class="mb-2 text-black bg-[aliceblue] theme-shadow " style="
                        padding: 10px;
                        text-align: center;
                        border-radius: 5px;">
                            {{table.TableName}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>