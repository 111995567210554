import { Component, OnInit, ViewChild } from '@angular/core';
import { AgmMap, MapsAPILoader } from '@agm/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-testing',
  templateUrl: './testing.component.html',
  styleUrls: ['./testing.component.scss'],
})
export class TestingComponent implements OnInit {
  @ViewChild(AgmMap, { static: true }) public agmMap: AgmMap;

  lat ;
  lng ;


  constructor(private apiloader: MapsAPILoader, private http:HttpClient) {

    if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition((position)=>{
    const longitude = position.coords.longitude;
    const latitude = position.coords.latitude;
    this.callApi(longitude, latitude);
    });
    } else {
    console.log("No support for geolocation")
    }
  }

  ngOnInit(): void {

  }

  callApi(Longitude: number, Latitude: number){
    console.log(Longitude,Latitude);
    this.lat = Latitude ;
    this.lng = Longitude ;  

    var LOCATION = 'https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/reverseGeocode?f=pjson&featureTypes=StreetInt&location=' + this.lng + ',' + this.lat ;

    if(this.lat && this.lng){
      this.http.get(LOCATION).subscribe((result)=>{
        console.log(LOCATION);
      })
    }
  }

  
}
