import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AgmCoreModule } from '@agm/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { ApiService } from './services/api.service';
import { AuthService } from './services/auth.service';
import { ConstantService } from './services/constant.service';
import { DbService } from './services/db.service';
import { RouterService } from './services/router.service';
import { UtilService } from './services/util.service';
import { CommonModule, DatePipe, HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CustomHttpInterceptorService } from './services/custom-http-interceptor.service';
import { MenuService } from './services/menu.service';
import { StorageService } from './services/storage.service';
import { CartComponent } from './components/cart/cart.component';
import { LoginComponent } from './components/login/login.component';
import { FormsModule } from '@angular/forms';
import { AddressEditComponent } from './components/address-edit/address-edit.component';

// import { ModalModule } from 'ngx-bootstrap/modal';
import { OrderSuccessComponent } from './components/order-success/order-success.component';
import { OrderListComponent } from './components/order-list/order-list.component';
import { ModifierComponent } from './components/modifier/modifier.component';
import { AlertComponent } from './components/alert/alert.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { AddressListComponent } from './components/address-list/address-list.component';

import { CheckoutModule } from 'paytm-blink-checkout-angular';
import { PaymentStatusComponent } from './components/payment-status/payment-status.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { ProfileComponent } from './components/profile/profile.component';
import { StoreDetailComponent } from './components/store-detail/store-detail.component';
import { TableSelectionComponent } from './components/table-selection/table-selection.component';
import { LoaderComponent } from './components/loader/loader.component';
import { TestingComponent } from './components/testing/testing.component';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
import { PolicyComponent } from './components/policy/policy.component';
import { FaqComponent } from './components/faq/faq.component';
import { ProductListComponent } from './components/product-list/product-list.component';
import { RefundComponent } from './components/refund/refund.component';
import { AboutComponent } from './components/about/about.component';
// import { AccordionModule } from 'ngx-bootstrap/accordion';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    CartComponent,
    LoginComponent,
    AddressEditComponent,
    OrderSuccessComponent,
    OrderListComponent,
    ModifierComponent,
    AlertComponent,
    AddressListComponent,
    PaymentStatusComponent,
    ProfileComponent,
    StoreDetailComponent,
    TableSelectionComponent,
    LoaderComponent,
    TestingComponent,
    TermsAndConditionsComponent,
    PolicyComponent,
    FaqComponent,
    ProductListComponent,
    RefundComponent,
    AboutComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    // ModalModule.forRoot(),
    // AccordionModule.forRoot(),
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    CheckoutModule,
    NgOtpInputModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCZSXFbMdQ9a3A-4B1FbzDseVZZ57BZl3o',
      libraries: ['places']
    }),

  ],
  providers: [
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    ApiService, AuthService, ConstantService, DbService, RouterService, UtilService, StorageService,
    MenuService, DatePipe
  ],
  entryComponents: [AddressEditComponent, TableSelectionComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
