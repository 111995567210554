<div class="flex justify-between sticky top-[-2px] bg-white px-0 py-2">
    <p class="text-gray-500 font-bold text-sm cursor-pointer" routerLink="/profile"><i
            class="fa-solid fa-chevron-left mr-2"></i>BACK</p>
    <div class="cart-navigation cursor-pointer" routerLink="/menu">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
            stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round"
                d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
        </svg>
    </div>
</div>

<div class="p-3 text-xs">
    <div class="container">
        <p><strong>We will not disclose your personal information to any third parties for marketing
                purposes.</strong><br><br><span> </span></p>
        <p><br><strong>Introduction</strong><br><br></p>
        <p><span>This is the privacy and cookies policy for </span><span style="text-decoration: underline;"><span><a
                        class="font-bold">{{url}}</a></span></span><span> (Site)</span></p>
        <p><span> Our privacy and cookies policy describes the ways we capture your personal information what we do with
                it and what controls you have. We aim to ensure that any personal information we obtain and use about
                you will always be held, used and transmitted in compliance with all applicable data protection
                legislation.</span></p>
        <p><span> </span><br><strong>Our Core Commitment To You</strong><br><br></p>
        <p><span>We are committed to protecting your privacy on-line. We know that providing personal information is an
                act of trust and we take that trust seriously. We appreciate that you do not want the personal
                information you provide to us distributed indiscriminately or used in ways you would not expect.
            </span><span>{{url}}</span><span> will not disclose your personal information to any third parties for
                marketing purposes. </span></p>
        <p><span> Furthermore, communications of news or offers will only be sent to you where you are happy to receive
                these.</span></p>
        <p><span> For your ease of reading we have set out the key sections of our policy below. We do sometimes update
                this policy from time to time by changing it on the Site. You may therefore want to return here
                regularly to view our most up to date policy. </span></p>
        <p><span> <br><strong>What Information May We Collect About You?</strong><br><br></span></p>
        <p><span>The information we collect from you depends on what activities you are carrying out on the Site,
                whether you are logged in or not and whether you are making a purchase.</span></p>
        <p><span>We may collect and process the following information about you:</span></p>
        <p><span>information (such as your name, email address, postal address (including zip code), telephone number)
                that you provide by completing forms on the Site, including if you register as a user of the Site,
                subscribe to any service, upload or submit any material via the Site or request any information;</span>
        </p>
        <ul>
            <li><span style="line-height: 1.2;">in connection with an account sign-in facility, your user-name, password
                    and other log-in details;</span></li>
            <li><span style="line-height: 1.2;">details of any transactions made by you through the Site;</span></li>
            <li><span style="line-height: 1.2;">credit card details (such as card type, name on the card, long card
                    number, card expiry date and CV2 number) that you provide to enable you to make purchases from the
                    Site and that may be updated by you or via your card issuer from time to time;</span></li>
            <li><span style="line-height: 1.2;">details of changes to your account that you notify us of (such as
                    address changes);</span></li>
            <li><span style="line-height: 1.2;">ratings you provide in relation to products that are available on the
                    Site;</span></li>
            <li><span style="line-height: 1.2;">details of who you have successfully referred to the Site;</span></li>
            <li><span style="line-height: 1.2;">communications you send to us, for example to report a problem or to
                    submit queries, concerns or comments regarding the Site or its content;</span></li>
            <li><span style="line-height: 1.2;">information from surveys that we may, from time to time, run on the Site
                    for research purposes, if you choose to respond to them; and</span></li>
            <li><span style="line-height: 1.2;">details of your visits to the Site, the resources you access and any
                    data you download.</span></li>
        </ul>
        <p><span>You are under no obligation to provide any such information. However, if you should choose to withhold
                requested information, we may not be able to provide you with certain services.</span></p>
        <p><span> </span><br><strong>What Uses Are Made Of Your Information?</strong><br><br></p>
        <p><span>We will use the information you provide to:</span></p>
        <ul>
            <li><span style="line-height: 1.2;">enable us to process your orders and to provide you with the services
                    and information offered through the Site and which you request;</span></li>
            <li><span style="line-height: 1.2;">administer your account with us;</span></li>
            <li><span style="line-height: 1.2;">where available and applicable, verify and carry out financial
                    transactions in relation to payments you make online;</span></li>
            <li><span style="line-height: 1.2;">provide you with order and billing information;</span></li>
            <li><span style="line-height: 1.2;">establish eating trends in order to recommend other products you may
                    like to purchase;</span></li>
            <li><span style="line-height: 1.2;">audit the downloading of data from the Site;</span></li>
            <li><span style="line-height: 1.2;">improve the layout and/or content of the pages of the Site and
                </span><span style="line-height: 1.2;">customise</span><span style="line-height: 1.2;"> them for
                    users;</span></li>
            <li><span style="line-height: 1.2;">verify whether you have had a previous account with us to prevent
                    fraudulent take-up of our offers;</span></li>
            <li><span style="line-height: 1.2;">identify visitors to the Site;</span></li>
            <li><span style="line-height: 1.2;">carry out research on our users' demographics and tracking of sales
                    data; and</span></li>
            <li><span style="line-height: 1.2;">send you information we think you may find useful or which you have
                    requested from us, about our products and services, provided you have indicated that you do not
                    object to being contacted for these purposes.</span></li>
        </ul>
        <p><span>You can tell us not to contact you or tell us you do not wish us to continue to send you information in
                this way by contacting us using the </span><br><strong><a
                    href="http://www.graze.com/uk/help/privacy">Contact Details</a></strong><br><br><span> at the end of
                this
                privacy and cookies policy or following the instructions in the Marketing section below.</span></p>
        <p><br><strong>Additional Information</strong><br><br></p>
        <p><span>When you visit the Site, we may automatically collect additional information about you, such as the
                type of internet browser you use, the website from which you have come to the Site and your IP address
                (the unique address which identifies your computer on the internet) which is automatically
            </span><span>recognised</span><span> by our web server. You cannot be identified from this information and
                it is only used to assist us in providing an effective service on the Site, to collect broad demographic
                information for aggregate use, to assist us in the detection of fraud, to help identify problems with
                our server and to administer the Site.</span></p>
        <p><br><strong>Our use of Cookies</strong><br><br></p>
        <p><span>Cookies are small pieces of information which are issued to your computer when you visit a website and
                which store and sometimes track information about your use of that website. A number of cookies we use
                last only for the duration of your web session and expire when you close your browser. Other cookies are
                used to remember you when you return to the Site and will last for longer. Although they do identify a
                user's computer, cookies do not personally identify users.</span></p>
        <p><span>We use cookies for the following key reasons:</span></p>
        <p><em>Essential session management</em><span> </span></p>
        <p><span>Creating a specific log-in session for a visitor to our website in order that the site remembers that a
                visitor is logged in and that their page requests are delivered in an effective, secure and consistent
                manner.</span></p>
        <p><span>Recognizing when a visitor to the website has visited before; this means we can identify the number of
                unique visitors we receive to the website and allows us to make sure we have enough capacity for the
                number of users that we get.</span></p>
        <p><span>Recognizing when a visitor to the website is a registered member.</span></p>
        <p><span>We may also log information from your computer including the existence of cookies, your IP address and
                information about your browser program in order to allow us to diagnose problems, administer and track
                your usage of the Services.</span></p>
        <p><em>Functionality</em><span> </span></p>
        <p><span>Customizing elements of the promotional layout and/or content of the pages of the website </span></p>
        <p><em>Performance and measurement</em><span> </span></p>
        <p><span>Collecting statistical information about how our visitors use the website(s) so that we can improve the
                website(s) and learn which parts are most popular to visitors.</span></p>
        <p><br><strong>Disabling Cookies</strong><br><br></p>
        <p><span>Some of the cookies used by our website are set by us, and some by or with the help of third parties
                who are delivering services on our behalf. In particular we use third parties to help us collect
                performance and measurement statistics about our website. </span></p>
        <p><span> Most web browsers automatically accept cookies but, if you prefer, you can set your browser to disable
                cookies or to notify you when you receive a cookie, giving you the chance to decide whether or not to
                accept it. Please note however, that by blocking or deleting cookies used on the Site you may not be
                able to take full advantage of the Site as it may impair your use of or prevent access to some areas,
                including taking advantage of special offers and vouchers. If you do leave cookies turned on, please
                take care to sign off when you are finished if using a shared computer.</span></p>
        <p><span>You can also learn more about cookies by visiting </span><br><strong><a
                    href="http://www.allaboutcookies.org/">www.allaboutcookies.org</a></strong><br><br><span> which
                includes
                additional useful information on cookies and how to block cookies using different types of
                browser.</span></p>
        <p><br><strong>Emails</strong><br><br></p>
        <p><span>If you have ever provided us with an email address, we will be able to associate this email address
                back to your previous browsing and purchase experiences, whether you are logged on or not. We will know
                whether you receive email alerts and will be able to associate this with any other contact information
                you may have given us.</span></p>
        <p><br><strong>Will You Receive Marketing?</strong><br><br></p>
        <p><span>If you provide us with an email address, it will be entered into our regular email update service only
                where you have agreed to receiving such emails. From time to time, we may also contact you by post
                (where you have indicated that you are happy for us doing so) with information on special offers or
                events regarding our products or services. If you do not wish to continue to receive updates or
                marketing information, please amend your preferences in the My Account section of the Site.</span></p>
        <p><span>If you agreed to receiving marketing material at the time you information was collected from you, but
                subsequently change your mind and no longer wish to receive such communications, you can opt out of
                receiving future communications at any time. Please note when opting out that you may continue to
                receive some information from us where this is already in the communication pipeline for up to 1 day
                after the date on which you update your account.</span></p>
        <p><span>You may also unsubscribe from receiving marketing emails by using the </span><br><strong><a
                    href="http://www.graze.com/uk/help/privacy">Contact Details</a></strong><br><br><span> below.</span>
        </p>
        <p><br><strong>Editing And Cancelling Your Account</strong><br><br></p>
        <p><span>You can access 'My Account' from every page on the Site to update and correct the personal details that
                we gathered when you subscribed. Please note that we are required to retain certain information for a
                period of time after the closure of an account, such as for accounting, taxation and legal
                purposes</span></p>
        <p><br><strong>External Links</strong><br><br></p>
        <p><span>The Site may contain links to external websites. We assume no responsibility for the privacy practices
                or the content of those websites. Therefore, please read carefully any privacy policies on those
                websites before either agreeing to their terms or using those websites.</span>X</p>
        <p><br><strong>Information Sharing And Disclosure</strong><br><br></p>
        <p><span>We are committed to protecting your personal information. We will not disclose your personally
                identifiable information to third parties without your consent except where:</span></p>
        <p><span>We are required by law to disclose information to third parties.</span></p>
        <p><span>The service is necessary for the performance of our operations such as mail delivery, hosting services,
                protecting us from fraud, and payment of your account.</span></p>
        <p><span>Where you have referred a new customer to us your first name and first initial of your surname will be
                shared with the person you referred to us and you agree to that sharing.</span></p>
        <p><span>In the event that we undergo re-</span><span>organisation</span><span> or are sold to a third party,
                you agree that any personal information we hold about you may be transferred to that
                re-</span><span>organised</span><span> entity or third party.</span></p>
        <p><span>Companies and individuals registered in our affiliate program receive aggregate information about
                customers that they direct to us through links on their website. No personally identifiable information
                is provided to affiliate members; the information collected from our affiliates is used strictly for the
                affiliate program and will not be used for general marketing purposes.</span></p>
        <p><span>We also provide analyses of our customers in the aggregate to prospective partners, advertisers and
                other third parties. We do this so that we and our business partners can understand our customers
                better, and so we can keep bringing you first-rate services. We may also disclose, on an anonymous
                basis, statements made by our customers such as comments or feedback we receive on or products or
                service. At no time, however, will we disclose personal information about specific customers to
                prospective partners, advertisers and other third parties.</span></p>
        <p><br><strong>Security</strong><br><br></p>
        <p><span>We have implemented technology and security features, as well as strict internal guidelines, to
                safeguard the privacy of your personal information from loss, alteration,
            </span><span>unauthorised</span><span> access or improper use. We will continue to enhance our security
                procedures as new technology becomes available. Our security and privacy policies are periodically
                reviewed and enhanced as necessary. Whilst we cannot ensure or guarantee that loss, alteration,
            </span><span>unauthorised</span><span> access or improper use of information will never occur, we use all
                reasonable efforts to prevent it.</span></p>
        <p><span>While we make every effort to ensure that your information is secure on our system, you should bear in
                mind that submission of information over the Internet is never entirely secure. As a result, we cannot
                guarantee the security of any information you transmit to us, and you do so at your own risk.</span></p>
        <p><span>Your account information is password-protected for your privacy and security. You can play an important
                role in keeping your personal information secure by maintaining the confidentiality of your password
                used on the Site. We recommend that you change your password from time to time for additional security.
                Please advise us immediately if there is any </span><span>unauthorised</span><span> use of your account
                by any other Internet user or any other breach of security.</span></p>
        <p><span>Only key members of our personnel have access to personal information. It is advisable to close your
                browser when you have finished your user session to help ensure others do not access your personal
                information if you use a shared computer or a computer in a public place.</span></p>
        <p><br><strong>Storage Of Your Information</strong><br><br></p>
        <p><span>Personal information is processed and stored in our databases on secure servers. This is necessary in
                order to process the information. Information submitted by you may be transferred by us to our other
                offices and/or to the third parties mentioned in the circumstances described above.</span></p>
        <p><br><strong>Your Rights</strong><br><br></p>
        <p><span>On request, we will provide you with a copy of this information at no cost. You also have a right to
                correct any errors in that information. As mentioned above, you have a right to prevent the use of your
                personal information for direct marketing purposes.</span></p>


    </div>
</div>