import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'; 
import { Subject } from 'rxjs';
import { Address } from 'src/app/models/address';
import { BaseResponse } from 'src/app/models/apiResponse';
import { AddressService } from 'src/app/services/address.service';
import { ApiService } from 'src/app/services/api.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-address-list',
  templateUrl: './address-list.component.html',
  styleUrls: ['./address-list.component.scss']
})
export class AddressListComponent implements OnInit {

  addressList = [];
  
  onAddressSelected = new Subject<Address>();

  constructor(private router: Router, private newAddress: AddressService, private api: ApiService,
    public storage: StorageService) { }

  ngOnInit(): void {
    this.getAddress();
  }

  ngOnChanges(){
    this.getAddress();

  }

  selectAddress(address: Address) {
    this.onAddressSelected.next(address);
  }

  AddNewAddress(){
    this.router.navigate(['/address-edit'])
  }


  
  getAddress() {
    var latitude = 0;
    var longitude = 0;
    this.api.getAddress('HomeDelivery', latitude, longitude).subscribe((data: BaseResponse) => {
      if (data != null && data.IsSuccess) {
        this.addressList = data.Result;
        console.log(this.addressList)
      }
    },
      (error) => {
      });
  }

  changeSelected(value){
    for(let i=0; i<this.addressList.length; i++){
      if(this.addressList[i].AddressId === value){
        this.addressList[i].IsSelected = true;
      }
      else{
        this.addressList[i].IsSelected = false;
      }
    }
    this.newAddress.newAddress.next(this.addressList);
  }

}
