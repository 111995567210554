<!-- for Desktop view -->
<div class="desktop">
  <section class="desktop-container">
    <div class="inner-desktop">
      <div class="inner-left-div">
        <p class="uppercase font-bold text-[25px] text-brown-400">
          {{storage.Store.StoreDisplayName}}
        </p>
        <!-- <p class="font-bold text-[40px] leading-10 my-5">
          50 years old restaurant <br />
          near you
        </p> -->
        <p class="text-[20px] font-bold">
          {{storage.Store.Addres}}
        </p>
        <div class="flex items-center my-5">
          <a target="_blank" href="https://www.facebook.com/ciferonofficial/"><i
              class="fa-brands fa-facebook text-yellow-500 mr-4 text-[35px]"></i></a>
          <a target="_blank" href="https://www.instagram.com/ciferon.pos/"><i
              class="fa-brands fa-instagram text-yellow-500 mr-4 text-[35px]"></i></a>
          <a target="_blank" href="https://mobile.twitter.com/ciferonpos/">
            <i class="fa-brands fa-twitter text-yellow-500 mr-4 text-[35px]"></i>
          </a>
        </div>
      </div>
      <div class="inner-right-div">
        <div class="mockup-phone">
          <div class="display">
            <div class="artboard artboard-demo phone-1 relative">
              <!-- <iframe src="http://localhost:4200/" id="my-iframe" class="iframe-class" width="100%" height="100%" frameborder="0"></iframe> -->
              <iframe [src]="currentUrl" id="my-iframe" class="iframe-class" width="100%" height="100%"
                frameborder="0"></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

<!-- for mobile view -->
<div class="mobile">
  <div *ngIf="!inInitialized">
    <div class="spinner-border text-success" role="status" style="margin-top: 50%; left: 32%; position: relative">
      <span class="sr-only">Loading...</span>
    </div>

    <div class="loading loading01">
      <span>C</span>
      <span>I</span>
      <span>F</span>
      <span>E</span>
      <span>R</span>
      <span>O</span>
      <span>N</span>
    </div>
  </div>

  <div class="h-full" *ngIf="inInitialized">
    <section class="h-full p-3 bg-white relative">
      <div class="h-[100%] overflow-y-auto pb-[60px]">
        <router-outlet></router-outlet>
      </div>
     
    </section>
  </div>
</div>