<div>
    <div class="flex justify-between px-3 sticky top-0 bg-white py-2 z-10">
        <p class="text-gray-500 font-bold text-sm cursor-pointer" (click)="storage.back()"><i
                class="fa-solid fa-chevron-left mr-2"></i>BACK</p>
        <div class="cart-navigation cursor-pointer" routerLink="/menu">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round"
                    d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
            </svg>
        </div>
    </div>

    <!-- =================================== -->
    <div class="bg-white p-3">
        <div class="bg-white theme-shadow rounded-xl p-4 my-3">
            <p class="text-xs font-bold text-grey-300 py-1 px-4 border border-gray-300 w-[fit-content]">
                Order {{currentOrder?.OrderStatus}}</p>
            <div class="flex justify-between my-3">
                <p class="text-xs font-bold text-orange-400 uppercase">#{{currentOrder?.ExternalId}}</p>
                <p class="text-xs font-medium text-gray-400">{{currentOrder?.EntryDate | date :'MMM dd, hh:mm a'}}</p>
                <p class="text-[14px] font-bold" *ngIf="currentOrder?.TokenNumber > 0">
                    Use order no {{currentOrder.TokenNumber}} to collect your order
                </p>
            </div>
            <div class="border-b"></div>
            <div class="flex justify-between my-4" *ngFor="let item of currentOrder?.OrderItemWrapperList">
                <p class="text-xs font-bold">
                    <span class="border p-1 px-2 mr-3">x{{item?.Quantity}}</span>
                    {{item?.ProductName}}
                </p>
                <p class="text-xs font-bold">
                    ₹{{item?.Total}}
                </p>
            </div>
            <!-- <span *ngIf="currentOrder.PaymentModes != undefined">Paid Via {{currentOrder.PaymentModes[0]}}</span> -->
            <div class="border-b my-2"></div>

            <table class="w-full">
                <tr>
                    <td class="text-xs text-gray-400 py-2">Item Total</td>
                    <td class="text-xs text-gray-400 py-2 text-right">₹{{currentOrder?.Subtotal}}</td>
                </tr>
                <tr>
                    <td class="text-xs text-gray-400 py-2">Restaurant Taxes:</td>
                    <td class="text-xs text-gray-400 py-2 text-right">₹{{currentOrder?.TotalApplicableTax}}</td>
                </tr>
                <tr *ngIf="currentOrder?.DeliveryCharges">
                    <td class="text-xs text-gray-400 py-2">Delivery Charges:</td>
                    <td class="text-xs text-gray-400 py-2 text- right">₹{{currentOrder?.DeliveryCharges}}</td>
                </tr>
               
                <ng-container *ngIf="currentOrder.PaymentModes == undefined || currentOrder.PaymentModes.length == 0">
                    <tr>
                        <td class="text-xs font-semibold py-2">Amount remaining to pay:</td>
                        <td class="text-xs font-semibold py-2 text-right">₹{{currentOrder?.GrandTotal}}</td>
                    </tr>
                    <tr>
                        <td class="text-xs font-semibold py-2">Payment Mode:</td>
                        <td class="text-xs font-semibold py-2 text-right">COD</td>
                    </tr>
                </ng-container>

                <ng-container *ngIf="currentOrder.PaymentModes != undefined && currentOrder.PaymentModes.length > 0">
                    <tr>
                        <td class="text-xs font-semibold py-2">Payment Mode:</td>
                        <td class="text-xs font-semibold py-2 text-right">Online</td>
                    </tr>
                </ng-container>

                <tr>
                    <!-- <td class="text-xs font-medium py-2 text-gray-400">Fgbb, South city, Sector 48, Gurugram, India 122772</td> -->
                </tr>
            </table>

            <!-- <p class="border-red-400 border py-1 px-3 text-red-400 text-xs font-bold w-[fit-content] ml-auto">Cancel
                Order</p> -->
        </div>
        <!-- <button class="bg-green-600 text-lg uppercase my-6 text-white rounded-2xl w-full py-2">Order
            {{currentOrder?.OrderStatus}}</button> -->
    </div>

    <!-- track -->

    <div class="bg-white rounded-xl my-3" *ngIf = "false">
        <div class="grid grid-cols-2 gap-4 p-4">
            <div class="flex items-center">
                <img src="assets/img/profile.png" alt="profile" class="w-12 h-12 object-cover rounded-full mr-3">
                <div>
                    <p class="text-xs font-bold text-gray-500">Joseph Reid</p>
                    <p class="text-xs font-medium text-gray-400">KA 02 JB 3344</p>
                </div>
            </div>
            <div class="text-right">
                <div class="flex items-center justify-end">
                    <i class="fa-regular fa-clock text-lg mr-3 text-[14px] text-green-600"></i>
                    <p class="text-md font-medium text-gray-500">10 <span class="text-xs">mins</span> </p>
                </div>
                <p class="text-[9px] font-medium text-gray-500">Estimated time of Arrival</p>
            </div>
        </div>
        <div class="border-b"></div>

        <div class="flex items-center p-4">
            <i class="fa fa-bowl-rice text-green-600 mr-4 w-5"></i>
            <p class="text-xs font-medium text-gray-400">Your order is being prepared</p>
            <div class="flex items-center justify-center w-6 h-6 rounded-full bg-green-600 ml-auto ">
                <i class="fa fa-check text-sm text-white"></i>
            </div>
        </div>
        <div class="border-b"></div>
        <div class="flex items-center p-4">
            <i class="fa fa-user-tie text-green-600 mr-4 w-5"></i>
            <p class="text-xs font-medium text-gray-400">Delivery agent has picked up your order</p>
            <div class="flex items-center justify-center w-6 h-6 rounded-full bg-green-600 ml-auto ">
                <i class="fa fa-check text-sm text-white"></i>
            </div>
        </div>
        <div class="border-b"></div>
        <div class="flex items-center p-4">
            <i class="fa fa-person-biking text-green-600 mr-4 w-5"></i>
            <p class="text-xs font-medium text-gray-400">Your food arriving to your location</p>
            <div class="flex items-center justify-center w-6 h-6 rounded-full bg-green-600 ml-auto ">
                <i class="fa fa-check text-sm text-white"></i>
            </div>
        </div>
        <div class="border-b"></div>
        <div class="flex items-center p-4">
            <i class="fa fa-bowl-food text-green-600 mr-4 w-5"></i>
            <p class="text-xs font-medium text-gray-600">Your food is delivered !</p>
            <!-- <div class="flex items-center justify-center w-6 h-6 rounded-full bg-green-600 ml-auto ">
            <i class="fa fa-check text-sm text-white"></i>
        </div> -->
        </div>
        <div class="border-b"></div>
    </div>
</div>

<ng-container *ngIf="inProgress == true">
    <div class="flex items-center bg-white fixed top-0 left-0 justify-center h-[100%] w-full">
        <app-loader></app-loader>
    </div>
</ng-container>