<ng-container *ngIf="storage.isUserLoggedIn">
    <div class="bg-gray-100 p-3 rounded-lg">
        <div class="flex justify-between">
            <div>
                <p class="text-xl font-bold">{{storage.user.UserName}} <i class="feather-check-circle text-success"></i>
                </p>
                <p class="text-xs font-medium">+91-{{storage.user.MobileNo}}</p>
                <!-- <p class="text-xs font-medium">example@xyz.com</p> -->
            </div>
            <div>
                <img src="assets/img/user2.png" class="w-14 h-14 object-cover rounded-full ml-auto" alt="">
            </div>
        </div>
    </div>
    <div class="py-3">
        <p class="text-md font-bold">My Orders</p>
        <!-- current orders -->
        <ng-container *ngIf="orders != undefined && orders.length > 0">
            <div class="border-gray-200 border rounded-sm p-3 my-3"
                *ngFor="let order of orders; let indexOfelement=index;">
                <div *ngFor="let item of order.OrderItemWrapperList">
                    <p class="text-[14px] font-bold">{{item.Quantity}} x {{item.ProductName}}</p>
                </div>
                <!-- <p class="text-[14px] font-bold">Shawarma Roll X1, Shawarma Plate X1</p> -->
                <div class="flex justify-between">
                    <p class="text-[14px] font-medium text-gray-400">Rs. {{order.GrandTotal}}</p>
                    <p class="text-[14px] font-bold text-green-600">{{order.EntryDate | date :'MMM dd, hh:mm a'}}</p>
                </div>
                <div class="flex justify-between">
                    Payment Mode:
                    <p *ngIf="order.PaymentModes == undefined || order.PaymentModes.length == 0"
                        class="text-[14px] font-bold text-green-600">COD</p>
                    <p *ngIf="order.PaymentModes != undefined && order.PaymentModes.length > 0"
                        class="text-[14px] font-bold text-green-600">Paid online</p>
                </div>
                <div class="flex justify-between mt-3">
                    <button
                        class="bg-green-600 border border-green-600 font-bold py-1 rounded-md text-sm text-white w-full">
                        {{order.OrderStatus}}</button>
                    <button class="border border-green-600 text-sm py-1 font-bold rounded-md w-full ml-3"
                        (click)="trackOrder(order.OrderId)">
                        View</button>
                    <!-- <span *ngIf="order.PaymentModes == undefined || order.PaymentModes.length == 0">-</span> -->
                </div>
            </div>
        </ng-container>

        <div class="flex items-center justify-center py-5" *ngIf="orders == undefined || orders.length == 0">
            <div class="text-center">
                <i class="fa-solid fa-search text-green-600 text-[60px]"></i>
                <p class="font-weight-bold text-dark mt-5">No orders found</p>
                <p>we could not find any past orders.</p>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="!storage.isUserLoggedIn">
    <div class="h-full flex items-center justify-center">
        <div class="text-center">
            <i class="fa-solid fa-circle-user text-green-600 text-[60px]"></i>
            <button class="bg-green-600 px-8 py-2 mt-4 text-white text-md rounded-md block mx-auto"
                [routerLink]="['/login']">Login</button>
            <p class="text-sm mt-3 text-black">Click on login to see your past orders</p>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="inProgress == true">
    <div class="flex items-center bg-white fixed top-0 left-0 justify-center h-[100%] w-full">
        <app-loader></app-loader>
    </div>
</ng-container>