<section class="bg-white relative h-screen overflow-y-auto">

    <!-- <div class="flex justify-center w-full py-3 gap-2">
            <a href="#item1" class="home-carousel-indicator">1</a> 
            <a href="#item2" class="home-carousel-indicator">2</a> 
            <a href="#item3" class="home-carousel-indicator">3</a> 
            <a href="#item4" class="home-carousel-indicator">4</a>
          </div> -->
    <!-- carousel ends -->

    <!-- Cafe infomation -->
    <div class="flex">
        <div class="w-[90%]" *ngIf = "store == undefined">
            <p class="text-3xl font-bold text-green-600 mb-2">{{company.Name}}</p>
            <!-- <div class="flex items-center">
                <i class="fa-solid fa-bowl-food mr-2 w-5 text-black"></i>
                <p class="text-[16px] font-bold text-black">
                    Salads, Drinks, Shakes
                </p>
            </div> -->
            <!-- <div class="flex pl-1">
                <i class="fa-solid fa-location-dot mr-2 w-4 flex items-center text-black"></i>
                <p class="text-sm text-gray-400">
                    {{company.Address}}
                </p>
            </div> -->

        </div>

        <div class="w-[90%]" *ngIf = "store != undefined">
            <p class="text-3xl font-bold text-green-600 mb-2">{{store.StoreDisplayName}}</p>
            <!-- <div class="flex items-center">
                <i class="fa-solid fa-bowl-food mr-2 w-5 text-black"></i>
                <p class="text-[16px] font-bold text-black">
                    Salads, Drinks, Shakes
                </p>
            </div> -->
            <div class="flex pl-1">
                <i class="fa-solid fa-location-dot mr-2 w-4 flex items-center text-black"></i>
                <p class="text-sm text-gray-400">
                    {{store.Addres}}
                </p>
            </div>

        </div>

        <!-- <div class="w-[10%] address-icon-div pt-3">
            <a (click)="onCallClicked()">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round"
                        d="M16 3h5m0 0v5m0-5l-6 6M5 3a2 2 0 00-2 2v1c0 8.284 6.716 15 15 15h1a2 2 0 002-2v-3.28a1 1 0 00-.684-.948l-4.493-1.498a1 1 0 00-1.21.502l-1.13 2.257a11.042 11.042 0 01-5.516-5.517l2.257-1.128a1 1 0 00.502-1.21L9.228 3.683A1 1 0 008.279 3H5z" />
                </svg>
            </a>
            <a (click)="onMapClicked()">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round"
                        d="M16 15v-1a4 4 0 00-4-4H8m0 0l3 3m-3-3l3-3m9 14V5a2 2 0 00-2-2H6a2 2 0 00-2 2v16l4-2 4 2 4-2 4 2z" />
                </svg>
            </a>
        </div> -->
    </div>

    <!-- carousel starts -->
    <div class="carousel w-full mb-4 mt-4" *ngIf = "false">
        <div id="item1" class="carousel-item w-full">
            <img src="assets/img/dummybannerimage.jpg" class="w-full rounded-2xl object-cover theme-shadow"
                style="height: 16rem;" *ngIf="offer.ImgUrl == undefined" />
            <img class="w-full rounded-2xl object-cover theme-shadow" style="height: 16rem;"
                *ngIf="offer.ImgUrl != undefined" src="{{offer.ImgUrl}}" />
        </div>
        <!-- <div id="item2" class="carousel-item w-full">
            <img src="assets/img/dummybannerimage.jpg" class="w-full rounded-2xl object-cover theme-shadow h-36" />
        </div>
        <div id="item3" class="carousel-item w-full">
            <img src="assets/img/dummybannerimage.jpg" class="w-full rounded-2xl object-cover theme-shadow h-36" />
        </div>
        <div id="item4" class="carousel-item w-full">
            <img src="assets/img/dummybannerimage.jpg" class="w-full rounded-2xl object-cover theme-shadow h-36" />
        </div> -->
    </div>

    <div>
        <div class="w-100" *ngIf = "offer != undefined">
            <p class="text-3xl font-bold text-green-600 mb-2" style="text-align: center;
            text-transform: uppercase;
            margin-top: 20px;">{{offer.Name}}</p>
            <div class="pl-1">
                <p class="text-gray-400 text-[16px]">
                    {{offer.OfferDescription}}
                </p>
            </div>

        </div>

        <div class="w-100" *ngIf = "offer == undefined" style="margin-top: 200px;">
            <p class="text-3xl font-bold text-green-600 mb-2" style="text-align: center;
            text-transform: uppercase;
            margin-top: 20px;">Welcome!</p>
            <div class="pl-1">
                <p class="text-gray-400 text-[16px]">
                    Register yourself now to get exciting offers from {{store.StoreDisplayName}}
                </p>
            </div>

        </div>

    </div>

    <div>

    </div>

    <div class="fixed bottom-0 w-[100%] z-[99] left-0 right-0 px-3 pb-3 mx-auto bg-white">
        <div class="w-full mt-7">
            <label class=" w-full py-3 text-white bg-[#5aa85a] text-sm">
                <button (click)="storage.isLoginVisible = true"
                    class=" w-full py-3 text-white bg-[#5aa85a] text-sm pointer-events-none cursor-pointer">
                    <ng-container *ngIf = "offer != undefined">Get Coupon</ng-container>
                    <ng-container *ngIf = "offer == undefined">Register</ng-container>
                </button>
            </label>
            
            <!-- <a href="https://ciferon.com/" style="text-align: center;">Powered by Ciferon</a> -->
        </div>
    </div>
    <!--       
    <ng-container *ngIf="!filteredCategoryList">
        <div class="flex items-center z-[99] bg-white fixed top-0 left-0 justify-center h-[100%] w-full">
            <app-loader></app-loader>
        </div>
    </ng-container> -->
</section>

<!-- loader -->
<ng-container *ngIf="inProgress">
    <div class="fixed bg-[rgba(0,0,0,0.8)] h-full w-full top-0 left-0 z-[9999] flex items-center justify-center">
        <i class="fa-solid fa-circle-notch text-white ml-3 text-2xl animate-spin"></i>
    </div>
</ng-container>
<!-- ends -->

<!--Customisation modal starts  -->
<ng-container *ngIf="storage.isLoginVisible">
    <div class="bg-[rgba(0,0,0,0.6)] fixed left-0 w-full h-full z-[9999] top-0  flex items-end justify-center">
        <div class="items-end w-full">
            <div class="w-full">
                <!-- <label id="close-modifier-modal"
                    class="btn btn-sm btn-circle mx-auto mb-5 flex w-16 h-16 bg-white text-gray-600  text-lg"
                    >
                    <i class="fa-solid fa-times text-[28px]"></i>
                </label> -->
                <app-login></app-login>
            </div>
        </div>
    </div>
</ng-container>