import { Component, Input, OnInit } from '@angular/core';
import { ComboGrpItem, Product } from 'src/app/models/product';
import { UtilService } from 'src/app/services/util.service';
import { StorageService } from 'src/app/services/storage.service';
import { Subject } from 'rxjs';
import { ProductWrapper } from 'src/app/models/ProductWrapper';
import { AddressService } from 'src/app/services/address.service';
// import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modifier',
  templateUrl: './modifier.component.html',
  styleUrls: ['./modifier.component.scss']
})
export class ModifierComponent implements OnInit {

  @Input() setProduct: any;
  @Input() mode: string;
  closeResult: string;
  Quantity: number;
  public onClose: Subject<any>;
  public onAdd: Subject<any>;
  currentProduct: ProductWrapper;
  VariantItemId: number;

  constructor(
    public utility: UtilService,
    public storage: StorageService,
    private Customize: AddressService) {
  }

  ngOnInit() {
    this.Quantity = 1;
    this.onClose = new Subject();
    this.onAdd = new Subject();
    this.Customize.newCustomize.subscribe((result)=> {
      // console.log(result);
      this.currentProduct = result.initialState.currentProduct  ;
      console.log(result.initialState.currentProduct);
      
    })

    if (this.currentProduct.IsVariantPresent && this.currentProduct.VariationItems.length > 0) {
      this.VariantItemId = this.currentProduct.VariationItems[0].VariantItemId;
    }
  }

  AddCart() {
    if (this.validatePopup()) {
      if (this.currentProduct.IsVariantPresent) {
        var variation = this.currentProduct.VariationItems.find(x => x.VariantItemId == this.VariantItemId);
        variation.Product.ComboGroupList = [];
        var VarientCombo = this.currentProduct.VariationItems.find(x => x.VariantItemId == this.VariantItemId && x.ModifierGroupIdIds.length > 0);
        if (VarientCombo != undefined) {
          this.currentProduct.ComboGroupList.filter(x => x.VariantItemId == this.VariantItemId).forEach(element => {
            element.VariantItemId = undefined;
            variation.Product.ComboGroupList.push(element);
          });
        }
        this.storage.addOrderItem(variation.Product, this.Quantity, variation.Price);
        this.Close();
      }
      else {
        this.storage.addOrderItem(this.currentProduct, this.Quantity, this.currentProduct.MinSalePrice);
        this.Close();
      }
    }
  }

  decreaseAddon(productId) {

  }

  increaseAddon(productId) {

  }

  onVariantSelected(VariantItemId) {
    this.VariantItemId = VariantItemId;
    this.currentProduct.ComboGroupList.forEach(ComboGroup => {
      ComboGroup.ComboList.forEach(ComboItem => {
        ComboItem.IsSelected = false;
        ComboItem.Quantity = 0;
        ComboItem.Total = 0;
      });
    });
  }

  filterCombos(ComboGroupList: any[]) {
    if (!this.currentProduct.IsVariantPresent) {
      return ComboGroupList;
    }
    else {
      var VarientCombo = this.currentProduct.VariationItems.find(x => x.VariantItemId == this.VariantItemId && x.ModifierGroupIdIds.length > 0);
      if (VarientCombo != undefined) {
        return ComboGroupList.filter(x => x.VariantItemId == VarientCombo.VariantItemId);
      }
    }
  }

  filterComboItems(ComboGrpItems: ComboGrpItem[]) {
    return ComboGrpItems
  }

  onComboSelected(ComboGroupId: number, ComboGrpItemId: number, IsSelected: boolean) {
    this.currentProduct.ComboGroupList.filter(x => x.VariantItemId == this.VariantItemId).forEach(ComboGroup => {
      if (ComboGroup.ComboGroupId == ComboGroupId) {
        if (!this.currentProduct.IsVariantPresent) {
          ComboGroup.ComboList.forEach(ComboItem => {
            if (ComboItem.ComboGrpItemId == ComboGrpItemId) {
              if (IsSelected) {
                ComboItem.Quantity = 0;
                ComboItem.Total = 0;
              }
              else {
                ComboItem.Quantity = 1;
                ComboItem.Total = ComboItem.Quantity * ComboItem.Price;
              }
            }
          });
        }
        else {
          ComboGroup.ComboList.forEach(ComboItem => {
            if (ComboItem.ComboGrpItemId == ComboGrpItemId) {
              if (IsSelected) {
                ComboItem.Quantity = 0;
                ComboItem.Total = 0;
              }
              else {
                ComboItem.Quantity = 1;
                ComboItem.Total = ComboItem.Quantity * ComboItem.Price;
              }
            }
          });
        }
      }
    });
  }

  Selected(ComboGroupId: number, ComboGrpItemId: number, HasVariant: boolean) {
    this.currentProduct.ComboGroupList.forEach(ComboGroup => {
      ComboGroup.ComboList.forEach(ComboItem => {
        ComboItem.IsSelected = true;
        ComboItem.Quantity = 0;
        ComboItem.Total = 0;
      });
    });
  }

  Close() {
    document.getElementById('close-modifier-modal')?.click();
    this.Quantity = 1;
    this.VariantItemId = 0;
  }

  get ComboTotal() {
    var Total = 0;

    if (this.currentProduct.IsVariantPresent) {
      Total = Total + this.currentProduct.VariationItems.filter(x => x.VariantItemId == this.VariantItemId)[0].Price;
    }
    else{
      Total = this.currentProduct.MinSalePrice;
    }
    if (this.currentProduct.ComboGroupList != null && this.currentProduct.ComboGroupList.length > 0) {
      this.currentProduct.ComboGroupList.forEach((item) => {
        if (item.ComboList != null && item.ComboList.length > 0) {
          item.ComboList.forEach((item1) => {
            if (item1.IsSelected) {
              Total = Total + (item1.Price * item1.Quantity);
            }
          });
        }
      });
    }
    return Total;
  }

  get hasAddon(): boolean {
    if (this.currentProduct.AddOnList != undefined && this.currentProduct.AddOnList.length > 0)
      return true;
    else
      return false;
  }

  validatePopup(): boolean {
    var result = true;
    if (this.currentProduct.VariationItems != null) {
      if (this.currentProduct.VariationItems.filter(x => x.VariantItemId == this.VariantItemId).length == 0) {
        this.utility.showToast('Please select any variant', 'danger');
        result = false;
      }
    }
    (this.currentProduct.IsVariantPresent ? this.currentProduct.ComboGroupList.filter(x => x.VariantItemId == this.VariantItemId) : this.currentProduct.ComboGroupList).forEach(comboGroup => {
      comboGroup.ErrorMsg = undefined;
      if (comboGroup.GroupQty > 0) {
        var SelectedQuantity = 0;
        comboGroup.ComboList.forEach(element => {
          if (element.IsSelected) {
            SelectedQuantity = SelectedQuantity + element.Quantity;
          }
        });
        if (SelectedQuantity > comboGroup.GroupQty) {
          comboGroup.ErrorMsg = "Allowed Quantity for " + comboGroup.Name + " is " + comboGroup.GroupQty + ", but selected quantity is " + SelectedQuantity;
        }
      }
      else {
        var SelectedQuantity = 0;
        comboGroup.ComboList.forEach(element => {
          if (element.IsSelected) {
            SelectedQuantity++
          }
        });
        if (SelectedQuantity > comboGroup.MaximumQty) {
          comboGroup.ErrorMsg = "Maximum Quantity for " + comboGroup.Name + " is " + comboGroup.MaximumQty + ", but selected quantity is " + SelectedQuantity;
        }
        if (SelectedQuantity < comboGroup.MinimumQty) {
          comboGroup.ErrorMsg = "Minimun Quantity for " + comboGroup.Name + " is " + comboGroup.MinimumQty + ", but selected quantity is " + SelectedQuantity;
        }
      }
      if (comboGroup.ErrorMsg != undefined) {
        this.utility.showToast(comboGroup.ErrorMsg, 'danger');
        result = false;
      }
    });
    return result;
  }

  incrementQuantity(qty) {
    this.Quantity = this.Quantity + qty;
  }

  decrementQuantity() {
    if (this.Quantity > 1) {
      this.Quantity = this.Quantity - 1;
    }
  }

  decreaseCombo(ComboGroupId: number, ComboGrpItemId: number) {
    this.currentProduct.ComboGroupList.filter(x => x.VariantItemId == this.VariantItemId).forEach(ComboGroup => {
      if (ComboGroup.ComboGroupId == ComboGroupId) {
        if (!this.currentProduct.IsVariantPresent) {
          ComboGroup.ComboList.forEach(ComboItem => {
            if (ComboItem.ComboGrpItemId == ComboGrpItemId) {
              ComboItem.Quantity = ComboItem.Quantity - 1;
              ComboItem.Total = ComboItem.Quantity * ComboItem.Price;
              if (ComboItem.Quantity == 0) {
                ComboItem.IsSelected = false;
              }
            }
          });
        }
        else {
          ComboGroup.ComboList.forEach(ComboItem => {
            if (ComboItem.ComboGrpItemId == ComboGrpItemId) {
              ComboItem.Quantity = ComboItem.Quantity - 1;
              ComboItem.Total = ComboItem.Quantity * ComboItem.Price;
              if (ComboItem.Quantity == 0) {
                ComboItem.IsSelected = false;
              }
            }
          });
        }
      }
    });
  }

  increaseCombo(ComboGroupId: number, ComboGrpItemId: number) {
    this.currentProduct.ComboGroupList.filter(x => x.VariantItemId == this.VariantItemId).forEach(ComboGroup => {
      if (ComboGroup.ComboGroupId == ComboGroupId) {
        if (!this.currentProduct.IsVariantPresent) {
          ComboGroup.ComboList.forEach(ComboItem => {
            if (ComboItem.ComboGrpItemId == ComboGrpItemId) {
              ComboItem.Quantity = ComboItem.Quantity + 1;
              ComboItem.Total = ComboItem.Quantity * ComboItem.Price;
            }
          });
        }
        else {
          ComboGroup.ComboList.forEach(ComboItem => {
            if (ComboItem.ComboGrpItemId == ComboGrpItemId) {
              ComboItem.Quantity = ComboItem.Quantity + 1;
              ComboItem.Total = ComboItem.Quantity * ComboItem.Price;
            }
          });
        }
      }
    });
  }

  increase() {
    this.Quantity = this.Quantity + 1;
  }

  decrease() {
    if (this.Quantity == 1) {
      return;
    }
    else {
      this.Quantity = this.Quantity - 1;
    }
  }
}