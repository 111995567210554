import { Component, OnInit } from '@angular/core';
import { StorageService } from 'src/app/services/storage.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  constructor(public storage : StorageService,private router: Router) { }

  ngOnInit(): void {
  }

  call(){
    window.open('tel:' + this.storage.Store.SupportContact);
  }
  manageAddress(){
    this.router.navigate(['/address-list'])
  }
  myOrders(){
    this.router.navigate(['/orders'])
  }

}
