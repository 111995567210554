<div class="flex justify-between sticky top-[-2px] bg-white px-0 py-2">
    <p class="text-gray-500 font-bold text-sm cursor-pointer" routerLink="/profile"><i
            class="fa-solid fa-chevron-left mr-2"></i>BACK</p>
    <div class="cart-navigation cursor-pointer" routerLink="/menu">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
            stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round"
                d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
        </svg>
    </div>
</div>

<div class="p-3 text-xs">

    1. Amount once paid through the payment gateway shall not be refunded other than in the
    following circumstances:
    <br><br> Multiple times debiting of Customer’s Card/Bank Account due to technical error OR Customer's
    account being debited with excess amount in a single transaction due to technical error. In such
    cases, excess amount excluding Payment Gateway charges would be refunded to the Customer.
    <br><br> Due to technical error, payment being charged on the Customer’s Card/Bank Account but the
    enrolment for the examination is unsuccessful. Customer would be provided with the enrolment
    by NISM at no extra cost. However, if in such cases, Customer wishes to seek refund of the
    amount, he/she would be refunded net the amount, after deduction of Payment Gateway
    charges or any other charges.
    <br><br>
    2. The Customer will have to make an application for refund along with the transaction number
    and original payment receipt if any generated at the time of making payments.
    <br><br>
    
    3. The application will be processed manually and after verification, if the claim is found valid, the
    amount received in excess will be refunded by NISM through electronic mode in favor of the
    applicant and confirmation sent to the mailing address given in the online registration form,
    within a period of 21 calendar days on receipt of such claim. It will take 3-21 days for the money
    to show in your bank account depending on your bank’s policy.
    <br><br>
    4. Company assumes no responsibility and shall incur no liability if it is unable to affect any
    Payment Instruction(s) on the Payment Date owing to any one or more of the following
    circumstances:
    a. If the Payment Instruction(s) issued by you is/are incomplete, inaccurate, and invalid and
    delayed.
    b. If the Payment Account has insufficient funds/limits to cover for the amount as mentioned
    in the Payment Instruction(s)
    c. If the funds available in the Payment Account are under any encumbrance or charge.
    d. If your Bank or the NCC refuses or delays honoring the Payment Instruction(s)
    e. Circumstances beyond the control of Company (including, but not limited to, fire, flood,
    natural disasters, bank strikes, power failure, systems failure like computer or telephone
    lines breakdown due to an unforeseeable cause or interference from an outside force)
    f. In case the payment is not effected for any reason, you will be intimated about the failed
    payment by an e-mail
    <br><br>
    5. User agrees that Company, in its sole discretion, for any or no reason, and without penalty, may
    suspend or terminate his/her account (or any part thereof) or use of the Services and remove
    and discard all or any part of his/her account, user profile, or his/her recipient profile, at any
    time. Company may also in its sole discretion and at any time discontinue providing access to
    the Services, or any part thereof, with or without notice. User agrees that any termination of his
    /her access to the Services or any account he/she may have or portion thereof may be effected
    without prior notice, and also agrees that Company will not be liable to user or any third party
    for any such termination. Any suspected, fraudulent, abusive or illegal activity may be referred
    to appropriate law enforcement authorities. These remedies are in addition to any other
    remedies Company may have at law or in equity. Upon termination for any reason, user agrees
    to immediately stop using the Services.
    <br><br>
</div>