import { Location } from '@angular/common';
import { Component, OnInit, ElementRef, Input } from '@angular/core';
// import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { Category } from 'src/app/models/category';
import { Order } from 'src/app/models/order';
import { Product } from 'src/app/models/product';
import { ProductWrapper } from 'src/app/models/ProductWrapper';
import { AddressService } from 'src/app/services/address.service';
import { ApiService } from 'src/app/services/api.service';
import { RouterService } from 'src/app/services/router.service';
import { StorageService } from 'src/app/services/storage.service';
import { UtilService } from 'src/app/services/util.service';
import { ModifierComponent } from '../modifier/modifier.component';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss']
})
export class ProductListComponent implements OnInit {
  
  @Input() productList : ProductWrapper[];

  showCustomization: boolean;
  searchString: string;
  containProductDetails: ProductWrapper;

  constructor(private util: UtilService, private location: Location, public storage: StorageService, private api: ApiService,
    private router: RouterService,private  ElByClassName: ElementRef, private Customize: AddressService) { }

  ngOnInit(): void {
  }

  increment(product: ProductWrapper, IsNew: boolean, UniqueNo: string) {
    if (IsNew) {
      if (product.IsVariantPresent || (product.ComboGroupList != undefined && product.ComboGroupList.length > 0)) {
        this.openModifier(product);
      }
      else {
        this.storage.addOrderItem(product, 1, product.MinSalePrice);
        console.log(product) ;
      }
    }
    else {
      this.storage.updateOrderItem(UniqueNo);
    }
    this.searchString = '';
  }

  decrement(OrderItem) {
    this.storage.decrease(OrderItem, this.storage.StoreId);
  }

  decrementProduct(productId: number) {
    var order = this.storage.currentOrder[0];
    if (order.OrderItemWrapperList.filter(x => x.ProductId == productId).length == 0) {
      this.util.showToast('There are variations of this item in your cart. Please go to cart to remove.', 'info');
    }
    else {
      this.storage.decreaseProduct(productId, this.storage.StoreId);
    }
  }

  closeCustomization(){
    this.showCustomization = false ;
  }

  getProductCount(Product: any): number {
    var count = 0;
    if (this.storage.currentOrder != undefined && this.storage.currentOrder.length > 0) {
      var order = this.storage.currentOrder[0];
      order.OrderItemWrapperList.forEach(x => {
        if (x.ProductId == Product.ProductId) {
          count = count + x.Quantity;
        }
        else if (Product.IsVariantPresent && Product.VariationItems.filter(y => y.ProductId == x.ProductId).length > 0) {
          count = count + x.Quantity;
        }
      });
    }
    return count;
  }

  IsCustomizable(product: Product): boolean {
    let isAddOn = product.AddOnList != undefined && product.AddOnList.length > 0;
    let isCombo = product.ComboGroupList != undefined && product.ComboGroupList.length > 0;
    return (isAddOn || isCombo);
  }

  async openModifier(product: ProductWrapper) {
     
    console.log(product);
    this.showCustomization = true ;

    const initialState = {
      initialState: {
        currentProduct: JSON.parse(JSON.stringify(product)),
      }
    };

    this.Customize.newCustomize.next(initialState) ;

    console.log(initialState);
    
    // var bsModalRef = this.modalService.show(ModifierComponent, initialState);

  }

  decrementCartItem(OrderItem) {
    this.storage.decrease(OrderItem, OrderItem.Product.StoreId);
  }

  incrementCartItem(UniqueNo: string) {
    this.storage.updateOrderItem(UniqueNo);
  }

  productDetails(product: ProductWrapper){
    
   
    this.containProductDetails = product;
    console.log(this.containProductDetails) ;
    // console.log(product) ;

    // this.openModifier(product);
  }

}
