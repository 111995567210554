<div class="flex justify-between sticky top-[-2px] bg-white px-0 py-2">
    <p class="text-gray-500 font-bold text-sm cursor-pointer" routerLink="/profile"><i
            class="fa-solid fa-chevron-left mr-2"></i>BACK</p>
    <div class="cart-navigation cursor-pointer" routerLink="/menu">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
            stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round"
                d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
        </svg>
    </div>
</div>

<div class="p-3 text-xs">
    <p><span style="font-size:20px"><strong>Software Licensing</strong></span></p><br>

<h3>All rights to the application, except the content, belong to <strong>Ciferon(Webmilez Infotech Private Limited)</strong>. Ciferon has granted license to the <strong>{{storage.Store.CompanyName}}(Business)</strong> for their use on a subscription basis.&nbsp;Any violation by business of the license provisions contained in this Section may result in the immediate termination of your right to use the Services, as well as potential liability for copyright and other IP Rights infringement depending on the circumstances.</h3>

<h2>&nbsp;</h2>

<h2><span style="font-size:20px"><strong>Ownership of Content</strong></span></h2><br>

<p><strong>Business</strong>&nbsp;is the sole and exclusive copyright owners of the Services and our Content.&nbsp;</p>

<h2>&nbsp;</h2>

<h2><span style="font-size:20px"><strong>Acceptance of terms</strong></span></h2><br>

<p>These Terms of Service (the &quot;Terms&quot;) are intended to make you aware of your legal rights and responsibilities with respect to your access to and use of the Business website at&nbsp;<a href="http://www.Business.com/" rel="noopener noreferrer" target="_blank">www.Business.com</a>&nbsp;(the &quot;Site&quot;) and any related mobile or software applications (&quot;Business Platform&quot;) including but not limited to delivery of information via the website whether existing now or in the future that link to the Terms (collectively, the &quot;Services&quot;).</p>

<p>In order to use the Services, you must first agree to the Terms. You can accept the Terms by:</p>

<ul>
	<li>Clicking to accept or agree to the Terms, where it is made available to you by Business in the user interface for any particular Service; or</li>
	<li>Actually using the Services. In this case, you understand and agree that Business will treat your use of the Services as acceptance of the Terms from that point onwards.</li>
</ul>

<h2>&nbsp;</h2>

<h2><span style="font-size:20px"><strong>Eligibility to use the services</strong></span></h2><br>

<ol>
	<li>
	<p>You hereby represent and warrant that you are at least eighteen (18) years of age or above and are fully able and competent to understand and agree the terms, conditions, obligations, affirmations, representations, and warranties set forth in these Terms.</p>
	</li>
	<li>
	<p>Compliance with Laws. You are in compliance with all laws and regulations in the country in which you live when you access and use the Services. You agree to use the Services only in compliance with these Terms and applicable law, and in a manner that does not violate our legal rights or those of any third party(ies).</p>
	</li>
</ol>

<p>&nbsp;</p>

<h3><span style="font-size:20px"><strong>Representations Regarding Your or Customer Content (For Business)</strong></span></h3>

<p>a. You are responsible for Your Content. You represent and warrant that you are the sole author of, own, or otherwise control all of the rights of Your Content or have been granted explicit permission from the rights holder to submit Your Content; Your Content was not copied from or based in whole or in part on any other content, work, or website; Your Content was not submitted via the use of any automated process such as a script bot; use of Your Content by us, third party services, and our and any third party users will not violate or infringe any rights of yours or any third party; Your Content is truthful and accurate; and Your Content does not violate the Guidelines and Policies or any applicable laws</p>

<p>&nbsp;</p>

<h3><span style="font-size:20px"><strong>Content Removal</strong></span></h3>

<p>Ciferon reserves the right, at any time and without prior notice, to remove, block, or disable access to any Content that we, for any reason or no reason, consider to be objectionable, in violation of the Terms or otherwise harmful to the Services or our Customers in our sole discretion. Subject to the requirements of applicable law, we are not obligated to return any of Your Content to you under any circumstances. Further, the Restaurant reserves the right to delete any images and pictures forming part of Customer Content, from such Restaurant&#39;s listing page at its sole discretion.</p>

<p>&nbsp;</p>

<h3><span style="font-size:20px"><strong>Third Party Content and Links</strong></span></h3>

<p>a. Some of the content available through the Services may include or link to materials that belong to third parties, such as third party reservation services or online food delivery/ordering. Please note that your use of such third party services will be governed by the terms of service and privacy policy applicable to the corresponding third party. We may obtain business addresses, phone numbers, and other contact information from third party vendors who obtain their data from public sources.</p>

<p>b. We have no control over, and make no representation or endorsement regarding the accuracy, relevancy, copyright compliance, legality, completeness, timeliness or quality of any product, services, advertisements and other content appearing in or linked to from the Services. We do not screen or investigate third party material before or after including it on our Services.</p>

<p>c. We reserve the right, in our sole discretion and without any obligation, to make improvements to, or correct any error or omissions in, any portion of the content accessible on the Services. Where appropriate, we may in our sole discretion and without any obligation, verify any updates, modifications, or changes to any content accessible on the Services, but shall not be liable for any delay or inaccuracies related to such updates. You acknowledge and agree that Business is not responsible for the availability of any such external sites or resources, and does not endorse any advertising, products or other materials on or available from such web sites or resources.</p>

<p>d. Third party content, including content posted by our Customers, does not reflect our views or that of our parent, subsidiary, affiliate companies, branches, employees, officers, directors, or shareholders. In addition, none of the content available through the Services is endorsed or certified by the providers or licensors of such third party content. We assume no responsibility or liability for any of Your Content or any third party content.</p>

<p>e. You further acknowledge and agree that Business is not liable for any loss or damage which may be incurred by you as a result of the availability of those external sites or resources, or as a result of any reliance placed by you on the completeness, accuracy or existence of any advertising, products or other materials on, or available from, such websites or resources. Without limiting the generality of the foregoing, we expressly disclaim any liability for any offensive, defamatory, illegal, invasive, unfair, or infringing content provided by third parties.</p>

<p>&nbsp;</p>

<p><span style="font-size:20px"><strong>Security</strong></span></p><br>

<p>We, at Ciferon, make sincere efforts and take all possible steps to protect your Information and Data by deploying a variety of technical and organizational measures to protect and secure the Information and Data we receive from you, including without limitation, security technologies.&nbsp;</p>

<p>&nbsp;</p>

<p>We are continuously utilizing security measures to protect your Information and Data from unauthorized access or against loss, misuse or alteration.&nbsp;We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security. Unauthorized entry or use, hardware or application failure and other factors, may compromise the security of your Information and Data at any time.&nbsp;We reserve the right, without any limitation, to investigate any suspected breaches of the Services&rsquo; security or information technology or other systems or networks.</p>

<p>&nbsp;</p>

<p><span style="font-size:20px"><strong>Customer Data&nbsp;</strong></span></p><br>

<p>&ldquo;Customer Data&rdquo; refers to any and all identifiable information about customers generated or collected by the Service Provider or the Restaurant, including, but not limited to, Customers&rsquo; name, shipping addresses, email addresses, phone numbers, and customer preferences and tendencies. Restaurant acknowledges and agrees to the terms of the applicable Privacy Policy of the Service Provider. The Restaurant and the Service Provider acting as the data processor shall undertake to take all reasonable steps &amp; measures to protect the customer data.</p>

<p>&nbsp;</p>

<p><span style="font-size:20px"><strong>Customer Data from the Restaurant</strong></span></p><br>

<p>The Restaurant retains the rights to export Customer Data from the app account maintained by the Restaurant, so long as such data is handled with reasonable security measures and its usage is in compliance with the applicable laws and the applicable Privacy Policy of the Service Provider. The Service Provider for the purpose of enhancement to its Products, analytical and&nbsp;marketing purposes&nbsp;reserves the right to use such data and to share such data with its third party affiliates. All such third party affiliates and partners are required to preserve the confidentiality of any data they may access.</p>

<p>&nbsp;</p>

<p><span style="font-size:20px"><strong>Customer Data from Service Provider and Affiliates</strong></span></p><br>

<p>The Restaurant agrees to use Customer Data from the Service Provider and third party affiliates for the fulfillment of the applicable Customer Order only, and in compliance this Agreement, shall not use such data for any other purpose, including without limitation enhancement to any file or list of the Restaurant or any third party, resell, broker or otherwise disclose to any third party, in whole or in part, for any purpose whatsoever. The Restaurant (and any other persons to whom the Restaurant provides any Customer Data) will implement and comply with reasonable security measures in protecting, handling, and securing the Customer Data.</p>

</div>