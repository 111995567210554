<ng-container *ngFor="let product of productList">
    <div class="food-items border-b border-[#ddd] py-3" (click)="productDetails(product)">
        <div class="flex item-center justify-between">
            <label class="flex items-center">
                <div class="my-auto">
                    <div class="flex items-center">
                        <div class="w-3 h-3 border border-green-700 flex items-center justify-center"
                            *ngIf="product.FoodType == 'Vegetarian'">
                            <i class="fa-solid fa-circle text-green-700 text-[7px]"></i>
                        </div>
                        <div class="w-3 h-3 border border-red-700 flex items-center justify-center"
                            *ngIf="product.FoodType == 'Non-vegetarian' ">
                            <i class="fa-solid fa-circle text-red-700 text-[7px]"></i>
                        </div>
                        <div class="w-3 h-3 border border-warning flex items-center justify-center"
                            *ngIf="product.FoodType == 'Egg' ">
                            <i class="fa-solid fa-circle text-warning text-[7px]"></i>
                        </div>
                        <p class="text-green-600 text-sm font-bold ml-2 flex items-center"
                            *ngIf="product.IsBestSeller == true">
                            <i class="fa-solid fa-pepper-hot mr-2"></i>
                            BestSeller
                        </p>
                        <p class="text-green-600 text-sm font-bold ml-2 flex items-center"
                            *ngIf="product.IsRecommended == true">
                            <!-- <i class="fa-solid fa-pepper-hot mr-2"></i> -->
                            Recommended
                        </p>
                    </div>
                    <p class="text-[14px] font-bold capitalize">
                        {{product.ProductName}}
                    </p>
                    <p class="text-sm font-bold mt-[-3px]">
                        ₹{{product.DisplayPrice}}
                    </p>
                    <p class="text-[12px] text-gray-400">
                        {{product.Description}}
                    </p>
                    <p *ngIf="product.IsVariantPresent || (product.ComboGroupList != undefined && product.ComboGroupList.length > 0)"
                        class="text-[12px] text-gray-400">
                        customizable
                    </p>
                </div>
            </label>
            <div class="text-center relative flex items-center">
                <div *ngIf="storage.ContactlessSettings.IsReadOnlyEnabled == false && product.InStock">
                    <label for="product-details-modal">
                        <img *ngIf="product?.ImgUrl != undefined" alt="#" src="{{product?.ImgUrl}}" alt="item"
                            class="rounded-md min-w-[7rem] w-28 h-24 object-cover relative">
                        <!-- <img *ngIf="product?.ImgUrl == undefined" alt="#"
                            src="assets/img/eat.jpg" alt="item"
                            class="rounded-md min-w-[7rem] w-28 h-24 object-cover relative"> -->
                    </label>
                    <label [ngClass]="{ 'no-image': product?.ImgUrl == undefined }" (click)="increment(product,true,'')"
                        *ngIf="getProductCount(product) == 0"
                        class="theme-shadow rounded-md  flex items-center justify-center bg-white h-7 w-24  mx-auto mt-[-20px] relative text-green-600 uppercase text-center font-bold text-sm">
                        ADD
                    </label>
                    <ng-container *ngIf="getProductCount(product) > 0">
                        <div [ngClass]="{ 'no-image': product?.ImgUrl == undefined }" class="btn-group overlay-p "
                            role="group" aria-label="Basic example">
                            <button type="button" (click)="decrementProduct(product.ProductId)"
                                class="btn btn-sm btn-plus-minus"><i class="fa fa-minus"></i></button>
                            <button type="button" class="btn btn-sm btn-plus-minus"
                                style="width: 32px;border-right: none;border-left: none;">{{getProductCount(product)}}</button>
                            <button type="button" (click)="increment(product,true,'')"
                                class="btn btn-sm btn-plus-minus"><i class="fa fa-add"></i></button>
                        </div>
                    </ng-container>
                </div>
                <label *ngIf="product.InStock == false" style="font-size: 10px;"
                    class="bg-white flex items-center justify-center mr-2 mx-auto relative rounded-md text-center text-gray-400 text-xs theme-shadow uppercase w-24">
                    OUT OF <br /> Stock
                </label>
            </div>
        </div>

    </div>
</ng-container>

<!--Customisation modal starts  -->
<ng-container *ngIf="showCustomization">
    <div class="bg-[rgba(0,0,0,0.8)] fixed left-0 w-full h-full z-[9999] top-0  flex items-end justify-center">
        <div class="items-end w-full">
            <div class="w-full">
                <label id="close-modifier-modal"
                    class="btn btn-sm btn-circle mx-auto mb-5 flex w-16 h-16 bg-white text-gray-600  text-lg"
                    (click)="closeCustomization()">
                    <i class="fa-solid fa-times text-[28px]"></i>
                </label>
                <app-modifier></app-modifier>
            </div>
        </div>
    </div>
</ng-container>