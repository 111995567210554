import { Component, Input, OnInit } from '@angular/core';
// import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {

  @Input() title : string;
  @Input() message : string;
  
  constructor() { }

  ngOnInit(): void {
  }

  close() {
    // this.bsModalRef.hide();
  }

}
