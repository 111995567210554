<div class="flex justify-between sticky top-[-2px] bg-white px-0 py-2">
    <p class="text-gray-500 font-bold text-sm cursor-pointer" routerLink="/profile"><i
            class="fa-solid fa-chevron-left mr-2"></i>BACK</p>
    <div class="cart-navigation cursor-pointer" routerLink="/menu">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
            stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round"
                d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
        </svg>
    </div>
</div>

<div class="p-3 text-xs">
    <p class="font-bold capitalize my-3">Brand</p>
    <div class="items-center my-5 mt-2 text-[15px]">
        {{storage.Store.StoreDisplayName}}
    </div>

    <hr>

    <p class="font-bold capitalize my-3">Legal Name</p>
    <div class="items-center my-5 mt-2 text-[15px]">
        {{storage.Store.CompanyName}}
    </div>

    <hr>

    <p class="font-bold capitalize my-3">Phone</p>
    <div class="items-center my-5 mt-2 text-[15px]">
        {{storage.Store.SupportContact}}
    </div>

    <hr>

    <p class="font-bold capitalize my-3">Email</p>
    <div class="items-center my-5 mt-2 text-[15px]">
        {{storage.Store.EmailID}}
    </div>

    <hr>

    <p class="font-bold capitalize my-3">Address</p>
    <div class="items-center my-5 mt-2 text-[15px]">
        {{storage.Store.Addres}}
    </div>

    <hr>

    <p class="font-bold capitalize my-3">Fssai</p>
    <div class="items-center my-5 mt-2 text-[15px]">
        {{storage.Store.Fssai}}
    </div>

    <hr>

</div>