import { Injectable } from '@angular/core';

import { LoginRequest, LoginResponse } from '../models/login';


@Injectable()
export class DbService  {

  //LoginResponse: Dexie.Table<LoginResponse, number>;
  constructor() {
    //super("MyAppDatabase");
    // this.version(2).stores({
    //   LoginResponse: '++id, AuthKey',
    //   StoreId: '++id,StoreId',     
    // });
  }

  saveLoginResponse(login: LoginResponse) {
    // this.transaction('rw', this.LoginResponse, () => {
    //   this.LoginResponse.clear();
    //   this.LoginResponse.add(login);
    // });
  }


  logout() {
    // this.transaction('rw', this.LoginResponse, () => {
    //   this.LoginResponse.clear();
    // });
  }

  // async getLoginResponse(): Promise<LoginResponse> {
  //   let response = await this.LoginResponse.toArray();
  //   return response[0];
  // }

}
