import { Component, OnInit } from '@angular/core';
import { Order } from 'src/app/models/order';
import { ApiService } from 'src/app/services/api.service';
import { StorageService } from 'src/app/services/storage.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.scss']
})
export class OrderListComponent implements OnInit {

  inProgress: boolean;
  orders: Order;

  constructor(private api: ApiService, public storage: StorageService, private routerLink: Router) { }

  ngOnInit(): void {
    this.getOrders();
  }

  getOrders() {
    this.inProgress = true;
    this.api.getOrders().subscribe((data: any) => {
      if (data != null && data.IsSuccess) {
        this.orders = data.Result.orderList;
      }
      setTimeout(() => {
        this.getOrders();
      }, 60000);
      this.inProgress = false;
    },
      (error) => {
        console.log(error);
        this.inProgress = false;
        setTimeout(() => {
          this.getOrders();
        }, 60000);
      });
  }

  trackOrder(id) {
    this.routerLink.navigate(['order-success'], { state: { example: id } });
    this.routerLink.navigateByUrl('order-success/' + id);
    // console.log(id)
  }

}
