import { Component, OnInit } from '@angular/core';
// import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { Address } from 'src/app/models/address';
import { BaseResponse } from 'src/app/models/login';
import { UserWrapper } from 'src/app/models/userWrapper';
import { ApiService } from 'src/app/services/api.service';
import { RouterService } from 'src/app/services/router.service';
import { StorageService } from 'src/app/services/storage.service';
import { UtilService } from 'src/app/services/util.service';
import { AddressEditComponent } from '../address-edit/address-edit.component';
import { AddressListComponent } from '../address-list/address-list.component';
import { CheckoutService } from 'paytm-blink-checkout-angular';
import { ActivatedRoute, Router } from '@angular/router';
import { AddressService } from 'src/app/services/address.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {

  paymentMode = "Cash";
  inProgress: boolean = false;
  orderId: any;
  addressList: Address[];
  orderType: string;
  tableName: any;
  selectedAddress: Address;
  subs: any;
  showAddresIf = false;
  finalAddress;
  checkedOne = true;
  isOrderTypeSelectionVisible: boolean;


  constructor(
    private router: RouterService, private readonly checkoutService: CheckoutService,
    public storage: StorageService, private api: ApiService, private activatedRoute: ActivatedRoute,
    private util: UtilService, private routerLink: Router, private newAddress: AddressService) { }

  ngOnInit(): void {
    this.tableName = this.storage.currentOrder[0].TableNo;
    this.activatedRoute.queryParams.subscribe(params => {
      var isFailed = +params['isFailed'];
      if (isFailed == 1) {
        this.util.showToast('Payment failed. Please try again', 'danger');
      }

    });

    this.getAddress();
    console.log(this.storage.currentOrder)
    this.orderType = this.storage.currentOrder[0].SaleOrderType;
    console.log(this.storage.currentOrder[0])
    if (this.orderType == undefined) {
      if (this.storage.ContactlessSettings.IsDineinEnabled) {
        this.orderType = this.storage.currentOrder[0].SaleOrderType = "DineIn";
      }
      else if (this.storage.ContactlessSettings.IsPickupEnabled) {
        this.orderType = this.storage.currentOrder[0].SaleOrderType = "TakeAway";
      }
      else if (this.storage.ContactlessSettings.IsDeliveryEnabled) {
        this.orderType = this.storage.currentOrder[0].SaleOrderType = "HomeDelivery";
      }
    }
    if (this.storage.currentOrder != undefined && this.storage.currentOrder[0].Address != undefined) {
      this.selectedAddress = this.storage.currentOrder[0].Address;
      this.finalAddress = this.storage.currentOrder[0].Address;
    }
    if (this.storage.ContactlessSettings.IsPaytmEnabled) {
      this.paymentMode = "Online";
    }
    else if (this.storage.ContactlessSettings.IsCodEnabled) {
      this.paymentMode = "Cash";
    }

    if (localStorage.getItem('tableId') != undefined) {
      this.isOrderTypeSelectionVisible = false;
      this.orderType = this.storage.currentOrder[0].SaleOrderType = "DineIn";
    }
    else {
      this.isOrderTypeSelectionVisible = true;
    }
  }

  decrementCartItem(OrderItem) {
    this.storage.decrease(OrderItem, OrderItem.Product.StoreId);
  }

  incrementCartItem(UniqueNo: string) {
    this.storage.updateOrderItem(UniqueNo);
  }

  changePaymentMode(mode: string) {
    this.paymentMode = mode;
  }

  get isCartEmpty(): boolean {
    var isEmpty = true;
    if (this.storage.currentOrder != undefined) {
      for (let index = 0; index < this.storage.currentOrder.length; index++) {
        const element = this.storage.currentOrder[index];
        if (element.OrderItemWrapperList != undefined && element.OrderItemWrapperList.length > 0) {
          isEmpty = false;
          break;
        }

      }
    }
    return isEmpty;
  }

  postOrder() {
    this.storage.currentOrder[0].PaymentMode = this.paymentMode;

    if (this.validateOrder()) {
      if (this.storage.currentOrder[0].SaleOrderType == 'DineIn') {
        this.storage.currentOrder[0].TableNo = this.tableName;

        if (this.storage.ContactlessSettings.IsTableNumberFieldVisible == false) {
          this.storage.currentOrder[0].SaleOrderType = 'Token';
        }
      }

      var orderAmount = 0;
      var dte = new Date();
      dte.setTime(dte.getTime() + (dte.getTimezoneOffset() + 330) * 60 * 1000);
      this.storage.currentOrder.forEach(element => {
        orderAmount = orderAmount + element.GrandTotal;
        //element.PaymentMode = 'Cash';
        element.PurchaseDate = dte.toLocaleString("en-US");
      });

      this.inProgress = true;
      //this.util.showLoader('Placing Order...');
      var orders = this.storage.currentOrder;
      console.log(orders)
      this.api.PostOrder(orders).subscribe((data: BaseResponse) => {
        console.log(data);
        this.inProgress = false;
        if (data != null && data.IsSuccess) {

          if (this.paymentMode == 'Cash') {
            this.orderId = data.Result.OrderId;
            //this.util.hideLoader();
            this.storage.ResetOrder();
            this.storage.saleOrderType = undefined;
            // this.router.redirect('order-success');
            this.routerLink.navigateByUrl('order-success/+' + this.orderId + '?isNewOrder=true');
          }
          else {
            this.openPaymentGateway(data.Result.PaytmResponse);
          }

        }
        else {
          //this.orderFailed();
        }
        //this.util.hideLoader();
      },
        (error) => {
          this.inProgress = false;
          //this.orderFailed();
        });

    }
  }

  openPaymentGateway(response) {
    this.inProgress = true;
    setTimeout(() => {
      this.inProgress = false;
    }, 5000);
    this.checkoutService.init(
      //config
      {
        data: {
          orderId: response.orderId,
          amount: response.amount,
          token: response.txnToken,
          tokenType: "TXN_TOKEN",
          channelName: "WEBSTAGING"
        },
        merchant: {
          mid: response.mid,
          //name: "Dummy",
          redirect: true
        },
        flow: "DEFAULT",
        handler: {
          notifyMerchant: this.notifyMerchantHandler
        }
      },
    );

    this.subs = this.checkoutService
      .checkoutJsInstance$
      .subscribe(instance => console.log(instance));
  }

  notifyMerchantHandler = (eventType, data): void => {
    console.log('MERCHANT NOTIFY LOG', eventType, data);
  }

  validateOrder(): boolean {
    let result = true;
    // if(this.storage.TableNumberCompulsory && (this.storage.currentOrder[0].TableNo == undefined || this.storage.currentOrder[0].TableNo == '')){
    //   result = false;
    //   this.util.showToast('Please enter table number.', 'danger');
    // }
    if (this.storage.currentOrder[0].SaleOrderType == 'HomeDelivery' && (this.storage.currentOrder[0].AddressId == 0 || this.storage.currentOrder[0].AddressId == undefined)) {
      result = false;
      this.util.showToast('Please select address.', 'danger');
    }
    else if (this.storage.ContactlessSettings.IsTableNumberFieldVisible && this.storage.currentOrder[0].SaleOrderType == 'DineIn' && (this.tableName == undefined || this.tableName == '')) {
      result = false;
      this.util.showToast('Please enter table number.', 'danger');
    }
    return result;
  }

  showAddresses() {
    this.routerLink.navigate(['address-list']);
  }

  getAddress() {
    this.util.toggleLoader();
    var latitude = 0;
    var longitude = 0;
    this.api.getAddress('HomeDelivery', latitude, longitude).subscribe((data: BaseResponse) => {
      if (data != null && data.IsSuccess) {
        this.addressList = data.Result;
        this.addressList.forEach(element => {
          if (element.AddressId == this.storage.currentOrder[0].AddressId) {
            element.IsSelected = true;
          }
          else {
            element.IsSelected = false;
          }
        });
      }
      this.util.toggleLoader();
    },
      (error) => {
        this.util.toggleLoader();
      });
  }

  selectAddress(address: Address) {
    this.addressList.forEach(element => {
      if (element.AddressId == address.AddressId) {
        element.IsSelected = true;
        this.storage.currentOrder[0].AddressId = address.AddressId;
        this.storage.currentOrder[0].Address = address;
        this.selectedAddress = address;
        this.storage.CalculateOrder();
      }
      else {
        element.IsSelected = false;
      }
    });
  }

  changeOrderType(type) {
    this.orderType = type;
    this.storage.currentOrder[0].SaleOrderType = type;
    this.storage.CalculateOrder();
  }

  formatAddress(address: Address) {
    var temp = [];
    return address.AddressLine1;
  }

  cartCount() {
    if (this.storage.currentOrder == undefined || this.storage.currentOrder.length == 0) {
      return 0;
    }
    else {
      return this.storage.currentOrder[0].OrderItemWrapperList.length;
    }
  }
}
