import { Store } from '../models/configuration'

export class LoginRequest {
    public Username: string;
    public Password: string;
    public Source: string;
    FcmToken : string;

    constructor() {
        this.Source = 'WEB';
    }
}

export class LoginResponse {
    public Status: string;
    public AuthKey: string;
    public ErrorMessage: string;
    public Result: any;
    public StoreList: Store[];
}

export class PostResponse {
    Result: any;
    ErrorMessage: string;
    Status: string;
}

export class OrderRequest {
    OrderType: string;
    currentPage: number;
    VendorId: number;
    StartDate: Date;
    EndDate: Date;
    currentPageIndex: number = 1;
}
export class OrderId {
    orderId: number;
}

export class ProductRequest {
    CategoryId: number;
    UnitMasterId: number;
    isInventory: boolean;
    isSemiProcessed: boolean;
    ProductName: String;

}

export class InventoryRequest {
    currentPage: number
    VendorId: number
    ProductId: number
    CategoryId: number
    OrderType: string;
    searchString: string
    IsMasterPrice: boolean;
    StartDate1: string;
    EndDate1: string;
    StartDate: Date;
    EndDate: Date;
    IsDatewise: boolean = false;
    IsDownLoad: boolean = false;
}
export class LedgerRequest {
    searchString: string;
    ContactType: String;
    currentIndexpage: number;
}

export class ExpenseRequest {
    searchString: string;
    ContactType: String;
    currentIndexpage: number;
}

export class BaseResponse {
    Error: string;
    IsSuccess: boolean;
    Result: any;
    OrderType: string;
}

export class ProductPriceRequest {
    ProductId: number;
    ProductPriceId: number;
}

export class InitRequest {

}
