<div class="flex justify-between px-3 sticky top-0 bg-white py-2 z-10">
  <p class="text-gray-500 font-bold text-sm cursor-pointer" (click)="storage.back()"><i
      class="fa-solid fa-chevron-left mr-2"></i>BACK</p>
        <div class="cart-navigation cursor-pointer" routerLink="/menu">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"
            strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round"
              d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
          </svg>
        </div>
</div>



<div class="relative">

  <ng-container>
    <form>
      <agm-map class="h-[400px] w-full" [latitude]="lat" [longitude]="lng" [zoom]="18">
        <agm-marker [latitude]="lat" [longitude]="lng" [markerDraggable]="true" (dragEnd)="markerDragEnd($event)">
        </agm-marker>
      </agm-map>
    </form>
  </ng-container>

  <ng-container>
    <div
      class="flex items-center justify-center pl-3 w-[93%] theme-shadow h-10 bg-white text-green-600 text-lg  absolute top-[9px] left-[13px]">
      <i class="fa fa-search"></i>
      <input type="text" class="form-control text-sm my-10 w-full placeholder:text-sm px-4" (keydown.enter)="$event.preventDefault()"
      placeholder="Search Nearest Location" autocorrect="off" autocapitalize="off" spellcheck="off" type="text"
      #search />
    </div>
  </ng-container>

  <div class="py-3">
    <div class="bg-[#fffeea] text-green-600 text-xs p-2 rounded-md border border-gray-300">
      A detailed address will help our Delivery Partner reach your doorstep easily.
    </div>

    <input type="text" class="border-b w-full py-2 placeholder:text-xs mt-4" [(ngModel)]="address.AddressLine1"
      placeholder="HOUSE / FLAT / BLOCK NO." ng-init="searchText ='can you see me'" />
    <p class="text-danger text-red-500" style="font-size: 12px;">{{errors.addressLine1Message}}</p>
    <input type="text" class="border-b w-full py-2 placeholder:text-xs mt-4" [(ngModel)]="address.AddressLine2"
      placeholder="APARTMENT / ROAD / AREA " />
    <p class="text-danger text-red-500" style="font-size: 12px;">{{errors.addressLine2Message}}</p>
    <div class="flex items-center justify-between">
      <div>
        <input type="text" class="border-b w-full py-2 placeholder:text-xs mt-4" [(ngModel)]="address.City"
          placeholder="Eg. INDORE " />
        <p class="text-danger text-red-500" style="font-size: 12px;">{{errors.addressLine3Message}}</p>
      </div>
      <div>
        <input type="text" class="border-b w-full py-2 placeholder:text-xs mt-4" [(ngModel)]="address.Pincode"
          placeholder="PINCODE" />
        <p class="text-danger text-red-500" style="font-size: 12px;">{{errors.addressLine4Message}}</p>
      </div>
    </div>


    <p class="text-xs uppercase text-gray-400 mt-5">direction to reach (optional)</p>
    <textarea cols="30" rows="3" class="border w-full p-2 bg-gray-50 rounded-md placeholder:text-xs mt-2 "
      [(ngModel)]="address.Message" placeholder="eg. Ring the bell on the red gate"></textarea>
    <p class="text-xs uppercase text-gray-400 mt-5">save this address as</p>
    <div class="py-4 flex overflow-x-auto w-full ">
      <input (click)="address.AddressType = 'Home'" class="checkbox-tools" type="radio" name="tools" id="eclipse">
      <label for="eclipse" class="min-w-[fit-content]">
        <i class="fa fa-home mr-2"></i>
        Home
      </label>

      <input (click)="address.AddressType = 'Work'" class="checkbox-tools" type="radio" name="tools" id="square"
        checked>
      <label for="square" class="min-w-[fit-content]">
        <i class="fa-solid fa-briefcase mr-2"></i>
        Work
      </label>

      <input (click)="address.AddressType = 'Other'" class="checkbox-tools" type="radio" name="tools" id="box">
      <label for="box" class="min-w-[fit-content]">
        <i class="fa-solid fa-location-dot mr-2"></i>
        Other
      </label>
    </div>

    <button class="text-white bg-green-600  h-10 rounded-md w-full uppercase text-sm font-bold mt-3"
      (click)="confirmLocation()">confirm location</button>

  </div>

</div>