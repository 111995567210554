<div class="h-screen fixed left-0 top-0 ">

    <div *ngIf="mode == 'login'" class="h-full flex items-center justify-center fixed  bg-[rgba(0,0,0,0.8)]">
        <div class="modal-middle">
            <div class="w-full">
                <label for="choose-order-type"
                    class="btn btn-sm btn-circle ml-auto mr-4 mb-[-33px] border-white relative z-10 flex w-8 h-8 bg-gray-800 text-white"
                    (click)="storage.back()">
                    <i class="fa-solid fa-times text-[17px]"></i>
                </label>
                <div class="modal-box relative w-[100%] mx-auto rounded-xl px-0 pt-3 pb-0 bg-white">
                    <h3 class="text-2xl font-[900] px-8 my-2">Your Phone !</h3>
                    <form>
                        <div class="px-8 py-5">
                            <label class="text-black text-md font-bold block mt-0">Phone Number</label>
                            <div class="flex items-center">
                                <div class="border-b border-gray-300 h-[3rem] flex items-center justify-center  px-3">
                                    <img src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png"
                                        class="w-6 h-5 m-auto object-cover rounded-full" alt="" />
                                </div>
                                <div class="input-left-seprator w-full">
                                    <input type="number"
                                        class="border-b pl-3  border-gray-300 text-sm font-bold py-0 h-[3rem] text-bold w-full placeholder:font-bold pt-0 placeholder:text-sm placeholder:text-gray-500"
                                        placeholder="Enter Number" [(ngModel)]="loginUser.MobileNo" id="cMob"
                                        name="cMob" #cMob="ngModel" />
                                </div>

                            </div>
                            <p class="text-sm text-[#b12c2c] my-5 px-8">{{mobileNumberMsg}}</p>

                        </div>

                        <p class="text-md text-[#5aa85a] my-5 mt-2 px-8">A 4 digit OTP will be sent via SMS to verify
                            your mobile number!</p>

                        <div class="w-full">
                            <label class=" w-full py-3 text-white bg-[#5aa85a] text-sm">
                                <button
                                    class=" w-full py-3 text-white bg-[#5aa85a] text-sm pointer-events-none cursor-pointer"
                                    for="choose-order-type" (click)="doLogin()">Confirm and Proceed
                                    <i *ngIf="showLoader"
                                        class="fa-solid fa-circle-notch text-white ml-3 text-lg animate-spin"></i></button>
                            </label>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="mode == 'signup'" class="h-full flex items-center justify-center fixed  bg-[rgba(0,0,0,0.8)] w-full">
        <div class="modal-middle w-full">
            <div class="w-full">
                <label for="login"
                    class="btn btn-sm btn-circle ml-auto mr-4 mb-[-20px] border-white relative z-10 flex w-8 h-8 bg-gray-800 text-white"
                    (click)="storage.isLoginVisible = false">
                    <i class="fa-solid fa-times text-[17px]"></i>
                </label>
                <div class="modal-box relative w-[90%] mx-auto rounded-xl px-0 pt-3 pb-0 bg-white">
                    <h3 class="text-2xl font-[900] px-8 my-2">Enter Your Details !</h3>
                    <form>
                        <div class="px-8 py-5">
                            <div class="flex items-center bg-gray-100 rounded-md p-2 px-4">
                                <i class="fa fa-user mr-4"></i>
                                <input type="text"
                                    class="bg-transparent pl-3 text-sm font-bold py-0 h-[2rem] text-bold w-full  pt-0 placeholder:text-sm placeholder:font-medium placeholder:text-gray-400"
                                    placeholder="Name" [(ngModel)]="loginUser.LedgerName" id="cName" name="cName"
                                    #cName="ngModel">
                            </div>
                            <p class="text-error" style="font-size: 12px;">{{customerNameMsg}}</p>


                            <div class="flex items-center bg-gray-100 rounded-md p-2 px-4 mt-5">
                                <i class="fa fa-envelope mr-4"></i>
                                <input type="number"
                                    class="bg-transparent pl-3 text-sm font-bold py-0 h-[2rem] text-bold w-full  pt-0 placeholder:text-sm placeholder:font-medium placeholder:text-gray-400"
                                    placeholder="Mobile no" [(ngModel)]="loginUser.MobileNo" id="cMob" name="cMob"
                                    #cMob="ngModel">
                            </div>
                            <p class="text-error" style="font-size: 12px;">{{mobileNumberMsg}}</p>
                            
                            <div class="flex items-center bg-gray-100 rounded-md p-2 px-4 mt-5">
                                <p style="font-size: 12px;font-weight: 600;">DOB</p>
                                <input type="date" (focus) = "this.showPicker()"
                                    class="bg-transparent pl-3 text-sm  font-bold py-0 h-[2rem] text-bold w-full  pt-0 placeholder:text-sm placeholder:font-medium placeholder:text-gray-400"
                                    placeholder="Date of Birth" [(ngModel)]="loginUser.DateOfBirth" id="dob" name="dob"
                                    #dob="ngModel">
                            </div>

                            <div class="flex items-center bg-gray-100 rounded-md p-2 px-4 mt-5">
                                <i class="fa fa-location-dot mr-4"></i>
                                <input type="text"
                                    class="bg-transparent pl-3 text-sm font-bold py-0 h-[2rem] text-bold w-full  pt-0 placeholder:text-sm placeholder:font-medium placeholder:text-gray-400"
                                    placeholder="City" [(ngModel)]="loginUser.City" id="city" name="city"
                                    #city="ngModel" maxlength="20">
                            </div>



                        </div>

                        <div class="w-full mt-7">
                            <label class=" w-full py-3 text-white bg-[#5aa85a] text-sm">
                                <button
                                    class=" w-full py-3 text-white bg-[#5aa85a] text-sm pointer-events-none cursor-pointer"
                                    (click)="doSignup()">Confirm and Proceed</button>
                            </label>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="mode == 'otp'" class="h-full flex items-center justify-center fixed  bg-[rgba(0,0,0,0.8)] w-full">
        <div class="modal-middle w-full">
            <div class="w-full">
                <a (click)="storage.isLoginVisible = false">
                    <label
                        class="btn btn-sm btn-circle ml-auto mr-4 mb-[-33px] border-white relative z-10 flex w-8 h-8 bg-gray-800 text-white"
                        routerLink="/">
                        <i class="fa-solid fa-times text-[17px]"></i>
                    </label>
                </a>
                <div class="modal-box relative w-[100%] mx-auto rounded-xl px-0 pt-3 pb-0 bg-white">
                    <h3 class="text-2xl font-[900] px-8 my-2">OTP verification</h3>
                    <form action="home.html">
                        <div class="px-8 py-5">
                            <p class="text-gray-400 text-md font-bold block mt-0">Enter the OTP you recieved to</p>
                            <div class="flex items-center justify-between my-3">
                                <p class="text-black text-md font-bold block">+91 {{mobileNumber}}</p>
                                <p class="text-sky-500 text-sm cursor-pointer font-bold block text-right mt-0 uppercase"
                                    (click)="resendOTP()">resend otp
                                    <i class="fa-solid fa-play ml-2 text-sky-500"></i>
                                </p>
                            </div>
                            <ng-otp-input (onInputChange)="onOtpChange($event)" style="margin: 0 auto;"
                                [config]="{length:4, allowNumbersOnly : true }"></ng-otp-input>
                            <p *ngIf="otpErrorMsg" style="font-size: 12px;" class="text-error text-center">The OTP
                                entered
                                is invalid/incorrect. Please try again.</p>
                        </div>
                        <div class="w-full">
                            <label class="w-full py-3 text-white bg-[#5aa85a] text-sm">
                                <button
                                    class=" w-full py-3 text-white bg-[#5aa85a] text-sm pointer-events-none cursor-pointer"
                                    (click)="verifyOtp()" [disabled]="!isOtpButtonEnabled">Confirm and Proceed</button>
                            </label>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="mode == 'message'" class="h-full flex items-center justify-center fixed  bg-[rgba(0,0,0,0.8)] w-full">
        <div class="modal-middle w-full">
            <div class="w-full">
                <a (click)="storage.isLoginVisible = false">
                    <label
                        class="btn btn-sm btn-circle ml-auto mr-4 mb-[-33px] border-white relative z-10 flex w-8 h-8 bg-gray-800 text-white"
                        routerLink="/">
                        <i class="fa-solid fa-times text-[17px]"></i>
                    </label>
                </a>
                <div class="modal-box relative w-[100%] mx-auto rounded-xl px-0 pt-3 pb-0 bg-white">
                    <h3 class="text-2xl font-[900] px-8 my-2">Congratulations</h3>
                    <form action="home.html" *ngIf = "couponCode != undefined">
                        <div class="px-8 py-5">
                            <p class="text-gray-400 text-md font-bold block mt-0"
                                style="text-align: center;width: 100%;">You have unlocked a coupon.</p>
                            <div class="flex items-center justify-between my-3">
                                <p style="text-align: center;width: 100%;"
                                    class="text-green-600 cursor-pointer font-bold block text-right mt-0 uppercase">
                                    {{couponCode}}
                                </p>
                            </div>
                            <p class="text-gray-400 text-md font-bold block mt-0"
                                style="text-align: center;width: 100%;">Visit any nearest outlet to claim this coupon
                            </p>


                        </div>
                        <div class="w-full">
                            <label class="w-full py-3 text-white bg-[#5aa85a] text-sm">
                                <button
                                    class=" w-full py-3 text-white bg-[#5aa85a] text-sm pointer-events-none cursor-pointer"
                                    (click)="storage.isLoginVisible = false">Okay</button>
                            </label>
                        </div>
                    </form>

                    <form action="home.html" *ngIf = "couponCode == undefined">
                        <div class="px-8 py-5">
                            <p class="text-gray-400 text-md font-bold block mt-0"
                                style="text-align: center;width: 100%;">You have registered with us.</p>
                            <div class="flex items-center justify-between my-3">
                                <p style="text-align: center;width: 100%;"
                                    class="text-green-600 cursor-pointer font-bold block text-right mt-0 uppercase">
                                    You'll soon recieve great offers from us.
                                </p>
                            </div>
                            <p class="text-gray-400 text-md font-bold block mt-0"
                                style="text-align: center;width: 100%;">Visit any nearest outlet and enjoy great food.
                            </p>


                        </div>
                        <div class="w-full">
                            <label class="w-full py-3 text-white bg-[#5aa85a] text-sm">
                                <button
                                    class=" w-full py-3 text-white bg-[#5aa85a] text-sm pointer-events-none cursor-pointer"
                                    (click)="storage.isLoginVisible = false">Okay</button>
                            </label>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

</div>