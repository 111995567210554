<div class="cubes">
    <div class="cube cube-1"></div>
    <div class="cube cube-2"></div>
    <div class="cube cube-3"></div>
    <div class="cube cube-4"></div>
    <div class="cube cube-5"></div>
    <div class="cube cube-6"></div>
    <div class="cube cube-7"></div>
    <div class="cube cube-8"></div>
    <div class="cube cube-9"></div>
  </div>