<div class="relative w-full rounded-b-none rounded-t-md px-3 py-2 bg-white" style="padding-left: 0.25rem;
padding-right: 0.25rem;">
  <h3 class="font-bold px-2">{{currentProduct.ProductName}}</h3>

  <div class="customisation-seprator"></div>

  <div class="overflow-y-auto max-h-[58vh]">

    <!-- Variants -->
    <div *ngIf="currentProduct.VariationItems != undefined && currentProduct.VariationItems.length > 0">
      <div>
        <h3 class="font-bold px-2">Choose size</h3>
        <p class="text-gray-400 text-xs px-2">Select any one option</p>
      </div>
      <div *ngFor="let item of currentProduct.VariationItems" class="flex px-2 items-center justify-between my-4">
        <p class="flex items-center text-sm font-semibold">
          <span class="mr-2">
            <div *ngIf="item.Product.FoodType == 'Vegetarian'"
              class="w-3 h-3 border border-green-700 flex items-center justify-center">
              <i class="fa-solid fa-circle text-green-700 text-[7px]"></i>
            </div>
            <div class="w-3 h-3 border border-red-700 flex items-center justify-center"
              *ngIf="item.Product.FoodType == 'Non-vegetarian' ">
              <i class="fa-solid fa-circle text-red-700 text-[7px]"></i>
            </div>
            <div class="w-3 h-3 border border-warning flex items-center justify-center"
              *ngIf="item.Product.FoodType == 'Egg' ">
              <i class="fa-solid fa-circle text-warning text-[7px]"></i>
            </div>
          </span> {{item.Name}}
        </p>
        <p class="flex items-center text-sm font-semibold"> ₹{{item.Price | number :'0.0-2'}}
          <input id="{{item.VariantItemId}}" name="variant" [checked]="item.VariantItemId == VariantItemId"
            (change)="onVariantSelected(item.VariantItemId)" type="radio" value="value4" class="styled-checkbox">
          <label for="{{item.VariantItemId}}" class="ml-2"></label>
        </p>
      </div>
      <div class="customisation-seprator"></div>
    </div>

    <!-- first plot -->
    <div *ngIf="currentProduct.ComboGroupList != undefined && currentProduct.ComboGroupList.length != 0">
      <div *ngFor="let item of filterCombos(currentProduct.ComboGroupList)">
        <h3 class="font-bold px-2">{{item.Name}}</h3>
        <p class="text-gray-400 text-xs px-2" *ngIf="!item.IsAddon && item.GroupQty">Select upto {{item.GroupQty}}
          options</p>
        <p class="text-gray-400 text-xs px-2"
          *ngIf="!item.IsAddon && item.MaximumQty == item.MinimumQty && item.MaximumQty != 0">Select {{item.MaximumQty}}
          items</p>
        <p class="text-gray-400 text-xs px-2" *ngIf="!item.IsAddon && item.MaximumQty != item.MinimumQty">Select
          minimum {{item.MinimumQty}} & maximum
          {{item.MaximumQty}} items</p>
        <div class="flex px-2 items-center justify-between my-4"
          *ngFor="let product of filterComboItems(item?.ComboList)">
          <p class="flex items-center text-sm font-semibold">
            <span>
              <div class="w-3 h-3 border border-green-700 flex items-center justify-center mr-2">
                <i class="fa-solid fa-circle text-green-700 text-[7px]"></i>
              </div>
            </span>
            {{product.ProductName}}
          </p>
          <!-- <div *ngIf="product.IsSelected && item.GroupQty > 0" style="display: initial;">
            <div class="product-qty">
              <span class="super_number menu">
                <button id="deduct" class="ui left attached button dec"
                  (click)="decreaseCombo(item.ComboGroupId,product.ComboGrpItemId)"> – </button>
                <input type="text" value="1" readonly="" [(ngModel)]="product.Quantity" min="1" max="100"
                  [ngModelOptions]="{standalone:true}">
                <button id="add" (click)="increaseCombo(item.ComboGroupId,product.ComboGrpItemId)"
                  class="ui right attached button inc" style="background: #21BA45 !important;">
                  + </button>
              </span>
            </div>
            ₹{{product.Total | number :'0.0-2'}}
          </div> -->
          <p class="flex items-center text-sm font-semibold">
            ₹{{product.Price | number :'0.0-2'}}
            <input class="styled-checkbox" type="checkbox" value="value4"
              (click)="onComboSelected(item.ComboGroupId,product.ComboGrpItemId,product.IsSelected)"
              [(ngModel)]="product.IsSelected" [ngModelOptions]="{standalone:
                               true}" id="{{item.ComboGroupId+'-'+product.ComboGrpItemId}}" />
            <label for="{{item.ComboGroupId+'-'+product.ComboGrpItemId}}" class="ml-2"></label>
          </p>
        </div>
      </div>
      <div class="customisation-seprator"></div>
    </div>



    <!-- third plot -->
    <div *ngIf="currentProduct.AddOnList != undefined && currentProduct.AddOnList.length != 0">
      <div>
        <h3 class="font-bold px-2">Select addons for your order</h3>
        <!-- <p class="text-gray-400 text-xs px-2" *ngIf="!item.IsAddon && item.GroupQty" >Select upto {{item.GroupQty}} options</p> -->
      </div>
      <div class="flex px-2 items-center justify-between my-4"
        *ngFor="let item of currentProduct.AddOnList; let i = index">
        <p class="flex items-center text-sm font-semibold">
          <span>
            <div *ngIf="item.FoodType == 'Vegetarian'"
              class="w-3 h-3 border border-green-700 flex items-center justify-center mr-2">
              <i class="fa-solid fa-circle text-green-700 text-[7px]"></i>
            </div>
            <div class="w-3 h-3 border border-red-700 flex items-center justify-center"
              *ngIf="item.FoodType == 'Non-vegetarian' ">
              <i class="fa-solid fa-circle text-red-700 text-[7px]"></i>
            </div>
            <div class="w-3 h-3 border border-warning flex items-center justify-center" *ngIf="item.FoodType == 'Egg' ">
              <i class="fa-solid fa-circle text-warning text-[7px]"></i>
            </div>
          </span>
          {{item.ProductName}}
        </p>

        <div *ngIf="item.IsSelected" style="display: initial;">
          <div class="product-qty">
            <span class="super_number menu">
              <button id="deduct" class="ui left attached button dec" (click)="decreaseAddon(item.ProductId)"> –
              </button>
              <input type="text" value="1" readonly="" [(ngModel)]="item.Quantity" min="1" max="100"
                [ngModelOptions]="{standalone:true}">
              <button id="add" (click)="increaseAddon(item.ProductId)" class="ui left attached button dec"
                style="background: #21BA45 !important;">
                + </button>
            </span>
          </div>
          ₹{{item.Total | number :'0.0-2'}}
        </div>

        <p class="flex items-center text-sm font-semibold">
          ₹{{item.MinSalePrice | number :'0.0-2'}}
          <label for="item-222968304" class="ml-2">
            <input class="styled-checkbox" type="checkbox" [(ngModel)]="item.IsSelected"
              [ngModelOptions]="{standalone:true}" />
          </label>
        </p>
      </div>
      <div class="customisation-seprator"></div>
    </div>
    <!--ends-->
  </div>
  <!-- add item -->
  <div class="flex items-center justify-between px-2">
    <div class="number">
      <span class="minus" (click)="decrease()">-</span>
      <input type="text" value="{{Quantity}}" />
      <span class="plus" (click)="increase()">+</span>
    </div>
    <button type="submit" class="rounded-md h-[45px] w-48 text-center text-white bg-green-600 text-md"
      (click)="AddCart()" #AddToCart>
      Add item ₹{{ComboTotal | number :'0.0-2'}}
    </button>
  </div>

</div>