<div class="modal-header">
    <h4 class="modal-title posfont font-500  ">{{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    {{message}}
</div>

<div class="modal-footer p-0 border-0">
    <div class="col-4 m-0 p-0">
        <button type="button" class="btn border-top btn-lg btn-block" (click)="close()">Close</button>
    </div>
</div>