import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Order } from '../models/order';
import { UserWrapper } from '../models/userWrapper';
import { RazorpayPaymentRequest } from '../models/razorpaypaymentrequest ';
import { Address } from '../models/address';

@Injectable()
export class ApiService {


  //BASE_URL = 'http://localhost:59088/';
  BASE_URL = 'https://api.ciferon.com/';
  CACHE_URL = 'https://apicache1.ciferon.com/';

  constructor(private http: HttpClient) {
  }

  GetMenu(mode) {
    return this.http.get(this.CACHE_URL + 'api/contactless/getmenu', {
      params: {
        saleOrderType: (mode == undefined) ? '' : mode.toString(),
        version: '3'
      }
    });
  }

  Init() {
    return this.http.get(this.CACHE_URL + 'api/contactless/init');
  }

  PostOrder(order: Order[]) {
    return this.http.post(this.BASE_URL + 'api/contactless/v2/postorder', order);
  }

  getOfferQrDetails(offerQrId: number) {
    return this.http.get(this.BASE_URL + 'api/rewardportal/get?offerQrId=' + offerQrId);
  }

  createCoupon(offerQrId: number, request: any) {
    return this.http.post(this.BASE_URL + 'api/rewardportal/register?offerQrId=' + offerQrId, request);
  }

  sendOtp(offerQrId: number, LoginWrapper: UserWrapper) {
    return this.http.post(this.BASE_URL + 'api/rewardportal/sendotp?offerQrId=' + offerQrId, LoginWrapper)
  }

  login(LoginWrapper: UserWrapper) {
    return this.http.post(this.BASE_URL + 'Api/Contactless_PublicApi/LoginOnlineOrder', LoginWrapper)
  }

  SignUp(wrapper: UserWrapper) {
    wrapper.Version = 1;
    return this.http.post(this.BASE_URL + 'Api/Contactless_PublicApi/RegisterUser', wrapper);
  }

  verifyPayment(request: RazorpayPaymentRequest) {
    return this.http.post(this.BASE_URL + 'Api/Contactless_AuthenticatedApi/Razorpay/Verify', request);
  }

  saveAddress(wrapper: Address) {
    return this.http.post(this.BASE_URL + 'api/contactless/saveaddress', wrapper);
  }

  getAddress(orderType, latitude, longitude) {
    return this.http.get(this.BASE_URL + 'api/contactless/getaddress');
  }

  getOrders() {
    return this.http.get(this.BASE_URL + 'api/contactless/getorders');
  }

  getFloors() {
    return this.http.get(this.BASE_URL + 'api/contactless_publicApi/getfloors');
  }

  getOrderById(orderId) {
    return this.http.get(this.BASE_URL + 'api/contactless/getorder?orderId=' + orderId);
  }

  getTableDetails(tableId: number) {
    return this.http.get(this.BASE_URL + 'api/contactless/gettable?tableId=' + tableId);
  }
}
