import { Injectable } from '@angular/core';
import { Store } from '../models/store';

@Injectable()
export class AuthService {

  constructor() { }

  getAuthKey() {
    var temp = localStorage.getItem('authkey');
    return temp;
  }



  setAuthKey(authkey: string) {
    localStorage.setItem('authkey', authkey);
  }

  setAddress(address: string) {
    localStorage.setItem('address', address);
  }

  getAddress() {
    return localStorage.getItem('address');
  }

  setStore(StoreId: Store) {
    localStorage.setItem('Store', JSON.stringify(StoreId));
  }

  getStore(): Store {
    return JSON.parse(localStorage.getItem('Store') || '{}');
  }



  setStoreId(StoreId: number) {
    localStorage.setItem('StoreId', StoreId.toString());
  }

  getStoreId() {
    return localStorage.getItem('StoreId') || '0';
  }

  clearAuthDetails() {
    localStorage.removeItem('StoreId');
    localStorage.removeItem('authkey');
    localStorage.removeItem('address');
    localStorage.removeItem('Order');
    localStorage.removeItem('LoginUser');
  }

  // setLocation(Location: string) {
  //   localStorage.setItem('Location', Location);
  // }

  // getLocation() {
  //   return localStorage.getItem('Location');
  // }


  setLoginUser(user) {
    localStorage.setItem('LoginUser', user);
  }

  getLoginUser() {
    return localStorage.getItem('LoginUser') || '{}';
  }

  setOrder(order) {
    localStorage.setItem('Order', order);
  }

  getOrder() {
    return localStorage.getItem('Order') || '[]';
  }

  setOrderType(orderType) {
    localStorage.setItem('orderType', orderType);
  }

  getOrderType() {
    return localStorage.getItem('orderType');
  }
}
