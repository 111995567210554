import { Component, OnInit } from '@angular/core';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-policy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.scss']
})
export class PolicyComponent implements OnInit {
  url: string;

  constructor(public storage : StorageService) { }

  ngOnInit(): void {
    this.url = window.location.hostname;
  }

}
