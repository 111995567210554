<div class="flex justify-between px-3 sticky top-0 bg-white py-2 z-10">
    <p class="text-gray-500 font-bold text-sm cursor-pointer" (click)="storage.back()"><i
            class="fa-solid fa-chevron-left mr-2"></i>BACK</p>
    <div class="cart-navigation cursor-pointer" routerLink="/menu">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
            stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round"
                d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
        </svg>
    </div>
</div>

<div *ngIf="isCartEmpty == false">


    <ng-container *ngIf = "isOrderTypeSelectionVisible">
        <!-- choose ordertype -->
        <h3 class="text-xl font-medium px-3 tracking-wide my-2 mt-0">Choose order type</h3>
        <form>
            <div class="flex items-center pb-4">
                <p class="flex items-center text-sm font-medium" *ngIf="storage.ContactlessSettings.IsDeliveryEnabled">
                    <input type="radio" [checked]="orderType == 'HomeDelivery'" name="radio-1"
                        class="radio radio-sm bg-white checked:bg-green-600 ml-2"
                        (click)="changeOrderType('HomeDelivery')" />
                    <label for="styled-checkbox-1" class="ml-2"></label>
                    Delivery
                </p>
                <p class="flex items-center text-sm font-medium" *ngIf="storage.ContactlessSettings.IsPickupEnabled">
                    <input type="radio" [checked]="orderType == 'TakeAway'" name="radio-1"
                        class="radio radio-sm bg-white checked:bg-green-600 ml-2" (click)="changeOrderType('TakeAway')">
                    <label for="styled-checkbox-2" class="ml-2"></label>
                    Pickup
                </p>
                <p class="flex items-center text-sm font-medium" *ngIf="storage.ContactlessSettings.IsDineinEnabled">
                    <input type="radio" name="radio-1" [checked]="orderType == 'DineIn'"
                        class="radio radio-sm bg-white checked:bg-green-600 ml-2" (click)="changeOrderType('DineIn')">
                    <label for="styled-checkbox-3" class="ml-2"></label>
                    Dine In
                </p>
            </div>
        </form>
        <!-- ends -->
        <div *ngIf="orderType == 'TakeAway'">
            <p class="text-xs px-3 pb-3">You have to pick it from : <span class="text-orange-400">
                    {{storage.Store.StoreDisplayName}} </span><br />
                <span class="text-orange-400">{{storage.Store.Addres}}</span>
            </p>
        </div>

        <div *ngIf="orderType == 'DineIn' || orderType == 'Token'">
            <div *ngIf="storage.ContactlessSettings.IsTableNumberFieldVisible">
                <p class="text-xs px-3 pb-3" style="line-height: 1.75rem;">Enter Your Table Number
                    <input type="text" style="width: 100px; float:right"
                        class="text-black text-center  bg-gray-100 rounded-md w-10 h-7 ml-3" [(ngModel)]="tableName"
                        id="a1" name="a1" #a1="ngModel" placeholder="e.g. B12 or 1" />
                </p>
                <p class="text-danger" style="font-size: 12px;">{{tableNameError}}</p>
            </div>
            <div>
                <div class="flex items-center px-3 pb-3">
                    <p class="text-xs">You are dining at :
                        <span class="text-orange-600">{{storage.Store.StoreDisplayName}}, </span>
                        <span class="text-orange-600">{{storage.Store.Addres}} </span>
                </div>
            </div>
        </div>
    </ng-container>

    <div class="bg-gray-100 p-3 rounded-md">
        <!-- order section -->
        <p class="text-xs">This store provide both Delivery and Self-Pickup options.<br />
            You have selected:
            <span class="text-orange-400" *ngIf="orderType == 'TakeAway'">TakeAway</span>
            <span class="text-orange-400" *ngIf="orderType == 'HomeDelivery'">Delivery</span>
            <span class="text-orange-400" *ngIf="orderType == 'DineIn' || orderType == 'Token'">Dine In</span>
        </p>

        <div *ngFor="let order of storage.currentOrder">
            <div class="bg-white rounded-xl p-3 pt-1 my-3">
                <p class="font-bold text-xs mb-3" *ngIf="cartCount() > 0">ITEMS IN CART ({{cartCount()}})</p>
                <p class="font-bold text-xs mb-3" *ngIf="cartCount() == 0">YOUR CART IS EMPTY</p>
                <div class="border-b border-[#ddd]"></div>

                <div *ngFor="let item of order.OrderItemWrapperList">
                    <div class="pt-4 pb-3">
                        <div class="flex items-center">
                            <div class="w-[40%] relative">
                                <p class="flex items-center text-sm font-semibold">
                                    <span>
                                        <div class="w-3 h-3 border border-green-700 flex items-center justify-center mr-2"
                                            *ngIf="item?.Product?.FoodType == 'Vegetarian'">
                                            <i class="fa-solid fa-circle text-green-700 text-[7px]"></i>
                                        </div>
                                        <div class="w-3 h-3 border border-red-700 flex items-center justify-center mr-2"
                                            *ngIf="item?.Product?.FoodType == 'Non-vegetarian' ">
                                            <i class="fa-solid fa-circle text-red-700 text-[7px]"></i>
                                        </div>
                                    </span>
                                    {{item.Product.ProductName}}
                                </p>
                                <ng-container *ngFor="let comboGroup of item.Product.ComboGroupList">
                                    <label style="display:block"
                                        class="text-gray-400 text-[10px] pl-5">{{comboGroup.Name}}:</label>
                                    <ng-container *ngFor="let comboItem of comboGroup.ComboList">
                                        <label style="display:block" *ngIf="comboItem.IsSelected == true"
                                            class="ml-2 text-gray-400 text-[10px] pl-5">{{comboItem.ProductName}}
                                            (₹{{comboItem.Price}})</label>
                                    </ng-container>
                                </ng-container>
                            </div>
                            <div class="w-[35%] flex items-center justify-end">
                                <!-- <div class="number">
                            <span class="minus" click)="decrementCartItem(item)">-</span>
                            <span class="plus">{{item.Quantity}}</span>
                            <span class="plus" (click)="incrementCartItem(item.UniqueNo)">+</span>
                        </div> -->

                                <div class="btn-group number" role="group" aria-label="Basic example">
                                    <button type="button" class="minus" (click)="decrementCartItem(item)">
                                        -
                                    </button>
                                    <button type="button"
                                        class="text-black text-sm font-medium w-8 border-r border-l border-gray-300">{{item.Quantity}}</button>
                                    <button type="button" class="plus" (click)="incrementCartItem(item.UniqueNo)">
                                        +
                                    </button>
                                </div>
                            </div>
                            <div class="w-[25%] flex items-center justify-end">
                                <p class="flex items-center text-sm font-semibold">
                                    ₹{{item.Total | number :'0.0-2'}}
                                </p>
                            </div>
                        </div>

                    </div>
                    <div class="border-b border-[#ddd]"></div>
                </div>
            </div>

            <!-- order ends -->

            <!-- write comment -->
            <div class="bg-white rounded-xl my-3">
                <input type="text" class="w-full border-none rounded-xl placeholder:text-xs h-full px-3 py-2"
                    placeholder="Write your comment/suggestionfor this restaurent" [(ngModel)]="order.Narration" />
            </div>

            <!-- apply promocode -->
            <!-- <div class="bg-white rounded-xl my-3 flex items-center justify-between w-full px-3 py-2"
                routerLink="/apply-coupon">
                <div>
                    <p class="text-sm font-semibold"> Apply Coupon</p>
                    <p class="text-xs text-gray-400">Save <span class="font-bold">$185</span> with PARTY</p>
                </div>
                <i class="fa-solid fa-chevron-right text-xs text-gray-400"></i>
            </div> -->

            <!-- Bill details -->
            <div class="bg-white rounded-xl p-3 my-3">
                <p class="font-bold text-xs mb-3">BILL DETAILS</p>

                <div class="flex items-center justify-between">
                    <p class="text-xs">Item Total</p>
                    <p class="text-xs">₹{{order.Subtotal | number :'0.0-2'}}</p>
                </div>
                <div class="border-b border-[#ddd] my-3"></div>

                <div class="flex items-center justify-between">
                    <p class="text-xs">Restaurant Taxes</p>
                    <p class="text-xs">₹{{order.TotalApplicableTax | number :'0.0-2'}}</p>
                </div>
                <div class="border-b border-[#ddd] my-3"></div>
                <ng-container *ngIf="order.DeliveryCharges > 0">
                    <div class="flex items-center justify-between">
                        <p class="text-xs">Delivery Charges</p>
                        <p class="text-xs">₹{{order.DeliveryCharges | number :'0.0-2'}}</p>
                    </div>
                    <div class="border-b border-[#ddd] my-3"></div>
                </ng-container>


                <div class="flex items-center justify-between">
                    <p class="text-xs font-bold">To Pay</p>
                    <p class="text-xs font-bold">₹{{storage.grandTotal | number :'0.0-2'}}</p>
                </div>
                <div class="border-b border-[#ddd] my-3"></div>

            </div>

            <!-- bill ends -->

            <div class="" *ngIf="!storage.isUserLoggedIn">
                <button class="rounded-md w-full py-3 text-white bg-[#2f4f4f] text-sm "
                    [routerLink]="['/login']">Login</button>
            </div>

        </div>

        <!-- address & payment -->
        <div class="bg-white rounded-xl p-3 my-3" *ngIf="orderType == 'HomeDelivery' && storage.isUserLoggedIn">
            <p class="text-lg font-bold">Select Address</p>
            <ng-container *ngFor="let address of addressList">
                <div class="bg-[#f2fcf2] p-3 rounded-md my-2">
                    <p class="flex items-center text-sm font-bold uppercase">
                        <input (change)="selectAddress(address)" type="radio" name="radio-11"
                            class="radio radio-sm bg-white checked:bg-green-600 mr-3" [checked]="address.IsSelected">
                        {{address?.AddressType}}
                    </p>
                    <p class="flex items-center text-xs mt-2 ml-8 font-medium capitalize">{{address?.AddressLine1}},
                        {{address?.AddressLine2}}</p>

                </div>
            </ng-container>

            <p class="text-md font-semibold ml-4 my-4 cursor-pointer" (click)="showAddresses()">
                <i class="fa-solid fa-plus text-sm mr-2"></i> Manage Address
            </p>
        </div>


        <!--Select Payment  -->
        <div class="bg-white rounded-xl p-3 my-3" *ngIf="storage.isUserLoggedIn">
            <p class="text-lg font-bold">Select Payment</p>
            <form>
                <label *ngIf="storage.ContactlessSettings.IsPaytmEnabled">
                    <div class="bg-[#f2fcf2] p-3 rounded-md my-2">
                        <p class="flex items-center text-sm font-bold uppercase">
                            <input type="radio" name="radio-1" class="radio radio-sm bg-white checked:bg-green-600 mr-3"
                                (click)="changePaymentMode('Online')" checked />
                            Online
                        </p>
                        <p class="flex items-center text-xs mt-2 ml-8 font-medium">Pay securely by UPI or Credit or
                            Debit card
                            or Internet Banking
                        </p>
                    </div>
                </label>
                <label *ngIf="storage.ContactlessSettings.IsCodEnabled">
                    <div class="bg-[#f2fcf2] p-3 rounded-md my-2">
                        <p class="flex items-center text-sm font-bold uppercase">
                            <input type="radio" name="radio-1" class="radio radio-sm bg-white checked:bg-green-600 mr-3"
                                (click)="changePaymentMode('Cash')" />
                            COD
                        </p>
                        <p class="flex items-center text-xs mt-2 ml-8 font-medium">Pay with cash</p>
                    </div>
                </label>
            </form>
        </div>
        <div *ngIf="storage.isUserLoggedIn">
            <a *ngIf="inProgress == false" [disabled]="inProgress" (click)="postOrder()">
                <ng-container>
                    <button class="rounded-md w-full py-3 text-white bg-[#2f4f4f] text-sm "
                        *ngIf="paymentMode == 'Online'">Confirm and Proceed to Pay</button>
                    <button class="rounded-md w-full py-3 text-white bg-[#2f4f4f] text-sm "
                        *ngIf="paymentMode == 'Cash'">Confirm and Proceed using Cash</button>
                </ng-container>
            </a>
            <button *ngIf="inProgress == true" class="btn btn-success btn-block btn-lg" type="button" disabled>
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Placing Order...
            </button>
        </div>

        <!-- ends -->
    </div>
</div>

<div *ngIf="isCartEmpty == true" class="text-center">
    <div class="svg-wrapper">
        <svg xmlns="http://www.w3.org/2000/svg" id="bbc88faa-5a3b-49cf-bdbb-6c9ab11be594" data-name="Layer 1"
            width="w-100" viewBox="0 0 728 754.88525" class="injected-svg modal__media modal__mobile__media"
            data-src="https://42f2671d685f51e10fc6-b9fcecea3e50b3b59bdc28dead054ebc.ssl.cf5.rackcdn.com/illustrations/cooking_lyxy.svg"
            xmlns:xlink="http://www.w3.org/1999/xlink">
            <rect x="514.67011" y="302.6311" width="33" height="524"
                transform="translate(-458.65432 311.24592) rotate(-33.25976)" fill="#e6e6e6"></rect>
            <path
                d="M335.58256,171.60615l63.84438,97.34271a8.5,8.5,0,0,1-14.21528,9.32341L311.81484,166.36508a60.62682,60.62682,0,0,0-29.14936,4.78729L362.63446,293.08a8.5,8.5,0,0,1-14.21528,9.3234l-79.969-121.92766A60.62685,60.62685,0,0,0,252.44516,205.304L325.842,317.2112a8.5,8.5,0,0,1-14.21528,9.3234l-63.84438-97.3427c-1.6398,27.14157,7.20944,59.3114,26.60329,88.881,36.04421,54.95614,94.83957,80.109,131.32307,56.18052s36.8396-87.87721.79539-142.83336C387.11022,201.85046,361.13005,180.91634,335.58256,171.60615Z"
                transform="translate(-236 -72.55738)" fill="#e6e6e6"></path>
            <rect x="256" y="204" width="33" height="524" fill="#e6e6e6"></rect>
            <ellipse cx="272" cy="119" rx="79" ry="119" fill="#e6e6e6"></ellipse>
            <ellipse cx="272" cy="119" rx="65" ry="97.91139" fill="#ccc"></ellipse>
            <ellipse cx="364" cy="734" rx="364" ry="20.88525" fill="#e6e6e6"></ellipse>
            <path
                d="M815.26782,806.25045a1162.796,1162.796,0,0,0-412.53564,0A15.04906,15.04906,0,0,1,385,791.45826V604.55738H833V791.45826A15.04906,15.04906,0,0,1,815.26782,806.25045Z"
                transform="translate(-236 -72.55738)" fill="#00a200"></path>
            <path
                d="M792,466.55738a92.85808,92.85808,0,0,0-30.39526,5.0863,179.055,179.055,0,0,0-324.4441-1.63928,93.00486,93.00486,0,1,0,12.16987,174.74988,179.02647,179.02647,0,0,0,300.7481-2.16382A93.00664,93.00664,0,1,0,792,466.55738Z"
                transform="translate(-236 -72.55738)" fill="#00a200"></path>
            <path
                d="M421,546.55738h-2A178.40222,178.40222,0,0,1,436.24707,469.572l1.80762.85644A176.41047,176.41047,0,0,0,421,546.55738Z"
                transform="translate(-236 -72.55738)" fill="#3f3d56"></path>
            <path
                d="M779,546.55738h-2a176.52632,176.52632,0,0,0-16.29395-74.501l1.81641-.83789A178.51046,178.51046,0,0,1,779,546.55738Z"
                transform="translate(-236 -72.55738)" fill="#3f3d56"></path>
            <path
                d="M385.24121,691.52808l-.48242-1.94141c.56445-.13964,57.40332-14.09961,140.70019-21.02636,76.88086-6.39258,192.68653-7.93457,307.78516,21.02734l-.48828,1.93945C717.93945,662.63746,602.38672,664.17261,525.667,670.55054,442.519,677.46167,385.8042,691.38843,385.24121,691.52808Z"
                transform="translate(-236 -72.55738)" fill="#3f3d56"></path>
            <path
                d="M385.24121,718.52808l-.48242-1.94141c.56445-.13964,57.40332-14.09961,140.70019-21.02636,76.88086-6.39258,192.68653-7.93457,307.78516,21.02734l-.48828,1.93945C717.93945,689.63746,602.38672,691.17456,525.667,697.55054,442.519,704.46167,385.8042,718.38843,385.24121,718.52808Z"
                transform="translate(-236 -72.55738)" fill="#3f3d56"></path>
            <path
                d="M385.24121,745.52808l-.48242-1.94141c.56445-.13964,57.40332-14.09961,140.70019-21.02636,76.88086-6.39258,192.68653-7.93457,307.78516,21.02734l-.48828,1.93945C717.93945,716.63746,602.38672,718.17456,525.667,724.55054,442.519,731.46167,385.8042,745.38843,385.24121,745.52808Z"
                transform="translate(-236 -72.55738)" fill="#3f3d56"></path>
            <path
                d="M753.26693,598.71334,729.03658,590.475l23.26113-118.72871-15.992-1.45382c-15.594,11.96435-36.35984,16.65481-62.99891,13.08438l42.64542,64.45274-21.745,15.34942-69.368-83.20523A20.866,20.866,0,0,1,620,466.61227v0a20.866,20.866,0,0,1,15.0905-20.05076L709.16769,425.224l86.74466,9.69214c13.11467,19.99417,13.62744,33.89954-6.33645,37.911Z"
                transform="translate(-236 -72.55738)" fill="#2f2e41"></path>
            <path
                d="M728.46691,644.90106h0a15.95869,15.95869,0,0,1-13.86555-21.711l12.046-30.97551c6.11869-11.59112,14.5164-10.14011,24.43261,0l4.84611,14.21526a9.17534,9.17534,0,0,1-.53485,7.176L743.64973,636.306A15.95871,15.95871,0,0,1,728.46691,644.90106Z"
                transform="translate(-236 -72.55738)" fill="#2f2e41"></path>
            <path
                d="M697.15218,604.33834h0a15.95869,15.95869,0,0,1-13.86555-21.711l12.046-30.97551c6.11869-11.59113,14.51641-10.14012,24.43261,0l4.84611,14.21525a9.17537,9.17537,0,0,1-.53485,7.176L712.335,595.74331A15.9587,15.9587,0,0,1,697.15218,604.33834Z"
                transform="translate(-236 -72.55738)" fill="#2f2e41"></path>
            <circle cx="476.55994" cy="212.13062" r="27.13799" fill="#ffb9b9"></circle>
            <polygon points="518.721 250.415 481.406 269.799 473.652 234.907 499.336 218.915 518.721 250.415"
                fill="#ffb9b9"></polygon>
            <path
                d="M799.7892,439.76224c-37.23393-11.24605-71.01788-17.07317-95.46758-8.23832,8.42738-23.70818-7.12737-59.91146-24.23035-96.9214,7.37949-9.64677,19.14576-13.38347,32.46867-15.02282,14.5769,10.5844,24.74122,3.79091,32.46867-12.59978,16.85358-.67652,33.095,5.29186,48.94531,15.50743C781.58355,362.17339,783.814,401.25293,799.7892,439.76224Z"
                transform="translate(-236 -72.55738)" fill="#3f3d56"></path>
            <path
                d="M703.837,437.33921c-5.87952,3.4656-11.3058,9.30325-16.47664,16.47664-8.73817-5.349-16.42816-11.439-22.48592-18.68294a40.01122,40.01122,0,0,1-7.33032-37.42892l16.56053-53.82173a23.60967,23.60967,0,0,1,7.67755-11.38054l2.18592-1.776,21.80731,41.19159-21.80731,40.707C686.73356,420.03892,694.88267,428.6031,703.837,437.33921Z"
                transform="translate(-236 -72.55738)" fill="#3f3d56"></path>
            <path
                d="M711.343,478.37478h0a14.00455,14.00455,0,0,1-19.66674-10.71872L688.072,442.98155l12.59979-6.7845,15.9909,20.93355A14.00455,14.00455,0,0,1,711.343,478.37478Z"
                transform="translate(-236 -72.55738)" fill="#ffb9b9"></path>
            <path
                d="M739.94024,283.50047l-4.63369.13763-12.853-18.20724c-16.46951,1.70257-29.96494,8.858-41.38524,19.81828l-1.15795-7.71966a29.10153,29.10153,0,0,1,22.90286-32.81892h.00006a29.10153,29.10153,0,0,1,34.57213,23.6573Z"
                transform="translate(-236 -72.55738)" fill="#2f2e41"></path>
            <path
                d="M687.82806,453.82563v0a14.00456,14.00456,0,0,1,10.71872-19.66675l24.67452-3.60414,6.7845,12.59978L709.07224,459.1454A14.00455,14.00455,0,0,1,687.82806,453.82563Z"
                transform="translate(-236 -72.55738)" fill="#ffb9b9"></path>
            <path
                d="M804.49034,431.38118c-23.4754,1.82279-49.10633,9.14326-75.93837,19.527a37.12074,37.12074,0,0,0-8.23832-21.80731c24.37008-6.41874,46.48406-13.95144,60.09127-25.68417L772.1666,341.387l17.93046-20.35349,3.09274,1.6136a20.65228,20.65228,0,0,1,10.4691,13.14326c7.57071,29.449,10.93351,57.66486,8.62195,84.21782A10.47079,10.47079,0,0,1,804.49034,431.38118Z"
                transform="translate(-236 -72.55738)" fill="#3f3d56"></path>
            <path
                d="M331.88594,800.6692q-32.74851,20.483-65.49722-.01716a4.441,4.441,0,0,1-2.10125-4.0963l6.81241-88.56136h55.10049l7.78288,88.5302A4.44,4.44,0,0,1,331.88594,800.6692Z"
                transform="translate(-236 -72.55738)" fill="#3f3d56"></path>
            <ellipse cx="62.39599" cy="636.43883" rx="27.80438" ry="10.01827" fill="#3f3d56"></ellipse>
            <path d="M320.18941,705.61437q-21.73251,15.28772-42.07674,0V689.58514h42.07674Z"
                transform="translate(-236 -72.55738)" fill="#00a200"></path>
            <ellipse cx="63.15104" cy="617.02776" rx="21.03837" ry="8.01462" fill="#00a200"></ellipse>
            <ellipse cx="64.15287" cy="616.02594" rx="2.00365" ry="1.00183" fill="#e6e6e6"></ellipse>
            <ellipse cx="73.61397" cy="616.02594" rx="2.00365" ry="1.00183" fill="#e6e6e6"></ellipse>
            <ellipse cx="68.88342" cy="618.39121" rx="2.00365" ry="1.00183" fill="#e6e6e6"></ellipse>
            <ellipse cx="49.96121" cy="618.39121" rx="2.00365" ry="1.00183" fill="#e6e6e6"></ellipse>
            <ellipse cx="54.69176" cy="616.02594" rx="2.00365" ry="1.00183" fill="#e6e6e6"></ellipse>
            <ellipse cx="59.42232" cy="619.57385" rx="2.00365" ry="1.00183" fill="#e6e6e6"></ellipse>
            <path
                d="M936.88594,800.6692q-32.74851,20.483-65.49722-.01716a4.441,4.441,0,0,1-2.10125-4.0963l6.81241-88.56136h55.10049l7.78288,88.5302A4.44,4.44,0,0,1,936.88594,800.6692Z"
                transform="translate(-236 -72.55738)" fill="#3f3d56"></path>
            <ellipse cx="667.39599" cy="636.43883" rx="27.80438" ry="10.01827" fill="#3f3d56"></ellipse>
            <path d="M925.18941,705.61437q-21.73251,15.28772-42.07674,0V689.58514h42.07674Z"
                transform="translate(-236 -72.55738)" fill="#00a200"></path>
            <ellipse cx="668.15104" cy="617.02776" rx="21.03837" ry="8.01462" fill="#00a200"></ellipse>
            <ellipse cx="669.15287" cy="616.02594" rx="2.00365" ry="1.00183" fill="#e6e6e6"></ellipse>
            <ellipse cx="678.61397" cy="616.02594" rx="2.00365" ry="1.00183" fill="#e6e6e6"></ellipse>
            <ellipse cx="673.88342" cy="618.39121" rx="2.00365" ry="1.00183" fill="#e6e6e6"></ellipse>
            <ellipse cx="654.96121" cy="618.39121" rx="2.00365" ry="1.00183" fill="#e6e6e6"></ellipse>
            <ellipse cx="659.69176" cy="616.02594" rx="2.00365" ry="1.00183" fill="#e6e6e6"></ellipse>
            <ellipse cx="664.42232" cy="619.57385" rx="2.00365" ry="1.00183" fill="#e6e6e6"></ellipse>
        </svg>
    </div>
    <h4 class="mb-3 mt-4 text-black">No items</h4>
    <p class="text-md mt-2 w-[70%] mx-auto text-black mb-5">Browse our menu and order your favourite food now</p>
    <button class="bg-green-600 px-8 py-2 mt-4 text-white text-md rounded-md block mx-auto"
        [routerLink]="['/menu']">View Menu</button>
</div>

<paytm-checkout></paytm-checkout>