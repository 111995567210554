import { Component, OnInit, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { RouterService } from 'src/app/services/router.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  inProgress: boolean;
  offer: any;
  offerQr: any;
  company: any;
  store: any;



  constructor(public storage: StorageService, private api: ApiService,
    private router: RouterService, private activatedRoute: ActivatedRoute) {
    activatedRoute.params.subscribe(params => {
      this.storage.offerQrId = +params['id'];
      if (this.storage.offerQrId != undefined) {
        this.getOfferQrDetails();
      }
    });
  }
  
  ngOnInit(): void { }

  getOfferQrDetails() {
    this.inProgress = true;
    this.api.getOfferQrDetails(this.storage.offerQrId).subscribe((data: any) => {
      if (data != null && data.IsSuccess) {
        this.offer = data.Result.offerWrapper;
        this.offerQr = data.Result.offerQr;
        this.company = data.Result.company;
        this.store = data.Result.store;
      }
      this.inProgress = false;
    },
      (error) => {
        console.log(error);
        this.inProgress = false;
      });
  }

  onMapClicked() {
    var url = 'https://www.google.com/maps/search/?api=1&query=' + this.storage.Store.Latitude + "," + this.storage.Store.Longitude;
    window.open(url, "_blank");
  }

  onCallClicked() {
    window.open('tel:' + this.storage.Store.SupportContact);
  }

}
