import { Product } from "./product";
import { Offer } from "./offer";

export class Order {
    OrderID: number;
    OrderSerialNo: string;
    Source: string;
    SettleDate: string;
    PurchaseDate: string;
    CreatedOn: string;
    PrintDate: string;
    GrandTotal: number;
    OrderItemWrapperList: OrderItem[];
    TableId?: number;
    Subtotal: number;
    DeliveryCharges: number = 0;
    PackagingCharges?: number;
    DecorationCharges?: number;
    MaintenanceCharges?: number;
    AdditionalChargesTotal?: number;
    CancellationReason: string;
    OrderStatus: string;
    SaleOrderType: string;
    OrderNo: string;
    OrderUniqueNo: string;
    PrintCount: number;
    KOTPrintCount: number;
    DiscountPer: number;
    DiscountRs: number;
    DiscountValue: number;
    RoundOff: number;
    Balance: number;
    TableNo: string;
    IsKotGenerated: boolean;
    IsComplimentary: boolean;
    RestaurantProductList: OrderItem[];
    BarProductList: OrderItem[];
    PaymentPaidList: string;
    TotalProductQty: number;
    ColorCode: string;
    StoreId: number;
    StoreName: string;
    LicenseId: number;
    TotalApplicableTaxInclusive: number;
    TotalApplicableTax: number;
    LoyaltyPoints: number = 0;
    RedeemPoints: number = 0;
    LoyaltyDiscount: number = 0;
    CurrentPoints: number;
    PrintStatus: string;
    IsComplementary: boolean;
    IsEditableOrder: boolean;
    OrderPrintHistory: string[];
    BillRequestFromAPP: boolean;
    Channel: string;
    ErrorMessage: string;
    ExternalId: string;
    SubStatus: string;
    Narration: string;
    OfferList: Offer[];
    CouponId?: number;
    SubtotalForOrderPoints: number;
    PlatformMasterId: number;
    FloorId: number;
    IsBarProductPresent: boolean;
    IsFoodProductPresent: boolean;
    BarProductSubtotal: number;
    FoodProductSubtotal: number;
    BarProductDiscount: number;
    FoodProductDiscount: number;
    BarProductTotal: number;
    FoodProductTotal: number;
    BarProductCount: number;
    FoodProductCount: number;
    DueDate: string;
    ConfirmTime: string;
    IsPreviousQuantityDiff: boolean;
    LedgerId: number;
    FoodDisc: number;
    DrinkDisc: number;
    PaymentMode: string;
    TaxSlabList: TaxSlabs[];
    AddressId: number;
    Address: any;
    ServiceCharge: number;
    CustomAttributeList: CustomAttributeOrder[];
    DeliverySlotId: number;
    DeliveryDate: Date;
    DeliveryDateString: string;
    OfferTransactions: OfferTransaction[];
    OrderDetail: OrderDetail;
    CarNo: string;
    TokenNumber: string;
    Table: any;
    PaymentModes: string;
    EntryDate : Date;
  IsTableQr: boolean;
    get TimerClock() {
        let time = '';
        if (this.PurchaseDate != undefined) {
            let diffInMs: number = Date.parse(new Date().toString()) - Date.parse(this.PurchaseDate);
            let seconds = Math.floor((diffInMs / 1000) % 60);
            let minutes = Math.floor((diffInMs / (1000 * 60)) % 60);
            let hours = Math.floor((diffInMs / (1000 * 60 * 60)) % 24);

            let shours = (hours < 10) ? "0" + hours : hours;
            let sminutes = (minutes < 10) ? "0" + minutes : minutes;
            let sseconds = (seconds < 10) ? "0" + seconds : seconds;

            time = shours + ":" + sminutes + ":" + sseconds
        }
        return new Date();
    }
    get offerText() {
        if (this.OfferList != undefined && this.OfferList.length > 0) {
            return this.OfferList[0].Name;
        }
        else {
            return '';
        }
    }

    constructor() {
        this.IsComplementary = false;
        this.CancellationReason = '';
        this.OrderItemWrapperList = new Array<OrderItem>();
        this.PrintCount = 0;
        this.Subtotal = 0;
        this.GrandTotal = 0;
        this.BillRequestFromAPP = false;
        this.IsKotGenerated = false;
        this.IsBarProductPresent = false;
        this.IsFoodProductPresent = false;
        this.IsPreviousQuantityDiff = false;
        this.OfferList = new Array<Offer>();
        this.CustomAttributeList = new Array<CustomAttributeOrder>();
        this.SaleOrderType = undefined;
    };
}

export class GstSlabs {
    GSTId: number;
    CGST: string;
    IGST: string;
    SGST: string;
    Vat: string;
    CGSTTotal: number;
    IGSTTotal: number;
    SGSTTotal: number;
    VatTotal: number;
    RestaurantProductType: string;
}

export class TaxSlabs {
    TaxItemId: number;
    Name: string;
    TotalTax: number;
    RestaurantProductType: string;
}

export class GstMaster {
    GstMasterId: number;
    TaxType: string;
    Name: string;
    SGST: number;
    CGST: number;
    IGST: number;
    Cess: number;
    IsSelected: boolean;
    status: string;
    Vat: number;

    TotalPercentTax(): number {
        return this.SGST + this.CGST;
    }
}



export class OrderItem {
    OrderSerialNo: string;
    OrderItem: number;
    ProductId: number;
    Product: Product;
    Quantity: number;
    PreviousQuantity: number;
    PrintQuantity: number;
    PrintPreviousQuantity: number;
    PrintTotal: number;
    PrintRate: number;
    Rate: number;
    Total: number;
    IsKOTGenerated: boolean;
    KOTNo: string;
    IsDeleted: boolean;
    KOTTotal: number;
    TotalTax: number;
    KotComment: string;
    KotCancelReason: string;
    ComboString: string;
    ComboListString: string[];
    ComboTotal: number;
    KOTDate: string;
    StartTime: Date;
    IsComplementary: boolean;
    UniqueNo: string;
    EndTime: string;
    CategoryName: string;
    CategoryId?: number;
    OfferItemId: number;
    OfferId: number;
    LoyaltyPoints: number;
    TaxType: string;
    Offer: Offer;
    IsOfferItem: boolean;
    ItemDiscount: number;
    ItemDiscountPer: number;
    IsVariant: boolean;
    Description: string;
    IsCancelKOT: boolean;
    OfferUniqueNo: string
    IsProductDiscountApply: boolean;
    ProductName: string;
    Messages: string;
    constructor() {
        this.KotComment = '';
        this.LoyaltyPoints = 0;
        this.KotCancelReason = '';
        this.IsOfferItem = false;
        this.IsCancelKOT = false;
        this.IsProductDiscountApply = false;
    }

    get GetComboItemId() {
        var result = '';
        if (this.Product.ComboGroupList != null && this.Product.ComboGroupList.length > 0) {
            this.Product.ComboGroupList.forEach((item) => {
                if (item.ComboList != null && item.ComboList.length > 0) {
                    item.ComboList.forEach((item1) => {
                        if (item1.IsSelected) {
                            if (result == null) {
                                result = item1.ComboGrpItemId + item1.Quantity;
                            }
                            else {
                                result = result + ',' + item1.ComboGrpItemId + item1.Quantity;
                            }
                        }
                    });
                }
            });
        }
        return result;
    }

    get GetAddonId() {
        var result = '';
        if (this.Product.AddOnList != null && this.Product.AddOnList.length > 0) {
            this.Product.AddOnList.forEach((item) => {
                if (item.IsSelected) {
                    if (result == null) {
                        result = item.ProductId + item.Quantity;
                    }
                    else {
                        result = result + ',' + item.ProductId + item.Quantity;
                    }
                }
            });
        }
        return result;
    }
}

export class OrderItem2 {
    CGST: number;
    CategoryId: number;
    Cess: number;
    CreatedOn: Date;
    DifferenceQuantity: number;
    DispatchedRate: number;
    GstMasterId: number;
    IGST: number;
    InitialRate: number;
    IsDeleted: boolean;
    IsGRNProcessed: boolean;
    IsIssueProcessed: boolean;
    IssuedStock: number;
    ModifiedOn: Date;
    OrderID: number;
    OrderItem2Id: number;
    Price: number;
    ProductId: number;
    PurchaseBasePrice: number;
    PurchaseNetPrice: number;
    Quantity: number;
    Rate: number;
    ReceivedStock: number;
    RequestedStock: number;
    SGST: number;
    Total: number;
    UnitMasterId: number;
    Vat: number;
}

export class OrderWrapper {
    order: Order[];
    paymentId: number;
    Mode: string;
    EntryDate: Date;
}

export class OrderStats {
    CurrentTotal: number
    PreviousTotal: number
    ABV: number
    Type: string
    CustomerCount: number
    TotalCustomers: number
    CustomerByABVPercent: number
    RevenueBreakupGrowth: number;
}

export class CustomAttributeOrder {
    Name: string;
    Value: string;
    CustomAttributeid: number;
}

export class TaxMaster {
    TaxMasterId: number;
    Name: string;
    Percent: number;
    IgstId: number;
    TaxItems: TaxItem[];
}

export class TaxItem {
    TaxItemId: number;
    Name: string;
    Value: number;
}

export class OfferTransaction {
    OfferUniqueNo: string;
    OfferGroupId: number;
    CouponId: number;
    OfferId: number;
}

export class OrderDetail {
    EnableDelivery: boolean;
    PreparationTime: number;
    CustomerArrivalTime: number;
    RiderName: string;
    RiderPhoneNumber: string;
    CashToBeCollected: number;
    RiderTemperature: number;
    CarNo: string;
}